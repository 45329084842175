import React from "react"
import QuickLinks from "./QuickLinks/QuickLinks"
import { Col, Row, Space, Header } from "uiComponents"
import { isMobile } from "helpers"
import { AppPaths, getPath } from "shoppio-constants"

const getLinksByStoreType = (type, storeID) => {
  let servicesStore = [
    {
      label: "View Bookings",
      to: getPath(AppPaths.STORE_DASHBOARD),
      state: {
        storeid: storeID,
        hash: "bookings",
      },
      iconClass: "lni lni-book-2",
    },
    {
      label: "Add Service",
      iconClass: "lni lni-shop",
      to: getPath(AppPaths.ADD_PRODUCT, { storeid: storeID }),
    },

    {
      label: "Add Categories",
      iconClass: "lni lni-node-add",
      to: getPath(AppPaths.ADD_CATEGORY),
      state: {
        storeid: storeID,
      },
    },
    {
      label: "Update Store",
      iconClass: "lni lni-shop",
      to: getPath(AppPaths.ADD_STORE_WITH_ID),
      state: {
        storeid: storeID,
      },
    },
    {
      label: "Add Pincodes",
      iconClass: "lni lni-map-pin-1",
      to: getPath(AppPaths.DELIVERY),
      state: {
        storeid: storeID,
      },
    },
    {
      label: "Manage Access",
      to: `${AppPaths.ACL}${storeID}`,
      iconClass: "lni lni-user-shield",
    },
    {
      label: "More Options",
      to: getPath(AppPaths.STORE_DASHBOARD),
      state: {
        storeid: storeID,
      },
      iconClass: "lni lni-sliders-double-vertical",
    },
  ]
  let productStore = [
    {
      label: "Add Product",
      iconClass: "lni lni-shop",
      to: getPath(AppPaths.ADD_PRODUCT, { storeid: storeID }),
    },

    {
      label: "Add Categories",
      iconClass: "lni lni-node-add",
      to: getPath(AppPaths.ADD_CATEGORY),
      state: {
        storeid: storeID,
      },
    },
    {
      label: "Update Store",
      iconClass: "lni lni-shop",
      to: getPath(AppPaths.ADD_STORE_WITH_ID),
      state: {
        storeid: storeID,
      },
    },
    {
      label: "Add Pincodes",
      iconClass: "lni lni-map-pin-1",
      to: getPath(AppPaths.DELIVERY),
      state: {
        storeid: storeID,
      },
    },
    {
      label: "View Orders",
      to: getPath(AppPaths.STORE_DASHBOARD),
      state: {
        storeid: storeID,
        hash: "orders",
      },
      iconClass: "lni lni-boxes-stacked-1",
    },
    {
      label: "View Returns",
      to: getPath(AppPaths.STORE_DASHBOARD),
      state: {
        storeid: storeID,
      },
      iconClass: "lni lni-truck-delivery-return",
    },
    {
      label: "Manage Access",
      to: `${AppPaths.ACL}${storeID}`,
      iconClass: "lni lni-user-shield",
    },

    {
      label: "More Options",
      to: getPath(AppPaths.STORE_DASHBOARD),
      state: {
        storeid: storeID,
      },
      iconClass: "lni lni-sliders-double-vertical",
    },
  ]
  let bothStore = [
    {
      label: "Add Product",
      iconClass: "lni lni-shop",
      to: getPath(AppPaths.ADD_PRODUCT),
      state: { storeid: storeID },
    },
    {
      label: "Add Service",
      iconClass: "lni lni-shop",
      to: getPath(AppPaths.ADD_PRODUCT, { storeid: storeID, hash: "service" }),
    },
    {
      label: "Add Categories",
      iconClass: "lni lni-node-add",
      to: getPath(AppPaths.ADD_CATEGORY),
      state: {
        storeid: storeID,
      },
    },
    {
      label: "Update Store",
      iconClass: "lni lni-shop",
      to: getPath(AppPaths.ADD_STORE_WITH_ID),
      state: {
        storeid: storeID,
      },
    },
    {
      label: "Add Pincodes",
      iconClass: "lni lni-map-pin-1",
      to: getPath(AppPaths.DELIVERY),
      state: {
        storeid: storeID,
      },
    },
    {
      label: "View Orders",
      to: getPath(AppPaths.STORE_DASHBOARD),
      state: {
        storeid: storeID,
      },
      iconClass: "lni lni-boxes-stacked-1",
    },
    {
      label: "View Returns",
      to: getPath(AppPaths.STORE_DASHBOARD),
      state: {
        storeid: storeID,
        hash: "returns",
      },
      iconClass: "lni lni-truck-delivery-return",
    },
    {
      label: "View Bookings",
      to: getPath(AppPaths.STORE_DASHBOARD),
      state: {
        storeid: storeID,
        hash: "orders",
      },
      iconClass: "lni lni-book-2",
    },

    {
      label: "Manage Access",
      to: `${AppPaths.ACL}${storeID}`,
      iconClass: "lni lni-user-shield",
    },

    {
      label: "More Options",
      to: getPath(AppPaths.STORE_DASHBOARD),
      state: {
        storeid: storeID,
      },
      iconClass: "lni lni-sliders-double-vertical",
    },
  ]

  const commonActions = [
    {
      label: "Coupon Codes",
      to: getPath(AppPaths.COUPON_CODES),
      state: {
        storeid: storeID,
      },
      iconClass: "lni lni-tickets-2",
    },
    {
      label: "Customer Messages",
      to: getPath(AppPaths.CUSTOMER_MESSAGES),
      state: {
        storeid: storeID,
      },
      iconClass: "lni lni-chat-2-text",
    },
  ]

  return type === "Services"
    ? [...servicesStore, ...commonActions]
    : type === "Products"
    ? [...productStore, ...commonActions]
    : type === "Both"
    ? [...bothStore, ...commonActions]
    : [...productStore, ...commonActions]
}
export default function AdminQuickLinks({
  storeID,
  type,
  categoryID,
  subcategoryID,
  bottomComponent,
  withPad,
  storeType,
}) {
  return (
    <Row>
      <Col xs={12} style={{ padding: withPad || isMobile ? "" : 0 }}>
        <Space lg />
        <Header sm bold noMar>
          Quick Actions
        </Header>
        <Space lg />
        <QuickLinks
          links={
            type === "store"
              ? getLinksByStoreType(storeType, storeID)
              : type === "category"
              ? [
                  {
                    label: "Update Category",
                    iconClass: "fas fa-pen",
                    to: getPath(AppPaths.UPDATE_CATEGORY),
                    state: {
                      storeid: storeID,
                      category_id: categoryID,
                    },
                  },
                  {
                    label: "Add Subcategory",
                    iconClass: "fas fa-project-diagram",
                    to: getPath(AppPaths.ADD_SUBCATEGORY),
                    state: {
                      storeid: storeID,
                      category_id: categoryID,
                    },
                  },
                ]
              : type === "subcategory"
              ? [
                  {
                    label: "Update Subcategory",
                    iconClass: "fas fa-pen",
                    to: getPath(AppPaths.UPDATE_SUBCATEGORY),
                    state: {
                      storeid: storeID,
                      category_id: categoryID,
                      subcategory_id: subcategoryID,
                    },
                  },
                ]
              : []
          }
        />
        {bottomComponent}
      </Col>
    </Row>
  )
}
