import React from "react"

const EliteBadge = () => {
  return (
    <i
      className='fas fa-5x fa-medal'
      style={{
        display: "flex",
        justifyContent: "flex-end",
        position: "absolute",
        right: 0,
        top: "-19px",
      }}
    />
  )
}

export default EliteBadge
