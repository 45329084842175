import { ErrorManager } from "appComponents"
import { DB, firebaseApp, storage } from "firebaseUtils"
import { getTime } from "helpers"
import { useState } from "react"
import { useStateValue } from "state"
export const handleError = (err, url, docID) => {
  console.error(err)
  const user = firebaseApp.auth().currentUser
  ErrorManager.setContext({
    userEmail: user?.phoneNumber || "Not user",
    userID: user?.uid,
    shoppioErrorType: "firestore-errors",
    dbPath: url,
    docID: docID,
  })
  ErrorManager.notify(err)
}

export const deleteDocFromDb = async (collectionURL, leafDoc, callback) => {
  await DB.collection(collectionURL)
    .doc(leafDoc)
    .delete()
    .then(() => {
      if (callback) {
        callback()
      }
    })
    .catch(e => handleError(e, collectionURL))
}

export const getDocsFromDb = async collectionURL => {
  const documents = await DB.collection(collectionURL)
    .get()
    .then(snap => {
      return snap.docs.map(doc => ({
        ...doc.data(),
        exists: doc?.exists,
        id: doc?.id,
      }))
    })
    .catch(e => handleError(e, collectionURL))
  return await documents
}

export const getDocFromDb = async (collectionURL, leafDoc) => {
  if (!leafDoc) return
  const data = await DB.collection(collectionURL)
    .doc(leafDoc)
    .get()
    .then(doc => {
      return {
        ...doc?.data(),
        exists: doc?.exists,
        id: doc?.id,
      }
    })
    .catch(e => handleError(e, collectionURL, leafDoc))
  return await data
}

export const getDocsFromDbWhere = async (collectionURL, ...query) => {
  // console.log("collectionURL", query)
  const data = await DB.collection(collectionURL)
    .where(...query)
    .get()
    .then(snap => {
      return snap.docs.map(doc => ({
        ...doc.data(),
        exists: doc?.exists,
        id: doc?.id,
      }))
    })
    .catch(e => handleError(e, collectionURL, query))
  return await data
}

export const getDocsFromDbMultipleWheres = async (collectionURL, ...query) => {
  let collection = await DB.collection(collectionURL)
  for (let oneWhere of query) {
    collection = collection.where(...oneWhere)
  }
  const data = await collection.get().then(snap => {
    return snap.docs.map(doc => ({
      ...doc.data(),
      exists: doc?.exists,
      id: doc?.id,
    }))
  })

  return data
}
export const getDocsFromDbMultipleWheresWithLimit = async (
  limit = 1,
  collectionURL,
  ...query
) => {
  let collection = DB.collection(collectionURL)
  for (let oneWhere of query) {
    collection = collection.where(...oneWhere)
  }
  const data = await collection
    .limit(limit)
    .get()
    .then(snap => {
      return snap.docs.map(doc => ({
        ...doc.data(),
        id: doc?.id,
      }))
    })
    .catch(e => handleError(e, collectionURL, query))

  return data
}

export const addDataToDb = async (url, data, dispatch, message, cb) => {
  return await DB.collection(url)
    .add({
      ...data,
    })
    .then(doc => {
      dispatch &&
        dispatch({
          type: "SHOW_ALERT",
          open: true,
          message,
        })
      cb && cb(doc)
      return "success"
    })
    .catch(e => handleError(e, url, data))
}

export const setDataToDb = async (url, docId, data, dispatch, message, cb) => {
  return await DB.collection(url)
    .doc(docId)
    .set(
      {
        ...data,
      },
      { merge: true },
    )
    .then(doc => {
      dispatch &&
        message &&
        dispatch({
          type: "SHOW_ALERT",
          open: true,
          message,
        })
      cb && cb(doc)
      return "success"
    })
    .catch(e => handleError(e, url, data))
}

export const updateDataToDb = async (
  url,
  docId,
  data,
  dispatch,
  message,
  cb,
) => {
  return await DB.collection(url)
    .doc(docId)
    .update({
      ...data,
    })
    .then(doc => {
      dispatch &&
        message &&
        dispatch({
          type: "SHOW_ALERT",
          open: true,
          message,
        })
      cb && cb(doc)
      return "success"
    })
    .catch(err => handleError(err, url, docId))
}

export const uploadFilesToStorage = (files, path = "files") =>
  new Promise((resolve, reject) => {
    console.log("files uploadFilesToStorage", files)
    let paths = []
    if (files && files.length) {
      files.forEach(file => {
        const uploadTask = storage.ref(`${path}/${file.name}`).put(file)
        uploadTask.on(
          "state_changed",
          () => {},
          err => reject(err),
          async () => {
            let fileURL = await uploadTask.snapshot.ref.getDownloadURL()
            paths.push(fileURL)
            if (paths.length === files.length) {
              resolve(paths)
            }
          },
        )
      })
    } else {
      resolve([])
    }
  })

export const useGetUserMeta = () => {
  const [, dispatch] = useStateValue()
  const updateUserMeta = async (uid, data) => {
    let metaRef = DB.collection("users_meta").doc(uid)
    return await metaRef
      .set(
        {
          ...data,
        },
        { merge: true },
      )
      .then(doc => {
        dispatch({
          type: "SET_USER_META",
          userMeta: doc?.data(),
        })
        return "success"
      })
      .catch(e => handleError(e, "users_meta", data))
  }

  let data = {
    updateUserMeta,
  }
  return data
}

export const useGetUserStores = () => {
  const [, dispatch] = useStateValue()

  const updateUserStores = async (uid, storeData) => {
    let storeRef = DB.collection("user_stores").doc(uid)
    return await storeRef
      .set(
        {
          ...storeData,
        },
        { merge: true },
      )
      .then(doc => {
        dispatch({
          type: "GENERAL",
          userStores: doc?.data(),
        })
        return "success"
      })
      .catch(e => handleError(e, "user_stores", storeData))
  }

  let data = {
    updateUserStores,
  }
  return data
}

export const useAddUserAndUserStores = (
  user,
  userMeta,
  dispatch,
  currentStoreId,
) => {
  const [busy, setBusy] = useState(false)

  const handleSubmit = async (url, data, successMessage, afterSave) => {
    setBusy(true)
    try {
      // If currentStoreId is undefined, generate a new ID
      const docRef = currentStoreId
        ? DB.collection(url).doc(currentStoreId)
        : DB.collection(url).doc()

      await docRef.set({ ...data }, { merge: true })

      dispatch &&
        dispatch({
          type: "SHOW_ALERT",
          open: true,
          message: successMessage,
        })

      afterSave && afterSave(docRef)
      return "success"
    } catch (error) {
      console.error(`Error updating ${url}:`, error)
      return "error"
    } finally {
      setBusy(false)
    }
  }

  const addToUserMeta = async (doc, id) => {
    try {
      const ref = DB.collection("users_meta")
        .doc(user?.uid)
        .collection("stores")
        .doc(id)
      await ref.set(doc)
    } catch (error) {
      console.error("Error adding to user meta:", error)
    }
  }

  const handleBusinessInfo = values => {
    console.log("values.......................", values)
    handleSubmit(
      "user_stores",
      {
        businessInfo: { ...values },
        created_by: { user_id: user?.uid, user_name: userMeta?.name },
        status: "validating",
        store_owner: userMeta?.name,
        store_name: values.name,
        created_at: getTime(),
        logo: values.logo,
      },
      "Store business info updated successfully!",
      async doc =>
        await addToUserMeta({ key: doc.id, value: values.name }, doc.id),
    )
  }

  const handleStoreType = values => {
    handleSubmit(
      "user_stores",
      { storeTypeInfo: { ...values, status: "validating" } },
      "Store type updated successfully!",
    )
  }

  const handleStoreAddress = values => {
    handleSubmit(
      "user_stores",
      { storeAddress: { ...values, status: "validating" } },
      "Store address updated successfully!",
    )
  }

  const handleThemeSave = values => {
    handleSubmit(
      "user_stores",
      { theme: { ...values } },
      "Store theme updated successfully!",
    )
  }

  const handleMarketingAd = values => {
    handleSubmit(
      "user_stores",
      {
        marketing: { ...values, status: "Draft" },
        ads: "enable",
      },
      "Marketing info updated successfully!",
    )
  }

  const handleAddProductInfo = values => {
    handleSubmit(
      "user_stores",
      { product: { productInfo: { ...values, status: "validating" } } },
      "Products added successfully!",
    )
  }

  const handleAddPricingStocks = values => {
    handleSubmit(
      "user_stores",
      { product: { pricingStocks: { ...values, status: "validating" } } },
      "Pricing and stocks added successfully!",
    )
  }

  const handleAddDescMedia = values => {
    handleSubmit(
      "user_stores",
      { product: { descriptionMedia: { ...values, status: "validating" } } },
      "Description and media added successfully!",
    )
  }
  return {
    busy,
    handleBusinessInfo,
    handleStoreType,
    handleStoreAddress,
    handleThemeSave,
    handleMarketingAd,
    handleAddProductInfo,
    handleAddPricingStocks,
    handleAddDescMedia,
  }
}

export const useAddStoreProduct = (user, userMeta, dispatch) => {
  const [busy, setBusy] = useState(false)
  const [docId, setDocId] = useState(null) // To store the document ID

  const handleSubmit = async (url, data, successMessage, afterSave) => {
    setBusy(true)
    try {
      // Use the existing docId if available; otherwise, generate a new one
      const docRef = docId
        ? DB.collection(url).doc(docId)
        : DB.collection(url).doc()

      // Save the docId for subsequent calls
      if (!docId) {
        setDocId(docRef.id)
      }

      await docRef.set({ ...data }, { merge: true })

      dispatch &&
        dispatch({
          type: "SHOW_ALERT",
          open: true,
          message: successMessage,
        })

      afterSave && afterSave(docRef)
      return "success"
    } catch (error) {
      console.error(`Error updating ${url}:`, error)
      return "error"
    } finally {
      setBusy(false)
    }
  }

  const handleProductInfo = async (storeId, values) => {
    handleSubmit(
      `products/${storeId}/store_products`,
      {
        productInfo: { ...values },
        created_by: { user_id: user?.uid, user_name: userMeta?.name },
        created_at: getTime(),
        status: "draft",
      },
      "Product information added successfully!",
    )
  }

  const handleProductPricing = async (storeId, values) => {
    handleSubmit(
      `products/${storeId}/store_products`,
      {
        pricing: { ...values },
        updated_at: getTime(),
        status: "draft",
      },
      "Product pricing added successfully!",
    )
  }

  const handleProductMedia = async (storeId, values) => {
    handleSubmit(
      `products/${storeId}/store_products`,
      {
        media: { ...values },
        updated_at: getTime(),
        status: "draft",
      },
      "Product media added successfully!",
    )
  }

  return {
    busy,
    handleProductInfo,
    handleProductPricing,
    handleProductMedia,
  }
}
