import React, { useEffect, useState } from "react"
import { PageLayout } from "appComponents"
import {
  Row,
  Col,
  Card,
  Header,
  Space,
  IconButtonCircle,
  Button,
  Divider,
  NoRecord,
} from "uiComponents"
import "./ThemeModule.css"
import { FormMaker } from "formComponents"
import SpaceBetween from "../../UI/Space/SpaceBetween"
import builderAd from "../../../images/builderAdd.png"
import { BlockPicker } from "react-color"
import GoogleFontFetcher from "../../../theme/font"
import { hexToRgba, isTablet, isMobile } from "helpers"
import { useStateValue } from "state"
import { useAddUserAndUserStores } from "firebaseUtils/firebaseOperations"
import { typeColors } from "shoppio-constants/Colors"
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min"

const ThemeModule = () => {
  const [{ user, userMeta, currentStoreData }, dispatch] = useStateValue()
  const currentStoreId = currentStoreData?.id
  const storeTheme = currentStoreData?.theme
  const [selectedCard, setSelectedCard] = useState(0)
  const [pickerOpenType, setPickerOpenType] = useState(null)

  const location = useLocation()
  const history = useHistory()
  const [showSelectStoreMessage, setShowSelectStoreMessage] = useState(false)

  useEffect(() => {
    const currentPath = location.pathname
    if (currentPath === "/theme/") {
      setShowSelectStoreMessage(false)
    } else {
      setShowSelectStoreMessage(true)
      history.push(`/theme/${currentStoreId}`)
    }
    // eslint-disable-next-line
  }, [location.pathname])

  const themeColors = [
    {
      type: "Primary",
      colorCode: storeTheme?.themeColors?.primary || "#4A67FF",
      blockColor: storeTheme?.themeColors?.primary || "#4A67FF",
    },
  ]
  const utilityColors = [
    {
      type: "Danger",
      colorCode: storeTheme?.utilityColors?.danger || "#BE2C2C",
      blockColor: storeTheme?.utilityColors?.danger || "#BE2C2C",
    },
    {
      type: "Success",
      colorCode: storeTheme?.utilityColors?.success || "#128807",
      blockColor: storeTheme?.utilityColors?.success || "#128807",
    },
    {
      type: "Neutral",
      colorCode: storeTheme?.utilityColors?.neutral || "#6A73A7",
      blockColor: storeTheme?.utilityColors?.neutral || "#6A73A7",
    },
  ]

  const [primaryColor, setPrimaryColor] = useState(
    storeTheme?.themeColors?.primary || "#4A67FF",
  )
  const [dangerColor, setDangerColor] = useState(
    storeTheme?.utilityColors?.danger || "#BE2C2C",
  )
  const [neutralColor, setNeutralColor] = useState(
    storeTheme?.utilityColors?.neutral || "#FFFFFF",
  )
  const [successColor, setSuccessColor] = useState(
    storeTheme?.utilityColors?.success || "#128807",
  )
  const [primaryFont, setPrimaryFont] = useState(
    storeTheme?.fontFamily?.primary || "",
  )
  const [secondaryFont, setSecondaryFont] = useState(
    storeTheme?.fontFamily?.secondary || "",
  )
  const [isRounded, setIsRounded] = useState(storeTheme?.isRounded === true)

  const handleSelect = index => {
    setSelectedCard(index)
    setIsRounded(index === 0)
  }

  const handleColorChange = (type, color) => {
    if (type === "Primary") {
      setPrimaryColor(color.hex)
    } else if (type === "Danger") {
      setDangerColor(color.hex)
    } else if (type === "Neutral") {
      setNeutralColor(color.hex)
    } else if (type === "Success") {
      setSuccessColor(color.hex)
    }
  }

  const handleSaveApply = () => {
    const themeValues = {
      themeColors: {
        primary: primaryColor,
      },
      utilityColors: {
        danger: dangerColor,
        success: successColor,
        neutral: neutralColor,
      },
      fontFamily: {
        primary: primaryFont,
        secondary: secondaryFont,
      },
      isRounded: isRounded,
    }

    // console.log("Saved Theme Values: ", themeValues)
    handleSaved(themeValues)
  }

  const { busy, handleThemeSave } = useAddUserAndUserStores(
    user,
    userMeta,
    dispatch,
    currentStoreId,
  )

  const handleSaved = values => {
    handleThemeSave(values)
  }
  const fields = [
    {
      name: "primary_font",
      label: "Primary Font",
      type: "select",
      value: storeTheme?.fontFamily?.primary || "",
      onChange: e => setPrimaryFont(e.target.value),
      children: [
        "Roboto",
        "Open Sans",
        "Montserrat",
        "Lato",
        "Poppins",
        "Source Sans Pro",
        "Raleway",
        "Noto Sans",
        "Inter",
        "Roboto Slab",
        "Merriweather",
        "Playfair Display",
      ].map(op => (
        <option key={op} value={op}>
          {op}
        </option>
      )),
    },
    {
      name: "secondary_font",
      label: "Secondary Font",
      type: "select",
      value: storeTheme?.fontFamily?.secondary || "",
      onChange: e => setSecondaryFont(e.target.value),
      children: [
        "Roboto",
        "Open Sans",
        "Montserrat",
        "Lato",
        "Poppins",
        "Source Sans Pro",
        "Raleway",
        "Noto Sans",
        "Inter",
        "Roboto Slab",
        "Merriweather",
        "Playfair Display",
      ].map(op => (
        <option key={op} value={op}>
          {op}
        </option>
      )),
    },
  ]

  return (
    <PageLayout
      mainContent={
        <Row>
          <Col sm={12} md={12}>
            {showSelectStoreMessage && (
              <Card>
                <Row>
                  <Col xs={12} sm={12} md={12} lg={8} xl={8}>
                    <Header sm bold>
                      Theme & Customisation
                    </Header>
                    <p>Customize your account to start selling on Shoppio.</p>
                    <Space lg />
                    <Header accent sm>
                      Theme
                    </Header>
                    <Space lg />
                    {themeColors.map((color, index) => (
                      <React.Fragment key={index}>
                        <ColorCard
                          type={color?.type}
                          colorCode={color?.colorCode}
                          blockColor={color?.blockColor}
                          onColorChange={handleColorChange}
                          isPickerOpen={pickerOpenType === color?.type}
                          setPickerOpen={setPickerOpenType}
                        />
                        <Space lg />
                      </React.Fragment>
                    ))}
                    <Header accent sm>
                      Utility
                    </Header>
                    <Space lg />
                    <Row style={{ padding: "0px 8px", gap: "30px" }}>
                      {utilityColors.map((color, index) => (
                        <ColorCard
                          key={index}
                          type={color.type}
                          colorCode={color.colorCode}
                          blockColor={color.blockColor}
                          onColorChange={handleColorChange}
                          isPickerOpen={pickerOpenType === color?.type}
                          setPickerOpen={setPickerOpenType}
                        />
                      ))}
                    </Row>
                    <Space lg />
                    <Header accent sm>
                      Font Family
                    </Header>
                    <Space />
                    <FormMaker
                      fields={fields}
                      hideSubmit
                      initialValues={storeTheme?.fontFamily}
                    />
                    <Header accent sm>
                      Corners
                    </Header>
                    <Space lg />
                    <Row style={{ padding: "0px 8px", gap: "30px" }}>
                      {[true, false].map((isRoundedValue, index) => (
                        <CornersCard
                          key={index}
                          isRounded={isRoundedValue}
                          isSelected={selectedCard === index}
                          onSelect={() => handleSelect(index)}
                        />
                      ))}
                    </Row>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                    <Header sm bold>
                      Preview
                    </Header>
                    <Space lg />
                    <TemplatePreview
                      primaryColor={primaryColor}
                      dangerColor={dangerColor}
                      neutralColor={neutralColor}
                      successColor={successColor}
                      primaryFont={primaryFont}
                      secondaryFont={secondaryFont}
                      isRounded={isRounded}
                    />
                  </Col>
                </Row>
                <Space lg />
                <Button onClick={handleSaveApply} busy={busy}>
                  Save & Apply
                </Button>
                <Button
                  onClick={() => history.push(`/store/${currentStoreId}`)}
                >
                  Preview Your Store
                </Button>
                <Divider fullWidth />
                <StoreBuilderAd />
              </Card>
            )}

            {!showSelectStoreMessage && (
              <>
                <Space md />
                <NoRecord type={"general"} svg={"shop_one"}>
                  <Header md bold>
                    Please select a store to customize your theme
                  </Header>
                  <Space />
                </NoRecord>
              </>
            )}
          </Col>
        </Row>
      }
    />
  )
}

export default ThemeModule

const ColorCard = ({
  type,
  blockColor,
  onColorChange,
  isPickerOpen,
  setPickerOpen,
}) => {
  const [selectedColor, setSelectedColor] = useState(blockColor)

  const handleClick = () => setPickerOpen(isPickerOpen ? null : type)

  const handleChange = color => {
    setSelectedColor(color.hex)
    onColorChange(type, color)
    setPickerOpen(null)
  }

  const colors = typeColors[type] || typeColors.neutral

  return (
    <div style={{ position: "relative" }}>
      <Header bold>{type}</Header>
      <div className='color-card-container' onClick={handleClick}>
        <div
          className='color-block'
          style={{ backgroundColor: selectedColor }}
        />
        <div className='color-code'>{selectedColor}</div>
      </div>
      {isPickerOpen && (
        <div style={{ position: "absolute", zIndex: "2", top: "100%" }}>
          <Space />
          <BlockPicker
            colors={colors}
            color={selectedColor}
            onChange={handleChange}
          />
        </div>
      )}
    </div>
  )
}

const CornersCard = ({ isRounded, onSelect }) => {
  const [{ currentStoreData }] = useStateValue()
  const storeTheme = currentStoreData?.theme

  const isSelected = storeTheme?.isRounded === isRounded

  return (
    <div
      className={`corners-card-container ${isSelected ? "selected" : ""}`}
      onClick={onSelect}
    >
      <SpaceBetween>
        <Header sm>{isRounded ? "Rounded" : "Square"}</Header>
        <i
          className={`${
            isSelected ? "fas fa-check-circle" : "far fa-circle"
          } check-icon`}
        />
      </SpaceBetween>
      <div
        className={`corners-block ${isRounded ? "rounded" : "square"} ${
          isSelected ? "selected-block" : ""
        }`}
      ></div>
    </div>
  )
}

const TemplatePreview = ({
  primaryColor,
  dangerColor,
  successColor,
  neutralColor,
  primaryFont,
  secondaryFont,
  isRounded,
}) => {
  // // console.log(
  //   "TemplatePreview...",
  //   primaryColor,
  //   dangerColor,
  //   successColor,
  //   neutralColor,
  //   primaryFont,
  //   secondaryFont,
  //   isRounded,
  // )
  const backgroundColor = hexToRgba(neutralColor, 0.1)
  return (
    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
      <div className='template-preview-container'>
        <div
          className={`template-preview ${
            isRounded ? "rounded-corners" : "square-corners"
          }`}
        >
          <div className='template-preview-content'>
            <div className='template-image-container'>
              <img
                src='https://www.bobswatches.com/rolex-blog/wp-content/uploads/2017/01/Green-Gold-Rolex-GMT-Master.jpg'
                alt='Watch Preview'
                style={{ width: "100%", height: "auto" }}
                className={isRounded ? "rounded-corners-img" : "square-corners"}
              />
              <div
                className={`out-of-stock-label ${
                  isRounded ? "rounded-corners" : "square-corners"
                }`}
                style={{ color: dangerColor, fontFamily: secondaryFont }}
              >
                Out of Stock
              </div>
            </div>
            <Space lg />
            <div className='template-details-container'>
              <div>
                <SpaceBetween>
                  <Header lg bold style={{ fontFamily: primaryFont }}>
                    Rollex Grandior 2024
                  </Header>
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      top: "-8px",
                    }}
                  >
                    <i
                      className='fas fa-certificate'
                      style={{ fontSize: "40px", color: successColor }}
                    ></i>
                    <span className='discount-percentage-text'>50% off</span>
                  </div>
                </SpaceBetween>
                <p style={{ fontSize: "16px", fontFamily: secondaryFont }}>
                  A great watch, stylish good very chrome black finish,
                  available for exchange...
                </p>
              </div>
              {primaryFont && (
                <GoogleFontFetcher
                  href={`https://fonts.googleapis.com/css2?family=${primaryFont.replace(
                    / /g,
                    "+",
                  )}&display=swap`}
                />
              )}
              {secondaryFont && (
                <GoogleFontFetcher
                  href={`https://fonts.googleapis.com/css2?family=${secondaryFont.replace(
                    / /g,
                    "+",
                  )}&display=swap`}
                />
              )}
              <Space lg />
              <div
                className={`category-tag ${
                  isRounded ? "rounded-corners" : "square-corners"
                }`}
                style={{
                  color: neutralColor,
                  backgroundColor: backgroundColor,
                }}
              >
                watch
              </div>
              <Space lg />
              <div>
                <SpaceBetween style={{ marginTop: "5px" }}>
                  <Header
                    noMar
                    lg
                    bold
                    style={{
                      color: primaryColor,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    ₹ 4,30,000
                  </Header>
                  <div className='template-actions-container'>
                    <IconButtonCircle large size='20' icon='heart' />
                    {!isTablet && (
                      <div
                        className={`add-to-cart-button ${
                          isRounded
                            ? "rounded-corners-button"
                            : "square-corners"
                        }`}
                        style={{
                          backgroundColor: primaryColor || "#4A67FF",
                          fontFamily: secondaryFont,
                        }}
                      >
                        Add to Cart
                      </div>
                    )}
                  </div>
                </SpaceBetween>
                {isTablet && (
                  <div
                    className={`add-to-cart-button ${
                      isRounded ? "rounded-corners-button" : "square-corners"
                    }`}
                    style={{
                      backgroundColor: primaryColor,
                      fontFamily: secondaryFont,
                    }}
                  >
                    Add to Cart
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Col>
  )
}

const StoreBuilderAd = () => (
  <Card extraPad notFull noXS>
    <Row>
      <Col sm={12} md={8}>
        <Header lg bold>
          Launching Shoppio Store Builder
        </Header>
        <p style={{ fontSize: "24px" }}>
          Make your store unique by changing the layout
          <br />
          using our powerful store builder
        </p>
        <Space lg />
        <Button fullWidth={isMobile}>Launch Builder</Button>
        <Button fullWidth={isMobile} accent>
          Learn More
        </Button>
      </Col>
      <Col
        sm={12}
        md={4}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img src={builderAd} alt='Store Builder Ad' />
      </Col>
    </Row>
  </Card>
)
