import { removeDuplicate } from "helpers"
import { Cache } from "cache"
import { CacheKeys } from "shoppio-constants"

export const initialState = {
  basket: localStorage.getItem("shoppio_cart")
    ? JSON.parse(localStorage.getItem("shoppio_cart"))
    : [],
  user: null,
  appBusy: false,
  alert: null,
  firebaseLastRow: {},
  currentStoreID: Cache.get(CacheKeys.selectedStoreId, true),
  busyProcesses: [],
  pricingModels: {},
  currentStoreProducts: {},
}

// Selector
export const getBasketTotal = basket =>
  basket?.reduce((amount, item) => {
    let santisePrice = item.price
    const sellingPrice = santisePrice - (item?.discount / 100) * santisePrice
    return Number(sellingPrice * item.quantity) + Number(amount)
  }, 0)
export const getBasketItems = basket =>
  basket?.reduce((amount, item) => {
    return Number(item.quantity) + Number(amount)
  }, 0)

const reducer = (state, action) => {
  // console.log('Action:', action.type, action)
  switch (action.type) {
    case "SET_USER_ORDERS":
      return {
        ...state,
        currentUserOrders: action.currentUserOrders,
      }
    case "SET_CURRENT_STORE_ID":
      return {
        ...state,
        currentStoreID: action.currentStoreID,
      }
    case "SELECT_ADDRESS":
      return {
        ...state,
        orderAddress: action.orderAddress,
      }
    case "SET_USER_META":
      return {
        ...state,
        userMeta: action.userMeta,
      }
    case "SET_DELIVERY_PERSONS":
      return {
        ...state,
        deliveryPersons: action.deliveryPersons,
      }
    case "GET_USER_STORES":
      return {
        ...state,
        userStores: action.userStores,
      }
    case "CLEAR_STORES":
      return {
        ...state,
        generalStores: [],
      }
    case "SET_FIRESTORE_LAST":
      return {
        ...state,
        firebaseLastRow: {
          ...state.firebaseLastRow,
          ...action.firebaseLastRow,
        },
      }
    case "GET_GENERAL_STORES":
      return {
        ...state,
        generalStores: state.generalStores
          ? [...state.generalStores, ...action.generalStores]
          : action.generalStores,
      }
    case "SET_STORE_PRODUCTS":
      return {
        ...state,
        storeProducts: state.storeProducts
          ? removeDuplicate(
              [...state.storeProducts, ...action.storeProducts],
              "id",
            )
          : action.storeProducts,
      }
    case "COLLECTION_RENDERER_SET":
      let oldRows = state[action.reducerVar] || []
      let newRows =
        oldRows && oldRows.length > 0
          ? removeDuplicate([...oldRows, ...action[action.reducerVar]], "id")
          : action[action.reducerVar]
      let newData = {}
      // console.log("COLLECTION_RENDERER_SET", action);
      newData[action.reducerVar] = newRows
      return { ...state, ...newData }
    case "COLLECTION_RENDERER_REPLACE":
      let temp = {}
      // console.log("COLLECTION_RENDERER_SET", action);
      temp[action.reducerVar] = action.newRows
      return { ...state, ...temp }

    case "SET_USER_PINCODE":
      return {
        ...state,
        userPincode: action.userPincode,
      }
    case "SET_STORE_PINCODE":
      return {
        ...state,
        storePincodes: action.storePincodes,
      }
    case "CLEAR":
      let state2 = { ...state }
      state2[action.clearVar] = []
      state2.firebaseLastRow[action.clearVar] = null
      // console.log('State2', state)
      return {
        ...state2,
      }
    case "GET_STORE_CATEGORIES":
      return {
        ...state,
        storeCategories: action.storeCategories,
      }
    case "GET_STORE_SUBCATEGORIES":
      // console.log("storeSubCategories", action.storeSubCategories);
      return {
        ...state,
        storeSubCategories: action.storeSubCategories,
      }
    case "SET_CURRENT_STORE":
      return {
        ...state,
        currentStore: action.currentStore,
      }
    case "SET_CURRENT_CATEGORY":
      return {
        ...state,
        currentCategory: action.currentCategory,
      }
    case "SET_CURRENT_SUBCATEGORY":
      return {
        ...state,
        currentSubcategory: action.currentSubcategory,
      }

    case "SET_BUSY":
      return {
        ...state,
        appBusy: true,
        busyMessage: action.message,
      }
    case "UNSET_BUSY":
      return {
        ...state,
        appBusy: false,
        busyMessage: null,
      }
    case "SET_USER":
      return {
        ...state,
        user: action.user,
        userMeta: action.user ? state.userMeta : {},
      }
    case "ADD_TO_BASKET":
      let payload = [...state.basket, action.item]
      Cache.set(CacheKeys.shoppioCart, payload)
      return {
        ...state,
        basket: payload,
      }
    case "EMPTY_BASKET":
      localStorage.setItem("shoppio_cart", JSON.stringify([]))
      return {
        ...state,
        basket: [],
      }
    case "FETCH_ENTITIES":
      let entities = state["entities"] || {}
      entities[action.reducerVar] = action.data
      return { ...state, entities }

    case "REMOVE_FROM_BASKET":
      const index = state.basket.findIndex(
        basketItem => basketItem.id === action.id,
      )
      let newBasket = [...state.basket]
      // console.log('Index', index, newBasket)
      if (index >= 0) {
        newBasket.splice(index, 1)
      } else {
        console.warn(
          `Can't remove product (id: ${action.id}) as it's not in basket!`,
        )
      }
      localStorage.setItem("shoppio_cart", JSON.stringify(newBasket))
      return {
        ...state,
        basket: newBasket,
      }
    case "UPDATE_PRODUCT":
      let product = state.basket.find(x => x.id === action.id)
      let message = {}
      let newProduct = { ...product }
      if (action.sign === "plus") {
        if (
          newProduct.quantity > 0 &&
          newProduct.quantity <
            (Number(newProduct.maxQuantity) || Number(newProduct.stock))
        ) {
          newProduct.quantity++
        } else {
          message = {
            alert: {
              open: true,
              alertType: "error",
              message:
                "Sorry! You can only add " +
                newProduct.quantity +
                " of these item.",
            },
          }
        }
      } else if (action.sign === "minus") {
        newProduct.quantity > 1 && --newProduct.quantity
      }
      let updatedBasket = state.basket.map((p, k) =>
        p.id === action.id ? newProduct : p,
      )
      // console.log("updatedBasket", newProduct, updatedBasket, message)

      localStorage.setItem("shoppio_cart", JSON.stringify(updatedBasket))
      return {
        ...state,
        ...message,
        basket: updatedBasket,
      }
    case "SHOW_ALERT":
      // console.log('reducer', action);
      return {
        ...state,
        alert: { ...action },
      }
    case "HIDE_ALERT":
      return {
        ...state,
        alert: null,
      }
    case "GET_ALL_CATEGORIES":
      return {
        ...state,
        availableStoreCategories: action.availableStoreCategories,
      }
    case "GET_ALL_SUBCATEGORIES":
      return {
        ...state,
        availableStoreSubcategories: action.availableStoreSubcategories,
      }
    case "UPDATE_PWA_PROMPTS":
      return {
        ...state,
        hidePWAWarning: { ...state.hidePWAWarning, ...action.hidePWAWarning },
      }
    case "SET_SUPER_ADMIN":
      return {
        ...state,
        isSuperAdmin: action?.isSuperAdmin,
      }
    case "GENERAL":
      return {
        ...state,
        ...action?.data,
      }
    case "CLEAR_BASKET":
      return {
        ...state,
        basket: [],
      }
    case "SET_BASKET":
      return {
        ...state,
        basket: action.basket,
      }
    case "SELECT_COUPON_CODE":
      return {
        ...state,
        appliedCoupon: action.couponCode,
      }

    case "BUY_NOW":
      let newCart = [...state.basket, action.item]

      localStorage.setItem("shoppio_cart", JSON.stringify(newCart))

      return {
        ...state,
        basket: newCart,
      }
    case "OPEN_PRIMARY_MENU":
      return {
        ...state,
        isPrimaryMenuActive: true,
        isFilterMenuOpen: false,
      }
    case "CLOSE_PRIMARY_MENU":
      return {
        ...state,
        isPrimaryMenuActive: false,
      }
    case "OPEN_FILTER_MENU":
      return {
        ...state,
        isFilterMenuOpen: true,
        isPrimaryMenuActive: false,
      }
    case "CLOSE_FILTER_MENU":
      return {
        ...state,
        isFilterMenuOpen: false,
      }
    case "LOGOUT":
      return {
        ...state,
        user: null,
        userMeta: {},
        basket: [],
        orderAddress: null,
        currentUserOrders: [],
        deliveryPersons: [],
        appBusy: false,
        alert: null,
      }

    case "SET_PRICING_MODELS":
      return {
        ...state,
        pricingModels: action.data.pricingModels,
      }

    case "SET_CURRENT_STORE_PRODUCTS":
      return {
        ...state,
        currentStoreProducts: action.data.currentStoreProducts,
      }

    case "SET_BUSY_PROCESS":
      let processes = [...state.busyProcesses]
      processes.push(action.process)
      return {
        ...state,
        busyProcesses: processes,
      }
    case "UNSET_BUSY_PROCESS":
      let filteredProcesses = [...state.busyProcesses].filter(
        process => process.id !== action.process.id,
      )
      return {
        ...state,
        busyProcesses: filteredProcesses,
      }

    default:
      return { ...state, ...action?.data }
  }
}

export default reducer
