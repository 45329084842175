import { getDocFromDb, handleError } from "firebaseUtils"
import { useEffect, useState } from "react"
import { processes } from "shoppio-constants"
import { getColorScheme } from "shoppio-constants/Colors"
// import { generateColorVariants } from "shoppio-constants/Colors"
import { useStateValue } from "state"

export const useGetStoreData = () => {
  const [{ currentStoreID }, dispatch] = useStateValue()
  const [busy, setBusy] = useState(true)

  const getStoreDataFromFirestore = async docId => {
    try {
      dispatch({
        type: "SET_BUSY_PROCESS",
        process: processes.GET_STORE_DATA,
      })
      const res = await getDocFromDb("user_stores", docId)
      if (res.exists) {
        const data = { ...res }
        dispatch({
          data: {
            currentStoreData: data,
          },
        })
      } else {
        handleError(res)
      }
    } catch (error) {
      handleError(error)
    } finally {
      setBusy(false)
      setTimeout(
        dispatch({
          type: "UNSET_BUSY_PROCESS",
          process: processes.GET_STORE_DATA,
        }),
        5000,
      )
    }
  }

  useEffect(() => {
    if (currentStoreID) {
      setBusy(true)
      getStoreDataFromFirestore(currentStoreID)
    } else {
      setBusy(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStoreID])

  return {
    busy,
  }
}

export const useGetSetFromStoreData = () => {
  const [state] = useStateValue()
  const currentStoreData = state?.currentStoreData || state?.currentStore
  console.log("currentStoreData", currentStoreData)
  const getCSSFromStoreData = () => {
    const { theme } = currentStoreData
    console.log("....theme", theme)

    const colorVariants = getColorScheme(
      theme?.themeColors?.primary || "default",
    )
    console.log("colorVariants", colorVariants)
    const formatFontFamily = fontFamily => {
      return fontFamily?.split(" ").join("+") || ""
    }
    const primaryFont = theme?.fontFamily?.primary
    const secondaryFont = theme?.fontFamily?.secondary

    const formattedPrimaryFont = formatFontFamily(primaryFont)
    const formattedSecondaryFont = formatFontFamily(secondaryFont)
    return `
    @import url('https://fonts.googleapis.com/css2?family=${formattedPrimaryFont}:wght@600&display=swap');
    ${
      secondaryFont
        ? `@import url('https://fonts.googleapis.com/css2?family=${formattedSecondaryFont}:wght@600&display=swap');`
        : ""
    }

    .theme-light {
        --color-primary: ${theme?.themeColors?.primary} !important;
        --theme-color: ${theme?.themeColors?.primary} !important;
        --font-one: ${primaryFont} !important;
        --font-two: ${secondaryFont || "inherit"} !important;
        --radius: ${theme?.isRounded === false && "0px"} !important;
        --background: ${colorVariants?.background} !important;
        --foreground: ${colorVariants?.foreground} !important;
        }
    .theme-dark {
        --color-primary: ${theme?.themeColors?.primary} !important;
        --theme-color: ${theme?.themeColors?.primary} !important;
        --font-one: ${primaryFont} !important;
        --font-two: ${secondaryFont || "inherit"} !important;
        --radius: ${theme?.isRounded === false && "0px"} !important;
    }
`
  }
  const setCSSFromStoreData = () => {
    const style = document.createElement("style")
    style.id = currentStoreData?.id
    style.innerHTML = getCSSFromStoreData()
    document.head.appendChild(style)
  }
  useEffect(() => {
    if (currentStoreData) {
      setCSSFromStoreData()
    }
    return () => {
      const style = document.getElementById(currentStoreData?.id)
      if (style) {
        // style.remove()
        document.head.removeChild(style)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStoreData])
}
