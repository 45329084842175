import React, { useState } from "react"
import {
  Col,
  Row,
  Card,
  Header,
  Space,
  IconButton,
  IconButtonCircle,
  Dialog,
} from "uiComponents"
import { PageLayout } from "appComponents"
import SpaceBetween from "../../UI/Space/SpaceBetween"
import { FormMaker } from "../../Forms"
import { cardData, fields, paymentsMethodData } from "./Schema"
import BillingCard from "./BillingCard"
import { ProvidersLogo } from "../ShippingLogistics/ProvidersLogo"
import "./PricingBilling.css"
import getSVG from "../../../images/svgs/svg"
import PriceCard from "../../../pages/Upgrade/PriceCard"
import { useStateValue } from "state"

const PricingBilling = () => {
  const [selectedPlan, setSelectedPlan] = useState("Pro")
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null)
  const [totalAmount, setTotalAmount] = useState(1800)
  const [dialog, setDialog] = useState(false)
  const [planType, setPlanType] = useState("Pro")
  const [{ pricingModels }] = useStateValue()
  console.log("pricingModels........", pricingModels)

  const handleSelectPlan = title => {
    setSelectedPlan(title)
    if (title === "Pro") {
      setTotalAmount(1800)
    } else if (title === "Elite") {
      setTotalAmount(3000)
    }
  }

  const handleDialogOpen = type => {
    setPlanType(type) // Set plan type for the dialog
    setDialog(true)
  }

  const handleDialogClose = () => setDialog(false)

  return (
    <PageLayout
      mainContent={
        <Row>
          <Col sm={12} md={9}>
            <Row>
              {cardData.map((data, index) => (
                <Col sm={12} md={6} key={index}>
                  <PriceCardLite
                    data={data}
                    isSelected={data.title === selectedPlan}
                    onSelectPlan={handleSelectPlan}
                    onClick={handleDialogOpen} // Pass function reference
                  />
                </Col>
              ))}
              <Col sm={12} md={12}>
                <Card>
                  <Header bold sm>
                    Please provide your billing details
                  </Header>
                  <p>
                    Fill in the necessary information to complete your purchase.
                  </p>
                  <Space lg />
                  <FormMaker hideSubmit fields={fields} validateOnMount />
                </Card>
              </Col>
              <Col sm={12} md={12}>
                <Card
                  svgIcon={getSVG(
                    "Wallet-cuate",
                    { width: "100%", height: "20rem" },
                    null,
                    true,
                  )}
                >
                  <Header bold sm>
                    Payments Methods
                  </Header>
                  <p>Choose your preferred payment method</p>
                  <Space lg />
                  <Row>
                    {paymentsMethodData.map((data, index) => (
                      <Col sm={12} md={6} key={index}>
                        <PaymentMethods
                          data={data}
                          isSelected={data.label === selectedPaymentMethod}
                          onSelectPaymentMethod={setSelectedPaymentMethod}
                        />
                      </Col>
                    ))}
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={3}>
            <BillingCard
              planName={selectedPlan === "Pro" ? "Pro Plan" : "Elite Plan"}
              price={totalAmount}
              startDate={`${new Date().toLocaleDateString()}`}
              endDate={`${new Date(
                new Date().setMonth(new Date().getMonth() + 1),
              ).toLocaleDateString()}`}
            />
          </Col>
          <Dialog
            closeOnOverlay
            fitContent
            right
            open={dialog}
            onClose={handleDialogClose}
          >
            {planType === "Pro" ? (
              <>
                <Header lg bold>
                  Pro Plan Details
                </Header>
                <PriceCard planType='Pro' planPrice='1800' />
              </>
            ) : (
              <>
                <Header lg bold>
                  Elite Plan Details
                </Header>
                <PriceCard elite planType='Elite' planPrice='3000' />
              </>
            )}
          </Dialog>
        </Row>
      }
    />
  )
}

export default PricingBilling

const PriceCardLite = ({ data, isSelected, onSelectPlan, onClick }) => {
  return (
    <Card themed={data.title === "Elite"}>
      <SpaceBetween>
        <div style={{ display: "flex" }}>
          <Header themed={data.title === "Elite"} sm bold>
            {data.title}
          </Header>
          <IconButtonCircle
            small
            icon='chevron-right'
            ml='10'
            onClick={() => onClick(data.title)} // Pass the plan title
          />
        </div>
        <Header themed={data.title === "Elite"} sm>
          {data.price}/month
        </Header>
      </SpaceBetween>
      <Space />
      <ul>
        {data.features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>

      <IconButton
        style={{ position: "absolute", right: "0", bottom: "0" }}
        selected={isSelected}
        small
        icon='check'
        onClick={() => onSelectPlan(data.title)}
      >
        {isSelected ? "Selected" : "Select"}
      </IconButton>
    </Card>
  )
}

const PaymentMethods = ({ data, isSelected, onSelectPaymentMethod }) => {
  return (
    <div
      className={`payment-method-card`}
      onClick={() => onSelectPaymentMethod(data.label)}
    >
      <Card className={`${isSelected ? "selected" : ""}`} lowPadTop>
        <SpaceBetween>
          <Header bold>{data.label}</Header>
          {
            <i
              className={`${
                isSelected ? "fas fa-check-circle" : "far fa-circle"
              } selected-icon`}
            />
          }
        </SpaceBetween>
        <Space />
        <SpaceBetween>
          <small>{data.desc}</small>
          <ProvidersLogo sm logo={data.logo} />
        </SpaceBetween>
      </Card>
    </div>
  )
}
