import React, { useEffect } from "react"
import { Col, Grid, Row, CompactCard } from "uiComponents"
import { useHistory, useParams } from "react-router-dom"
import AdminQuickLinks from "../AdminComponents/AdminQuickLinks"
import { CollectionRenderer, DocRenderer } from "firestoreComponents"
import { getDocFromDb } from "firebaseUtils"
import { isMobile } from "helpers"
import { useStateValue } from "state"
import { Footer } from "appComponents"
import "../../styles/theme.css"
import ProductList from "./ProductList"
import { hasAdminAccessToStore } from "appComponents"
import { AppPaths, getPath } from "shoppio-constants"
import { useGetSetFromStoreData } from "hooks/Store"

export const getStoreData = async (id, dispatch) => {
  dispatch({
    type: "SET_CURRENT_STORE_ID",
    currentStoreID: id,
  })
  dispatch({
    type: "SET_CURRENT_STORE",
    currentStore: await getDocFromDb(`user_stores`, id),
  })
}
function StoreHome() {
  const [{ userMeta, user, isSuperAdmin, currentStore }, dispatch] =
    useStateValue()
  console.log("user.........", user)
  const history = useHistory()
  const { id } = useParams()
  const storeID = id
  useGetSetFromStoreData()
  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch({
      type: "SET_CURRENT_STORE",
      currentStore: {},
    })
    if (storeID) getStoreData(storeID, dispatch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  console.log("Store", currentStore)
  const ownsThisStore = isSuperAdmin || hasAdminAccessToStore(userMeta, storeID)
  return (
    <div className='Home'>
      <div className='home__container'>
        <Grid>
          <Row center='xs'>
            <Col xs={12} md={12} style={{ padding: isMobile ? "" : 0 }}>
              <DocRenderer
                collectionPath={`user_stores`}
                docID={storeID}
                loaderType='store'
                renderDoc={data => {
                  const {
                    store_name,
                    desc,
                    store_pic,
                    store_tagline,
                    store_logo,
                    store_link,
                    ownedBy,
                    theme_color,
                  } = data || {}
                  console.log("color.....", theme_color)
                  return (
                    <>
                      <CompactCard
                        config={{
                          ...data,
                          showComment: !ownsThisStore,
                          innerStyle: { width: "100%" },
                          logo: store_logo,
                          version: "store-new",
                          extraClass: "store-home",
                          title: store_name,
                          subtitle: store_tagline,
                          backgroundImage: store_pic,
                          ownedBy: ownedBy,
                          themeColor: theme_color,
                          desc: desc,
                          shareLink: {
                            name: store_name,
                            link: store_link
                              ? "https://shoppio.life/" + store_link
                              : "https://shoppio.life/store/" + storeID,
                          },
                        }}
                      />
                    </>
                  )
                }}
              />
            </Col>
          </Row>
          {ownsThisStore ? (
            <AdminQuickLinks
              storeID={storeID}
              type='store'
              storeType={currentStore?.type_of_store}
            />
          ) : (
            ""
          )}
        </Grid>
        <Grid>
          <CollectionRenderer
            noReload
            paddedTop
            horizontal
            limit={10}
            loaderType='category'
            collectionPath={`store_categories/${storeID}/data`}
            viewType={"custom"}
            uppercaseTitle='Browse by categories'
            noDocMessage={ownsThisStore ? "No store categories yet." : null}
            renderDoc={({ id, data }) => {
              const { category_name, desc, category_pic, category_logo } =
                data || {}
              return (
                <CompactCard
                  key={id}
                  config={{
                    logo: category_logo,
                    version: "category-new",
                    title: category_name,
                    subtitle: desc,
                    backgroundImage: category_pic,
                    action: {
                      label: "View Category",
                      onClick: () => {
                        history.push({
                          pathname: getPath(AppPaths.CATEGORY_HOME, {
                            storeid: storeID,
                            category_id: id,
                          }),
                        })
                      },
                    },
                  }}
                />
              )
            }}
          />
        </Grid>
        {(!user || (user && userMeta)) && (
          <ProductList storeID={storeID} isAdmin={ownsThisStore} />
        )}
      </div>
      <Footer />
    </div>
  )
}

export default StoreHome
