import React, { useState } from "react"
import { Space, Button, Card, Dialog } from "uiComponents"
import EliteBadge from "./EliteBadge"
import Plans from "./Plans"
import "./PriceCard.css"

const PriceCard = ({ planType, planPrice, elite, style }) => {
  const [open, setOpen] = useState()

  const proFeatures = [
    "Store Website",
    "Theming",
    "Mobile App",
    "Customer Message Module",
    "User & Permission",
    "Bulk product upload",
  ]

  const eliteFeatures = [
    "Coupon Codes",
    "Ads Module",
    "Custom Domain",
    "Product AR View",
  ]

  const allFeature = elite ? proFeatures.concat(eliteFeatures) : proFeatures

  return (
    <>
      <Card
        style={{
          ...style,
        }}
        className='price-card-container'
        fullHeight
        themed={elite}
        noXS
      >
        {elite ? <EliteBadge /> : ""}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <div>
            <h2
              style={{
                color: elite ? "var(--background)" : "",
                fontFamily: "var(--font-two)",
              }}
              sm
            >
              {planType}
            </h2>
            <h1
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "44px",
                color: elite ? "var(--background)" : "",
                fontFamily: "var(--font-two)",
              }}
            >
              ₹{planPrice}
              <p
                style={{
                  marginLeft: "5px",
                  marginTop: "5px",
                  fontSize: "25px",
                  fontFamily: "var(--font-two)",
                }}
              >
                / Month
              </p>
            </h1>
            <p
              style={{
                color: elite ? "var(--background)" : "",
              }}
            >
              If you a small seller who want to digitize your store and increase
              sales
            </p>
            <h1
              style={{
                color: elite ? "var(--background)" : "",
                fontFamily: "var(--font-two)",
              }}
            >
              Features
            </h1>
            <ul style={{ fontSize: "16px", fontWeight: "500" }}>
              {allFeature.map((item, index) => (
                <li key={index}>{item}</li>
              ))}
            </ul>
          </div>

          <div>
            <Space lg />
            <Button
              style={{
                backgroundColor: elite ? "var(--background)" : "",
                color: elite ? "var(--theme-color)" : "",
              }}
              fullRound
              fullWidth
              onClick={() => setOpen(true)}
            >
              Upgrade
            </Button>
          </div>
        </div>
      </Card>
      <Dialog big open={open} onClose={() => setOpen()}>
        <Plans />
      </Dialog>
    </>
  )
}

export default PriceCard
