import { Space, Card, Header } from "uiComponents"
import { getSVG } from "getSVG"
import logo from "../../images/Icon.png"

const Summary = () => {
  return (
    <>
      <Card notFull noXS>
        <img src={logo} width={25} alt='logo' style={{ marginBottom: 10 }} />
        <Space />
        <Header lg>
          <h2
            style={{
              fontFamily: "var(--font-two)",
            }}
          >
            Boost
          </h2>{" "}
          your business with Shoppio
        </Header>

        <p>
          Choose plan that works for your business, feel free to contact us if
          you need more details
        </p>
        <Space />
        <Card>
          <b>Limited time offer</b>
          <p>We are offering for first 100 stores</p>
          <Space />
          <Header>150 SKUs upload</Header>
        </Card>
        <Space lg />
        <div style={{ textAlign: "center" }}>
          {getSVG("Discounts", { width: 250 }, null, true)}
        </div>
      </Card>
    </>
  )
}

export default Summary
