import React, { useCallback, useState } from "react"
import ShoppioLogo from "../../../images/JustLogoWhite.png"
import { Link, useLocation, useHistory } from "react-router-dom"
import { IconButton, Jumbotron, Loader } from "uiComponents"
import { useIsScrolled } from "hooks"
import { useStateValue } from "state"
import { isMobile } from "helpers"
import { Drawer, UserNotifications } from "appComponents"
import { Dialog } from "uiComponents"
import "./Navigation.css"
import { AppPaths } from "shoppio-constants"

export const salesDashboardLinks = [AppPaths.SALES_DASHBOARD]
export const enggDashboardLinks = [AppPaths.ENGINEERING_DASHBOARD]
export const queryTicketLinks = [AppPaths.QUERY_RESOLUTION]
export const adminDashLink = [AppPaths.QUERY_RESOLUTION]
export const sellerPrimaryMenuLinks = [
  {
    label: "Seller Dashboard",
    path: AppPaths.SELLER_DASHBOARD,
  },
  {
    label: "Analytics",
    path: AppPaths.STORE_ANALYTICS,
  },
  {
    label: "Account",
    path: AppPaths.ACCOUNT_SETUP,
  },
  {
    label: "Store",
    path: AppPaths.STORE_MANAGEMENT,
  },
  {
    label: "Store",
    path: AppPaths.STORE_MANAGEMENT_WITH_ID,
  },
  {
    label: "Create Store",
    path: AppPaths.CREATE_STORE,
  },
  {
    label: "Theme",
    path: AppPaths.THEME,
  },
  {
    label: "Theme",
    path: AppPaths.STORE_THEME,
  },
  {
    label: "KYC",
    path: AppPaths.KYC_STATUS,
  },
  {
    label: "Customers",
    path: AppPaths.CUSTOMERS_PAGE,
  },
  {
    label: "Logistics",
    path: AppPaths.SHIPPING_LOGISTICS,
  },
  {
    label: "Membership",
    path: AppPaths.PLANS,
  },
  {
    label: "Integrations",
    path: AppPaths.INTEGRATIONS,
  },
  {
    label: "Native Apps",
    path: AppPaths.NATIVE_APPS,
  },
  {
    label: "Pricing & Billing",
    path: AppPaths.PRICING_BILLING,
  },
  {
    label: "Marketing",
    path: AppPaths.MARKETING,
  },
  {
    label: "Customers Messages",
    path: AppPaths.CUSTOMER_CHAT,
  },
  {
    label: "Review & Ratings",
    path: AppPaths.REVIEWS_RATINGS,
  },
]

export default function Navigation() {
  const isScrolled = useIsScrolled(40)
  const [{ user, openDialog, userMeta, checkingAuth }, dispatch] =
    useStateValue()
  const location = useLocation()
  const history = useHistory()
  const onSalesDashboardPage = salesDashboardLinks.includes(location?.pathname)
  const onEnggDashboardPage = enggDashboardLinks.includes(location?.pathname)
  const onAdminsDashboardPage = adminDashLink.includes(location?.pathname)
  const onSellerPrimaryMenus = sellerPrimaryMenuLinks.find(
    menu => menu.path === location.pathname,
  )
  const shouldShowHeader = ![AppPaths.LOGIN, AppPaths.SELLER_SIGNUP].includes(
    location?.pathname,
  )
  const onHome = ![AppPaths.SELLER_DASHBOARD].includes(location?.pathname)
  const onCareerPage = [AppPaths.CAREER].includes(location?.pathname)

  const onLandingPage = [AppPaths.HOME_PAGE].includes(location?.pathname)
  const [menuOpen, setMenuOpen] = useState()
  const navigate = path => {
    history?.push(path)
  }
  const toggleDialog = () => {
    dispatch({
      type: "GENERAL",
      data: {
        openDialog: !openDialog,
      },
    })
  }
  const Left = () => {
    return (
      <div>
        <Link to='/' style={{ marginLeft: 0 }} className='navigation-logo'>
          <img className='header__logo' src={ShoppioLogo} alt='ss' />
        </Link>
      </div>
    )
  }
  const renderCommonLinks = () => (
    <div>
      <Link to={AppPaths.CAREER} style={{ marginLeft: 0 }}>
        <span className='page-header'>Career</span>
      </Link>
      <Link
        to={{
          pathname: AppPaths.HOME_PAGE,
          state: { scrollToWhyChoose: true },
        }}
        style={{ marginLeft: "50px" }}
      >
        <span className='page-header'>Why Us?</span>
      </Link>
      <Link to={AppPaths.PLANS} style={{ marginLeft: "50px" }}>
        <span className='page-header'>Pricing</span>
      </Link>
    </div>
  )
  const Middle = () => {
    return (
      <div className='middle-content'>
        {onCareerPage && renderCommonLinks()}
        {onLandingPage && renderCommonLinks()}

        {onSalesDashboardPage && (
          <span className='page-header center-text'>Sales dashboard</span>
        )}
        {onEnggDashboardPage && (
          <span className='page-header center-text'>Engineering dashboard</span>
        )}
        {queryTicketLinks && <span className='page-header center-text'></span>}
        {onAdminsDashboardPage && (
          <span className='page-header center-text'>Admin Dashboard</span>
        )}
        {onSellerPrimaryMenus && (
          <span className='page-header center-text'>
            {onSellerPrimaryMenus.label}
          </span>
        )}
      </div>
    )
  }

  const Right = useCallback(() => {
    return (
      <div className='navigation-right-content'>
        {checkingAuth ? (
          <div>
            <IconButton
              onClick={() => navigate(AppPaths.SELLER_DASHBOARD)}
              icon={"dashboard-sidebar"}
            >
              <Loader />
            </IconButton>
          </div>
        ) : userMeta?.type === "seller" ? (
          <>
            {!onSellerPrimaryMenus && (
              <IconButton
                onClick={() => navigate(AppPaths.SELLER_DASHBOARD)}
                icon={"dashboard-sidebar"}
              >
                Dashboard
              </IconButton>
            )}
            <IconButton
              onClick={() => navigate(AppPaths.QUERY_RESOLUTION)}
              icon={"info-circle"}
            >
              Help
            </IconButton>
            <IconButton
              onClick={() => toggleDialog()}
              icon={"bell-2"}
            ></IconButton>
            <IconButton
              onClick={() => setMenuOpen(true)}
              icon={"menu-fries-right-1"}
            />
          </>
        ) : user?.uid ? (
          <>
            <IconButton
              onClick={() => navigate(AppPaths.ACCOUNT)}
              icon={"user-circle"}
            >
              Account
            </IconButton>
            <IconButton
              onClick={() => navigate(AppPaths.QUERY_RESOLUTION)}
              icon={"info-circle"}
            >
              Help
            </IconButton>
            <IconButton
              onClick={() => setMenuOpen(true)}
              icon={"menu-fries-right-1"}
            />
          </>
        ) : (
          <>
            <IconButton
              onClick={() => navigate(AppPaths.LOGIN)}
              icon={"user-1"}
            >
              Login
            </IconButton>
            <IconButton icon={"info-circle"}>Help</IconButton>
          </>
        )}
      </div>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkingAuth, userMeta, onHome])
  return shouldShowHeader ? (
    <div className='navigation-container'>
      <div
        className={isScrolled ? "navigation navigation-shadow" : "navigation"}
      >
        <Left />
        {!isMobile && <Middle />}
        {!isMobile && <Right />}

        {/* <>
            <IconButton onClick={() => navigate("/login")} icon={"sign-in"}>
              Login
            </IconButton>
          </> */}
        {isMobile && (
          <IconButton
            style={{ marginRight: "0px" }}
            onClick={() => setMenuOpen(true)}
            icon={"menu-fries-right-1"}
          />
        )}
      </div>
      <Drawer
        setMenuOpen={setMenuOpen}
        logo={ShoppioLogo}
        menuOpen={menuOpen}
        user={user}
        history={history}
        dispatch={dispatch}
        openDialog={openDialog}
      />
      <Dialog right open={openDialog} onClose={() => toggleDialog()}>
        <Jumbotron icon={"lnr lnr-alarm"} title={"Notifications"} />
        <UserNotifications />
      </Dialog>
    </div>
  ) : (
    ""
  )
}
