import React, { useEffect, lazy, Suspense } from "react"
import { Route, BrowserRouter as Router, Switch } from "react-router-dom"
import { Redirect } from "react-router-dom/cjs/react-router-dom.min"
import { useStateValue } from "state"
import { DB, handleError } from "firebaseUtils"
import logo from "./images/Logo.png"
import PullRefresh from "./components/UI/PullRefresh"
import { useSetAuth, useFetchEnvs, usePricingModels } from "hooks"
import { Notifier, Logout, useAttachMessageHandler } from "appComponents"
import { Header, Loader } from "uiComponents"
import "./App.css"
import "./pages/Home/Home.css"
import { StoreAnalytics } from "./components/App/StoreAnalytics"
import {
  Customers,
  Integrations,
  NativeApps,
  PricingBilling,
  QueryResolution,
  Navigation,
  CustomerMessages,
} from "./components/App"
import KYC from "./components/App/KYC/KYC"
import ReviewStatus from "./components/App/KYC/ReviewStatus"
import LoginNew from "./components/App/Login/Login"
import { Marketing } from "./components/App/Marketing"
import SignupHome from "./components/App/SignUp/SignupHome"
import { ThemeModule } from "./components/App/Theme"
import SalesDashboard from "./pages/SalesDashboard/SalesDashboard"
import EngineeringDashboard from "./pages/EngineeringDashboard/EngineeringDashboard"
import AutoImagePreview from "uiComponents/AutoImagePreview/AutoImagePreview"
import { getTime } from "helpers"
import { Cache } from "cache"
import { AppPaths, CacheKeys } from "shoppio-constants"
import { StoreFormPage } from "./pages/StoreManagement/StoreForm"
import { useCurrentStoreProducts } from "hooks/CustomHooks"
const Career = lazy(() => import("./components/App/Career/CareerWrapper"))
const Join = lazy(() => import("./components/App/Join/Join"))
const AddProduct = lazy(() => import("./pages/AdminComponents/AddProduct"))
const AddProductV2 = lazy(() => import("./components/App/Product/AddProduct"))
const ProductHome = lazy(() => import("./components/App/Product/ProductsHome"))
const Checkout = lazy(() => import("./pages/Checkout/Checkout"))
const ContactUs = lazy(() => import("./pages/ContactUs/ContactUs"))
const CategoryHome = lazy(() => import("./pages/Home/CategoryHome"))
const StoreHome = lazy(() => import("./pages/Home/StoreHome"))
const SubcategoryHome = lazy(() => import("./pages/Home/SubcategoryHome"))
const Orders = lazy(() => import("./pages/Orders/Orders"))
const ProductDetails = lazy(() =>
  import("./pages/ProductDetails/ProductDetails"),
)
const Queries = lazy(() => import("./pages/Queries/Queries"))
const AclSignup = lazy(() => import("./pages/SignUp/AclSignUp"))
const SignUp = lazy(() => import("./pages/SignUp/SignUp"))
const AccessControlList = lazy(() =>
  import("./components/App/Stores/AccessControlList"),
)
const AddCategory = lazy(() => import("./components/App/Stores/AddCategory"))
const AddStore = lazy(() => import("./components/App/Stores/AddStore"))
const AddSubcategory = lazy(() =>
  import("./components/App/Stores/AddSubcategory"),
)
const DeliveryLocationContainer = lazy(() =>
  import("./components/App/Stores/DeliveryLocationContainer"),
)
const Shops = lazy(() => import("./components/App/Stores/Shops"))
const StoreDashboard = lazy(() =>
  import("./components/App/Stores/StoreDashboard"),
)
const UpdateCategory = lazy(() =>
  import("./components/App/Stores/UpdateCategory"),
)
const UpdateSubcategory = lazy(() =>
  import("./components/App/Stores/UpdateSubcategory"),
)
const ViewCategories = lazy(() =>
  import("./components/App/Stores/ViewCategories"),
)
const SubCategory = lazy(() => import("./pages/SubCategory/SubCategory"))
const SuperUserDashboard = lazy(() =>
  import("./pages/SuperUserDashboard/SuperUserDashboard"),
)
const Account = lazy(() => import("./pages/UserProfile/Account"))
const PlaceOrder = lazy(() => import("./pages/UserProfile/PlaceOrder"))
const SelectUserAddress = lazy(() =>
  import("./pages/UserProfile/SelectUserAddress"),
)
const SellerProfile = lazy(() => import("./pages/UserProfile/SellerProfile"))
const UserAddresses = lazy(() => import("./pages/UserProfile/UserAddresses"))
const BigBannerTitle = lazy(() =>
  import("./components/App/AdBanners/BigBannerTitle"),
)
const Alert = lazy(() => import("./components/UI/Alert/Alert"))
const PWAinstall = lazy(() => import("./components/UI/PWA/PWAinstall"))
const Policies = lazy(() => import("./pages/Footer/Policies"))
const Terms = lazy(() => import("./pages/Footer/Terms"))
const CancellationRefund = lazy(() =>
  import("./pages/Footer/CancellationRefund"),
)
const ShippingDelivery = lazy(() => import("./pages/Footer/ShippingDelivery"))
const AboutUs = lazy(() => import("./pages/Footer/AboutUs"))
const SavedProducts = lazy(() => import("./pages/UserProfile/SavedProducts"))
const ReviewRating = lazy(() => import("./pages/UserProfile/ReviewRating"))
const WhyShoppioSellers = lazy(() =>
  import("./components/App/WhyShoppioSellers/WhyShoppioSellers"),
)
const SellerPolicies = lazy(() => import("./pages/Footer/SellerPolicies"))
const StoreLinkParser = lazy(() =>
  import("./components/App/Stores/StoreLinkParser"),
)
const FAQs = lazy(() => import("./pages/Queries/FAQs"))
const CouponCodes = lazy(() => import("./components/App/Stores/CouponCodes"))
const SelectCouponCode = lazy(() =>
  import("./components/App/Stores/SelectCouponCode"),
)
const MessageToCustomer = lazy(() =>
  import("./components/App/Messaging/MessageToCustomer"),
)
const Plans = lazy(() => import("./pages/Upgrade/Plans"))
const SellerDashboard = lazy(() =>
  import("./pages/SellerDashboard/SellerDashboard"),
)
const StoreManagementExtention = lazy(() =>
  import("./pages/StoreManagement/StoreManagementExtention"),
)
const ShippingLogistics = lazy(() =>
  import("./components/App/ShippingLogistics/ShippingLogistics"),
)
const AccountSetup = lazy(() =>
  import("./components/App/AccountSetup/AccountSetup"),
)
const LandingPage = lazy(() =>
  import("./components/App/LandingPage/LandingPage"),
)
const ReviewsRatings = lazy(() =>
  import("./components/App/ReviewRating/ReviewRating"),
)

const UserStores = lazy(() => import("./components/App/UserStores/UserStores"))

const AppBusyLoader = () => {
  let darkThemed = document.querySelector(".theme-dark")
  let filterColor = "none"
  if (darkThemed) filterColor = "invert(1)"
  return (
    <div className='app-loader'>
      <div>
        <img
          src={logo}
          width={200}
          alt='logo'
          style={{ marginBottom: 10, filter: filterColor }}
        />
        <Loader />
      </div>
    </div>
  )
}

const AuthRoute = props => {
  const [{ user, checkingAuth }] = useStateValue()
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])
  if (checkingAuth) return <Loader full />
  if (user) {
    return <Route {...props} />
  } else {
    Cache.set(CacheKeys.PATH_BEFORE_LOGIN, window.location.href)
    return <Redirect to='/login' />
  }
}

const PullToRefreshRoute = props => {
  return (
    <PullRefresh>
      {props.auth ? <AuthRoute {...props} /> : <Route {...props} />}
    </PullRefresh>
  )
}

export const getOrCreateMeta = async (uid, dispatch) => {
  if (!dispatch || !uid) return
  let docRef = DB.collection(`users_meta`).doc(uid)
  const userMeta = (await docRef.get()).exists
  if (userMeta) {
    docRef
      .get()
      .then(async doc => {
        if (doc.exists) {
          let userMeta = doc.data()
          let storesSnap = await DB.collection(`users_meta`)
            .doc(uid)
            .collection("stores")
            .get()
          let stores = storesSnap.docs.map(doc => doc.data())
          userMeta["stores"] = stores
          // console.log("user meta", userMeta);
          dispatch({
            type: "SET_USER_META",
            userMeta: userMeta,
          })
          dispatch({
            type: "GENERAL",
            data: {
              checkingAuth: false,
            },
          })
          dispatch({
            type: "UNSET_BUSY",
          })
        } else {
          dispatch({
            type: "UNSET_BUSY",
          })
        }
      })
      .catch(e => console.error("Error fetching usermeta", e))
  } else {
    let docRef = DB.collection(`users_meta`).doc(uid)
    docRef.set({
      created_at: getTime(),
    })
    docRef
      .get()
      .then(doc => {
        if (doc.exists) {
          let userMeta = doc.data()
          dispatch({
            type: "SET_USER_META",
            userMeta: userMeta,
          })
          dispatch({
            type: "GENERAL",
            data: {
              checkingAuth: false,
            },
          })
          dispatch({
            type: "UNSET_BUSY",
          })
        } else {
          dispatch({
            type: "UNSET_BUSY",
          })
        }
      })
      .catch(err => handleError(err))
    dispatch({
      type: "UNSET_BUSY",
    })
  }
}

function Routes() {
  const [{ appBusy, busyMessage }] = useStateValue()
  useFetchEnvs()
  useSetAuth()
  useAttachMessageHandler()
  usePricingModels()
  useCurrentStoreProducts()
  const isSuperAdmin = true
  return (
    <>
      {appBusy && <AppBusyLoader message={busyMessage} />}
      <Notifier />
      <Router>
        <div className={appBusy ? "App app-busy" : "App"}>
          <Route component={Navigation} />
          <Route component={AutoImagePreview} />
          <Suspense fallback={<Loader full />}>
            <Route component={BigBannerTitle} />
            <Route component={Alert} />
            <Switch>
              {isSuperAdmin && (
                <PullToRefreshRoute
                  path={AppPaths.SUPERUSER_DASHBOARD}
                  component={SuperUserDashboard}
                />
              )}
              <AuthRoute
                path={AppPaths.STORE_DASHBOARD}
                component={StoreDashboard}
              />
              <AuthRoute
                path={AppPaths.MANAGE_STORE}
                render={props => (
                  <StoreDashboard type={"Store Manager"} {...props} />
                )}
              />
              <AuthRoute
                path={AppPaths.MANAGE_DELIVERIES}
                render={props => (
                  <StoreDashboard type={"Delivery Manager"} {...props} />
                )}
              />
              <AuthRoute
                path={AppPaths.DELIVERIES}
                render={props => (
                  <StoreDashboard type={"Delivery Person"} {...props} />
                )}
              />
              <AuthRoute path={AppPaths.PLACE_ORDER} component={PlaceOrder} />
              <AuthRoute
                path={AppPaths.SELECT_ADDRESS}
                component={SelectUserAddress}
              />
              <AuthRoute
                path={AppPaths.SELECT_COUPONS}
                component={SelectCouponCode}
              />
              <AuthRoute
                path={AppPaths.CUSTOMER_MESSAGES}
                component={MessageToCustomer}
              />
              <PullToRefreshRoute
                path={AppPaths.SELLER_MESSAGES}
                component={StoreHome}
              />
              <AuthRoute
                path={AppPaths.MY_ADDRESSES}
                component={UserAddresses}
              />
              <AuthRoute path={AppPaths.ORDERS} component={Orders} />
              <Route path={AppPaths.SUB_CATEGORY} component={SubCategory} />
              <PullToRefreshRoute
                path={AppPaths.STORE_HOME}
                component={StoreHome}
              />
              <AuthRoute path={AppPaths.ACL} component={AccessControlList} />
              <AuthRoute
                path={AppPaths.DELIVERY}
                component={DeliveryLocationContainer}
              />
              <AuthRoute path={AppPaths.COUPON_CODES} component={CouponCodes} />
              <PullToRefreshRoute
                path={AppPaths.CATEGORY_HOME}
                component={CategoryHome}
              />
              <PullToRefreshRoute
                path={AppPaths.SUBCATEGORY_HOME}
                component={SubcategoryHome}
              />
              <Route
                path={AppPaths.VIEW_CATEGORIES}
                component={ViewCategories}
              />
              <Route path={AppPaths.PRODUCT_ADD} component={ProductDetails} />
              <Route path={AppPaths.ADD_PRODUCT} component={AddProduct} />
              <Route path={AppPaths.ADD_NEW_PRODUCT} component={AddProductV2} />
              <Route path={AppPaths.PRODUCT_HOME} component={ProductHome} />
              <Route path={AppPaths.UPDATE_PRODUCT} component={AddProduct} />
              <Route
                path={AppPaths.PRODUCT_DETAILS}
                component={ProductDetails}
              />
              <AuthRoute
                path={AppPaths.ADD_STORE_WITH_ID}
                component={AddStore}
              />
              <AuthRoute path={AppPaths.ADD_STORE} component={AddStore} />
              <AuthRoute path={AppPaths.ADD_CATEGORY} component={AddCategory} />
              <AuthRoute
                path={AppPaths.UPDATE_CATEGORY}
                component={UpdateCategory}
              />
              <AuthRoute
                path={AppPaths.ADD_SUBCATEGORY}
                component={AddSubcategory}
              />
              <AuthRoute
                path={AppPaths.UPDATE_SUBCATEGORY}
                component={UpdateSubcategory}
              />
              <AuthRoute path={AppPaths.CART} component={Checkout} />
              <AuthRoute path={AppPaths.ACCOUNT} component={Account} />
              <AuthRoute
                path={AppPaths.SAVED_PRODUCTS}
                component={SavedProducts}
              />
              <AuthRoute
                path={AppPaths.REVIEWS_RATINGS}
                component={ReviewRating}
              />
              <AuthRoute
                path={AppPaths.SELLER_PROFILE}
                component={SellerProfile}
              />
              {/* <AuthRoute path={AppPaths.SELLER_SIGNUP} component={SellerSignUp} /> */}
              <Route path={AppPaths.ACL_SIGNUP} component={AclSignup} />
              <Route path={AppPaths.CONTACT_US} component={ContactUs} />
              <Route path={AppPaths.ABOUT_US} component={AboutUs} />
              <Route path={AppPaths.POLICIES} component={Policies} />
              <Route
                path={AppPaths.SELLER_TERMS_POLICIES}
                component={SellerPolicies}
              />
              <Route path={AppPaths.TERMS} component={Terms} />
              <Route
                path={AppPaths.SHOPPIO_FOR_SELLERS}
                component={WhyShoppioSellers}
              />
              <Route
                path={AppPaths.CANCELLATION_REFUND}
                component={CancellationRefund}
              />
              <Route
                path={AppPaths.SHIPPING_DELIVERY}
                component={ShippingDelivery}
              />
              <Route path={AppPaths.QUERIES} component={Queries} />
              <Route path={AppPaths.FAQS} component={FAQs} />
              <Route path={AppPaths.SIGNUP} component={SignUp} />
              <Route path={AppPaths.PLANS} component={Plans} />
              <Route
                path={AppPaths.SHIPPING_LOGISTICS}
                component={ShippingLogistics}
              />
              <Route
                path={AppPaths.CREATE_STORE}
                component={StoreManagementExtention}
              />{" "}
              <Route
                path={AppPaths.STORE_MANAGEMENT_WITH_ID}
                component={StoreFormPage}
              />
              <Route
                path={AppPaths.STORE_MANAGEMENT}
                component={StoreManagementExtention}
              />
              <AuthRoute
                path={AppPaths.SELLER_DASHBOARD}
                component={SellerDashboard}
              />
              <AuthRoute
                path={AppPaths.STORE_ANALYTICS}
                component={StoreAnalytics}
              />
              <AuthRoute
                path={AppPaths.INTEGRATIONS}
                component={Integrations}
              />
              <AuthRoute path={AppPaths.KYC_STATUS} component={KYC} />
              <AuthRoute
                path={AppPaths.REVIEW_STATUS}
                component={ReviewStatus}
              />
              <AuthRoute path={AppPaths.NATIVE_APPS} component={NativeApps} />
              <Route path={AppPaths.LOGIN} component={LoginNew} />
              <AuthRoute path={AppPaths.LOGOUT} component={Logout} />
              <AuthRoute path={AppPaths.CUSTOMERS_PAGE} component={Customers} />
              <AuthRoute
                path={AppPaths.PRICING_BILLING}
                component={PricingBilling}
              />
              <AuthRoute path={AppPaths.MARKETING} component={Marketing} />
              <AuthRoute
                path={AppPaths.QUERY_RESOLUTION}
                component={QueryResolution}
              />
              <AuthRoute
                path={AppPaths.CUSTOMER_CHAT}
                component={CustomerMessages}
              />
              <AuthRoute path={AppPaths.STORE_THEME} component={ThemeModule} />
              <AuthRoute path={AppPaths.THEME} component={ThemeModule} />
              <AuthRoute
                path={AppPaths.REVIEW_RATING}
                component={ReviewsRatings}
              />
              <Route path={AppPaths.JOIN} component={Join} />
              <Route
                path={AppPaths.SALES_DASHBOARD}
                component={SalesDashboard}
              />
              <Route
                path={AppPaths.ENGINEERING_DASHBOARD}
                component={EngineeringDashboard}
              />
              <Route path={AppPaths.SELLER_SIGNUP} component={SignupHome} />
              <Route path={AppPaths.ACCOUNT_SETUP} component={AccountSetup} />
              <AuthRoute path={AppPaths.UPDATE_ACCOUNT} component={SignUp} />
              <PullToRefreshRoute path={AppPaths.SHOPS} component={Shops} />
              <Route path={AppPaths.CAREER} exact component={Career} />
              <Route path={AppPaths.USER_STORES} component={UserStores} />
              <PullToRefreshRoute
                path={AppPaths.STORE_LINK}
                component={StoreLinkParser}
              />
              <Route path={AppPaths.LANDING_PAGE} component={LandingPage} />
              <Route
                path='*'
                render={() => <Header lg>Page Not Found</Header>}
              />
            </Switch>
          </Suspense>
        </div>
      </Router>
      {window.innerWidth < 766 && <PWAinstall type='mobile' />}
    </>
  )
}

export default Routes
