import React from "react"
import { Col, Row, IconButtonCircle, Space, Card, Header } from "uiComponents"
import { getSVG } from "getSVG"
import { QuickActions } from "appComponents"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { flexRowCenterBetween } from "../../styles/CommonStyles"
import { AppPaths } from "shoppio-constants"

const StoreManagement = () => {
  const history = useHistory()
  const data = [
    {
      svg: "Catalogue",
      header: "Manage Listing",
      description: "Add, edit, and delete products.",
    },
    {
      svg: "Advanced_customization",
      header: "Customization",
      description: "Edit, update store logo, color.",
    },
    {
      svg: "Business_analytics",
      header: "Sales Analysis",
      description: "See live orders and cash flow",
    },
  ]

  const quickActionsData = [
    {
      icon: "plus",
      text: "Add Product",
    },
    {
      icon: "coins",
      text: "Manage Pricing",
    },
    {
      icon: "boxes-stacked-1",
      text: "Manage Orders",
    },
    {
      icon: "shop",
      text: "Manage Store",
      onClick: () => history.push("/user_stores"),
    },
  ]

  return (
    <Card>
      <div style={{ ...flexRowCenterBetween }}>
        <div>
          <Header bold sm>
            Store Management
          </Header>
          <p>You can manage your store, account and analytics.</p>
        </div>

        <IconButtonCircle
          onClick={() => history.push(AppPaths.STORE_MANAGEMENT)}
          icon={"chevron-right"}
        />
      </div>
      <Row>
        <Col sm={12} md={8}>
          <Row>
            {data.map(item => (
              <Col xs={6} sm={6} md={4}>
                <Card noHorPad>
                  <div style={{ textAlign: "center" }}>
                    {getSVG(item.svg, { width: 100 }, null, true)}
                  </div>
                  <Header bold style={{ fontSize: "14px" }}>
                    {item.header}
                  </Header>
                  <p style={{ fontSize: "12px" }}>{item.description}</p>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
        <Col xs={12} sm={6} md={4}>
          <div style={{ textAlign: "center" }}>
            {getSVG("Business_ethics", { width: 200 }, null, true)}
          </div>
        </Col>

        <Col sm={6} md={12}>
          <Space lg />
          <Header sm={true}>Quick Actions</Header>
          <QuickActions data={quickActionsData} />
        </Col>
      </Row>
    </Card>
  )
}

export default StoreManagement
