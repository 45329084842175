import React, { Component } from "react"
import "./card.css"
import { Col, Row } from "uiComponents"
import { isMobile } from "../../../helpers"

class Card extends Component {
  state = {
    expanded: false,
  }

  toggle = () => {
    this.setState({
      expanded: !this.state.expanded,
    })
  }
  render() {
    const {
      onClick,
      children,
      style,
      innerStyle,
      className,
      noPadTop,
      colored,
      tip,
      fullHeight,
      iconClass,
      iconLetter,
      fullWidthChild,
      type,
      noShadow,
      circles,
      danger,
      busy,
      notFull,
      themeColor,
      cardActions,
      expanderContent,
      media,
      tag,
      rightComponent,
      svgIcon,
      asLink,
      alt,
      lowPadTop,
      lowPad,
      horizontal,
      noHorPad,
      extraPad,
      rowStyle,
      themed,
      noXS,
      fullRound,
      white,
      colStyle,
      childrenStyle,
      lessHorPad,
      veryLowPad,
      jumboIcon,
    } = this.props
    const { imgUrl, website, videoUrl } = media || {}
    const { expanded } = this.state
    return (
      <div
        className={
          "card " +
          (className ? className : "") +
          (colored ? " colored" : "") +
          (alt ? " alt" : "") +
          (type ? ` ${type}` : "") +
          (busy ? " card-busy" : "") +
          (asLink ? " card-link" : "") +
          (themed ? " card-themed" : "") +
          (white ? " card-themed-white" : "") +
          (fullRound ? " card-full-round" : "")
        }
        style={{
          paddingTop: veryLowPad
            ? 0
            : lowPadTop
            ? "1rem"
            : noPadTop
            ? "0rem"
            : "2rem",
          height: fullHeight ? "100%" : notFull ? "unset" : "",
          ...style,
        }}
        data-tip={tip}
      >
        <Row
          onClick={onClick}
          middle={noXS ? undefined : "xs"}
          style={{
            margin: 0,
            padding: veryLowPad
              ? "1.5rem 1rem"
              : lowPad
              ? "2rem 1.5rem"
              : extraPad
              ? "5rem 5rem 5rem 5rem"
              : horizontal
              ? "3rem 2rem"
              : noHorPad
              ? "1rem"
              : lessHorPad
              ? " 3rem 1.5rem"
              : "",
            cursor: onClick && "pointer",
            ...rowStyle,
          }}
        >
          <Col
            xs={12}
            sm={12}
            xl={svgIcon ? 8 : 12}
            lg={12}
            md={12}
            style={{
              margin: 0,
              padding: isMobile ? "0" : horizontal || noHorPad ? "0rem" : "",
              ...colStyle,
            }}
          >
            <div
              style={{
                ...innerStyle,
                boxShadow: noShadow ? "none" : "",
                height: fullHeight ? "100%" : notFull ? "unset" : "",
                padding: horizontal ? "0" : "",
              }}
              className='card-child'
            >
              {jumboIcon && <i className={`card-jumbo-icon ${jumboIcon}`} />}
              {tag && <div className='tag'>{tag}</div>}
              {rightComponent && (
                <div className='right-component'>{rightComponent}</div>
              )}

              {imgUrl && (
                <div
                  className={"background"}
                  style={{
                    backgroundImage: `url(${imgUrl})`,
                  }}
                />
              )}
              {videoUrl && (
                <embed
                  className={"background"}
                  src={videoUrl + "?autoplay=1"}
                  wmode='transparent'
                  type='video/mp4'
                  width='100%'
                  autostart={"true"}
                  title='Keyboard Cat'
                />
              )}
              {website || imgUrl || videoUrl
                ? ""
                : circles && (
                    <span
                      className='card-circle-one'
                      style={{ backgroundColor: themeColor }}
                    />
                  )}
              {expanderContent && (
                <button
                  onClick={this.toggle}
                  className={
                    expanded
                      ? "btn-chevron fas fa-chevron-up"
                      : "btn-chevron fas fa-chevron-down"
                  }
                />
              )}
              {website || imgUrl || videoUrl
                ? ""
                : circles && (
                    <span
                      className='card-circle-two'
                      style={{ backgroundColor: themeColor }}
                    />
                  )}
              {iconClass ? (
                <span
                  className={
                    (danger ? "danger " : "") + "card-icon " + iconClass
                  }
                />
              ) : null}
              {iconLetter ? (
                <span className={(danger ? "danger " : "") + "card-icon"}>
                  {iconLetter}
                </span>
              ) : null}
              <div
                className='card-children'
                style={{
                  width: iconClass ? "75%" : fullWidthChild ? "100%" : "",
                  display: horizontal ? "inline-flex" : "",
                  ...childrenStyle,
                }}
              >
                {iconClass ? (
                  <span className={"card-icon-big " + iconClass} />
                ) : null}

                {children}
                {cardActions && (
                  <div className='card-actions'>
                    <div>
                      {cardActions}
                      {expanderContent && expanded ? (
                        <div
                          style={{
                            marginTop: "2rem",
                          }}
                        >
                          {expanderContent}
                        </div>
                      ) : (
                        <div />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} xl={svgIcon ? 4 : 12} lg={12} md={12}>
            {svgIcon && <div className='svg-icon'>{svgIcon}</div>}
          </Col>
        </Row>
      </div>
    )
  }
}

export default Card

export const CardActions = ({ children }) => (
  <div className='card-actions'>
    <div>{children}</div>
  </div>
)
