import React from "react"
import "./PrimaryMenu.css"
import { Divider, Loader, Space } from "uiComponents"
import { useStateValue } from "state"
import { isMobile } from "helpers"
import { auth } from "firebaseUtils"
import { Link } from "react-router-dom"
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom"
import { useMenuItems } from "hooks"

const PrimaryMenu = ({ isOpen, setMenuOpen }) => {
  const [{ user, userMeta, ...state }, dispatch] = useStateValue()
  const isPrimaryMenuActive = state?.isPrimaryMenuActive || isOpen
  const location = useLocation()
  const history = useHistory()

  const handleAuthentication = () => {
    if (user) {
      auth.signOut()
      dispatch({
        type: "SHOW_ALERT",
        open: true,
        message: "Log Out Successfully",
      })
      history.push("/")
    }
  }

  // const [themeIcon, setIcon] = useState(
  //   localStorage.getItem("theme") === "dark" ? "fas fa-moon" : "fas fa-sun",
  // )

  // const [themeLabel, setThemeLabel] = useState(
  //   localStorage.getItem("theme") === "dark" ? "Light Mode" : "Dark Mode",
  // )

  // const themeSetter = theme => {
  //   if (theme === "light") {
  //     setIcon("fas fa-moon")
  //     setThemeLabel("Dark Mode")
  //     let body = document.getElementsByClassName("default")[0]
  //     body.classList.remove("theme-dark")
  //     body.classList.add("theme-light")
  //     localStorage.setItem("theme", "light")
  //   } else {
  //     setIcon("fas fa-sun")
  //     setThemeLabel("Light Mode")
  //     let body = document.getElementsByClassName("default")[0]
  //     body.classList.remove("theme-light")
  //     body.classList.add("theme-dark")
  //     localStorage.setItem("theme", "dark")
  //   }
  // }

  // const toggleTheme = () => {
  //   const currentTheme = localStorage.getItem("theme")
  //   const newTheme = currentTheme === "dark" ? "light" : "dark"
  //   themeSetter(newTheme)
  // }

  const { menuItems: menuToRender } = useMenuItems()
  return (
    <div
      onMouseEnter={() => {
        !isMobile && dispatch({ type: "OPEN_PRIMARY_MENU" })
      }}
      onMouseLeave={() => {
        !isMobile && dispatch({ type: "CLOSE_PRIMARY_MENU" })
      }}
      className={
        isMobile
          ? `primary-menu-fixed ${
              isPrimaryMenuActive ? "primary-menu-open" : ""
            }`
          : undefined
      }
    >
      <div
        className={
          isPrimaryMenuActive
            ? "primary-menu primary-menu-active"
            : "primary-menu"
        }
      >
        <div className='primary-menu-links'>
          {menuToRender?.map((item, i) => {
            const isActive = location?.pathname === item?.url
            return item?.group ? (
              <>{item.component}</>
            ) : (
              <Link
                to={item?.disabled ? "#" : item?.url || "#"}
                onClick={() => {
                  if (item?.disabled) return
                  dispatch({ type: "CLOSE_PRIMARY_MENU" })
                  setMenuOpen && setMenuOpen()
                  if (item?.onClick) {
                    item?.onClick()
                  }
                }}
                className={
                  isPrimaryMenuActive
                    ? "primary-menu-link-wrapper primary-link-visible"
                    : "primary-menu-link-wrapper"
                }
                key={i}
              >
                <div
                  className={
                    (isActive
                      ? "primary-menu-link primary-menu-link-active"
                      : "primary-menu-link") +
                    " " +
                    (item?.disabled ? "primary-menu-link-disabled" : "")
                  }
                  key={i}
                >
                  <span className='primary-menu-link-icon'>
                    <span
                      className={
                        `lni lni-${item?.iconClass}` || "fas fa-info-circle"
                      }
                    />
                  </span>
                  <>
                    <span className={"primary-menu-link-label"}>
                      {item?.label || "Label"}
                    </span>
                    <span
                      className={
                        isActive
                          ? "primary-menu-caret primary-menu-caret-active"
                          : "primary-menu-caret"
                      }
                    >
                      <span className='fas fa-chevron-right' />
                    </span>
                  </>
                </div>
              </Link>
            )
          })}
          {!userMeta && <Loader center />}
          <Space lg />
          <Divider style={{ width: "90%", margin: "0 auto" }} />
          <Space lg />
          <Link
            to={"#"}
            onClick={handleAuthentication}
            className={
              isPrimaryMenuActive
                ? "primary-menu-link-wrapper primary-link-visible"
                : "primary-menu-link-wrapper"
            }
          >
            <div className={"primary-menu-link"}>
              <span className='primary-menu-link-icon'>
                <span className={"lni lni-exit"} />
              </span>
              <>
                <span className={"primary-menu-link-label"}>Logout</span>
              </>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default PrimaryMenu
