import React, { useEffect, useState } from "react"
import { Col, Row } from "uiComponents"

const getSVG = (type, style, onLoad, alone) => {
  let img = require(`/${type}.svg`)
  if (img.default) {
    let IMG = () => {
      return (
        <img
          data-svg='true'
          data-nopreview='true'
          draggable={false}
          onLoad={typeof onLoad === "function" ? onLoad : undefined}
          src={img.default}
          alt='k'
          style={{ width: "100%", margin: "0.5rem 0", ...style }}
        />
      )
    }
    return alone ? (
      <IMG />
    ) : (
      <Row center='xs'>
        <Col xs={10} sm={8} md={6} lg={6}>
          <IMG />
        </Col>
      </Row>
    )
  }
  return <div />
}

export default getSVG

export const GetColorSVG = (type, style, onLoad, alone, fill) => {
  const [svgContent, setSvgContent] = useState(null)

  useEffect(() => {
    // Dynamically import the SVG file as text
    import(`/${type}.svg`)
      .then(module => {
        fetch(module.default)
          .then(response => response.text())
          .then(svg => {
            // Inject the fill color into the SVG
            const updatedSvg = svg.replace(
              /<svg([\s\S]*?)>/,
              `<svg$1 fill="${fill || "currentColor"}">`,
            )
            setSvgContent(updatedSvg)
            if (typeof onLoad === "function") onLoad()
            console.log("onLoad")
          })
          .catch(err => console.error("Error loading SVG content:", err))
      })
      .catch(err => console.error("Error importing SVG:", err))
  }, [type, fill, onLoad])

  const SVGComponent = () => (
    <div
      dangerouslySetInnerHTML={{ __html: svgContent }}
      style={{ width: "100%", margin: "0.5rem 0", ...style }}
    />
  )

  if (!svgContent) {
    return <div>Loading...</div> // Placeholder while the SVG loads
  }

  return alone ? (
    <SVGComponent />
  ) : (
    <Row center='xs'>
      <Col xs={10} sm={8} md={6} lg={6}>
        <SVGComponent />
      </Col>
    </Row>
  )
}
