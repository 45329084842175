import ImageUploading from "react-images-uploading"
import { Row, Col, Space, Busy, Highlight } from "uiComponents"
import React, { useEffect, useState } from "react"
import "./StandaloneImageUploader.css"
import { maxImageUploadSizeInBytes } from "config"
import { storage } from "firebaseUtils"

export const uploadImagesAndGetPaths = (mayBePics, path, finalCB) => {
  // console.log("Sheiku", pics);
  let pics = mayBePics
  if (pics && pics.filter(p => typeof p !== "string").length > 0) {
    pics = pics.filter(p => typeof p !== "string")
  } else {
    return finalCB(pics)
  }
  let paths = []
  if (pics && pics.length > 0) {
    // console.log(pics);
    pics.forEach(pic => {
      if (pic) {
        // console.log("user: ", pic);
        var name = Date.now() + "-" + pic?.file?.name
        // make ref to your firebase storage and select images folder
        var storageRef = storage.ref(`${path}/${name}`)
        // put file to Firebase
        var uploadTask = storageRef.put(pic.file)

        uploadTask.on(
          "state_changed",
          () => {},
          err => console.log(err),
          async () => {
            let backgroundURL = await uploadTask.snapshot.ref.getDownloadURL()
            paths.push(backgroundURL)
            if (paths.length === pics.length) {
              finalCB(paths)
            }
          },
        )
      }
    })
  } else {
    finalCB([])
  }
}
export const imageDeleteFromStorage = imgSrc =>
  new Promise(async (resolve, reject) => {
    try {
      if (imgSrc && !imgSrc.includes("firebasestorage")) resolve(true)
      let actualPath = await storage.refFromURL(imgSrc).location.path_
      await storage.ref().child(actualPath).delete()
      console.log("ractualPathes")
      resolve(true)
    } catch (e) {
      reject(e)
    }
  })

const OldImage = ({ image, onDeleteSuccess }) => {
  const onDelete = url => {
    imageDeleteFromStorage(url)
    if (onDeleteSuccess) {
      onDeleteSuccess()
    }
  }
  return image ? (
    <div className='image-item'>
      <img src={image} alt='' width='100' />
      <div className='image-item__btn-wrapper'>
        <button
          type='button'
          className='btn-primary btn-accent btn-small'
          onClick={() => onDelete(image)}
        >
          Remove
        </button>
      </div>
    </div>
  ) : (
    <div />
  )
}
export function StandaloneImageUploader({
  onUpdate,
  errorText,
  onDelete,
  maxNumber = 1,
  info,
  label,
  value,
  pathToUpload,
  showUploadButton,
  onUpload,
}) {
  // console.log(oldImages, "ImageUploader");
  const [images, setImages] = useState([])
  const [busy, setBusy] = useState()
  const [saved, setSaved] = useState()

  const onChange = imageList => {
    // data for submit
    // console.log(imageList);
    onUpdate && onUpdate(imageList)
    setImages(imageList)
  }

  const deleteImage = async url => {
    let res = await imageDeleteFromStorage(url)
    if (res) {
      setBusy()
      setSaved()
    }
  }

  const onImageUploadToFirebase = async index => {
    setBusy(true)
    if (pathToUpload) {
      const img = images[index]
      await uploadImagesAndGetPaths(
        [img],
        `${pathToUpload}/product_images`,
        imagesPath => {
          console.log("imagesPath", imagesPath)
          setBusy()
          onUpload && onUpload(imagesPath)
          setSaved(imagesPath[0])
        },
      )
    }
  }
  // console.log(errorText);
  useEffect(() => {
    if (!saved && images.length > 0 && showUploadButton) {
      // alert("Uploading")
      onImageUploadToFirebase(0)
    } else {
      // alert("Not Uploading")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images.length, saved])
  return (
    <Busy busy={busy}>
      <div
        className={`standalone-uploader ${errorText ? "standalone-error" : ""}`}
      >
        {errorText && <div className='error'>{errorText}</div>}
        {label && <label>{label} </label>}
        <ImageUploading
          multiple
          value={images}
          onChange={onChange}
          maxNumber={maxNumber}
          dataURLKey='data_url'
          maxFileSize={maxImageUploadSizeInBytes}
          resolutionType='more'
          resolutionWidth={500}
          resolutionHeight={500}
        >
          {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
            errors,
          }) => (
            // write your building UI
            <div className='upload__image-wrapper'>
              <div className='sticky'>
                {((showUploadButton && !saved) || !showUploadButton) && (
                  <div className='flex'>
                    <span className='lni lni-upload-2 upload-icon' />

                    <div>
                      {info && (
                        <>
                          <br />
                          <p>{info}</p>
                          <br />
                        </>
                      )}
                      <Row>
                        <Col xs={12}>
                          {maxNumber !== imageList.length && (
                            <>
                              <button
                                type='button'
                                style={{
                                  ...(isDragging ? { color: "red" } : {}),
                                  marginBottom: "1rem",
                                }}
                                onClick={onImageUpload}
                                className='btn-primary btn-small'
                                {...dragProps}
                              >
                                {imageList.length > 0 &&
                                maxNumber > imageList.length
                                  ? "Add More"
                                  : "Upload"}
                              </button>
                              <p>Drag and drop files here</p>
                            </>
                          )}
                          {imageList.length > 1 && (
                            <button
                              type='button'
                              className='btn-primary btn-accent btn-small'
                              onClick={onImageRemoveAll}
                            >
                              Remove all
                            </button>
                          )}
                        </Col>
                      </Row>
                      <Space />
                    </div>
                  </div>
                )}
              </div>
              {imageList.length === 0 && value && (
                <OldImage image={value} onDeleteSuccess={onDelete} />
              )}
              {imageList.map((image, index) => (
                <div key={index} className='image-item'>
                  <img src={image["data_url"]} alt='' width='100' />
                  <div className='image-item__btn-wrapper'>
                    <button
                      type='button'
                      className='btn-primary btn-accent btn-small'
                      onClick={() => {
                        onImageUpdate(index)
                        if (saved) {
                          setBusy(true)
                          deleteImage(saved)
                        }
                      }}
                    >
                      Change
                    </button>
                    <button
                      type='button'
                      className='btn-primary btn-accent btn-small'
                      onClick={() => {
                        onImageRemove(index)
                        if (saved) {
                          setBusy(true)
                          deleteImage(saved)
                        }
                      }}
                    >
                      Remove
                    </button>
                  </div>
                </div>
              ))}
              {errors && (
                <div>
                  {errors.maxNumber && (
                    <Highlight type={"danger"}>
                      Number of selected images exceed {errors.maxNumber}
                    </Highlight>
                  )}
                  {errors.acceptType && (
                    <Highlight type={"danger"}>
                      Your selected file type is not allow
                    </Highlight>
                  )}
                  {errors.maxFileSize && (
                    <Highlight type={"danger"}>
                      Selected file size exceed 3 MB
                    </Highlight>
                  )}
                  {errors.resolution && (
                    <span>
                      Image resolution should be greater 500px by 500px.
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
        </ImageUploading>
      </div>
    </Busy>
  )
}
