import React, { useState } from "react"
import {
  Tabs,
  Tab,
  Card,
  Row,
  Col,
  Space,
  Header,
  // IconButton,
  Toggle,
  IconButtonCircle,
  NoRecord,
} from "uiComponents"
import SpaceBetween from "../../UI/Space/SpaceBetween"
import { FormMaker } from "../../Forms"
import { fields } from "./Schema"
import "./MarketingForm.css"
import { DeviceWrapper } from "../../UI/Devices/Devices"
import { AdBanner } from "./AdBanner"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useAddUserAndUserStores } from "firebaseUtils/firebaseOperations"
import { useStateValue } from "state"
import LiveAnalytics from "../StoreAnalytics/LiveAnalytics"
// import { statusStyles } from "shoppio-constants/Constants"

const MarketingForm = () => {
  const [formData, setFormData] = useState({})
  const [showWrappedDevices, setShowWrappedDevices] = useState(false)
  const [{ user, userMeta, currentStoreData }, dispatch] = useStateValue()
  const currentStoreId = currentStoreData?.id
  const initialValues = currentStoreData?.marketing

  const history = useHistory()
  console.log(
    "user",
    user,
    "userMeta",
    userMeta,
    "currentStoreId",
    currentStoreId,
  )

  const { busy, handleMarketingAd } = useAddUserAndUserStores(
    user,
    userMeta,
    dispatch,
    currentStoreId,
  )

  const handleSubmit = values => {
    handleMarketingAd(values)
    setFormData({})
  }

  const handleFormChange = data => {
    setFormData(data)
    console.log(data)
  }

  const handleToggleDevices = () => {
    setShowWrappedDevices(!showWrappedDevices)
  }

  // const status = initialValues?.status

  return (
    <div>
      <Row>
        <Col sm={12} md={8} lg={8} xl={8}>
          <Card notFull>
            <Tabs noMar style={{ position: "relative" }}>
              <Tab label='New Ads'>
                <Toggle
                  active={showWrappedDevices}
                  onClick={handleToggleDevices}
                  horizontal
                  activeLabel={"Hide Devices"}
                  label={"Show Devices"}
                  style={{
                    position: "absolute",
                    top: "0",
                    right: "0",
                    zIndex: 999,
                  }}
                />
                <Row>
                  {showWrappedDevices && (
                    <Col sm={12} md={12}>
                      <Card>
                        <Row>
                          <>
                            <Col sm={12} md={4}>
                              <div className='center-content'>
                                <DeviceWrapper data={formData} scale={"0.8"} />
                              </div>
                            </Col>
                            <Col sm={12} md={4}>
                              <div className='center-content'>
                                <DeviceWrapper
                                  data={formData}
                                  type='desktop'
                                  scale={"0.8"}
                                />
                              </div>
                            </Col>
                            <Col sm={12} md={4}>
                              <div className='center-content'>
                                <DeviceWrapper
                                  data={formData}
                                  type='ipad'
                                  scale={"0.8"}
                                />
                              </div>
                            </Col>
                          </>
                        </Row>
                      </Card>
                    </Col>
                  )}
                  <Card noXS notFull>
                    <SpaceBetween>
                      <div style={{ display: "flex", marginRight: "10px" }}>
                        <IconButtonCircle
                          icon='chevron-left'
                          mr='10'
                          onClick={() => history.goBack()}
                        />
                        <div>
                          <Header sm bold>
                            Please provide your marketing ad details
                          </Header>
                          <p>
                            Discover premium elegance with our luxury brand –
                            Explore now and elevate your style!
                          </p>
                        </div>
                      </div>
                    </SpaceBetween>
                    <Space />
                    <FormMaker
                      fields={fields}
                      validateOnMount
                      handleChange={data => handleFormChange(data)}
                      busy={busy}
                      onSubmit={handleSubmit}
                    />
                  </Card>
                </Row>
              </Tab>
              <Tab label='Active Ads'>
                {initialValues?.adActive === true ? (
                  <AdBanner
                    active
                    data={initialValues}
                    storeData={currentStoreData}
                  />
                ) : (
                  <>
                    <NoRecord type={"general"} svg={"product"}>
                      <Header sm bold>
                        You have not any active ads
                      </Header>
                    </NoRecord>
                  </>
                )}
              </Tab>
              <Tab label='My Ads'>
                <AdBanner
                  editable
                  active
                  data={initialValues}
                  storeData={currentStoreData}
                  adStatus
                />
              </Tab>
            </Tabs>
          </Card>
        </Col>
        <Col sm={12} md={4} lg={4} xl={4}>
          <LiveAnalytics />
        </Col>
      </Row>
    </div>
  )
}

export default MarketingForm
