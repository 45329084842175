import React, { useState } from "react"
import { Row, Col, Card, Header, Space, Button } from "uiComponents"
import { PageLayout } from "appComponents"
import getSVG from "../../../images/svgs/svg"
import AppDetails from "./AppDetails"
import { isMobile } from "helpers"

const NativeApps = () => {
  const [viewChange, setViewChange] = useState(false)

  const handleViewChange = () => {
    setViewChange(!viewChange)
  }

  return (
    <>
      <PageLayout
        mainContent={
          !viewChange ? (
            <>
              <Row>
                <Col sm={12} md={12}>
                  <Card
                    themed
                    svgIcon={getSVG(
                      "App_data",
                      { width: "100%", height: "40rem" },
                      null,
                      true,
                    )}
                  >
                    <Row>
                      <Col sm={12} md={12}>
                        <Button small color='var(--success)'>
                          Elite
                        </Button>
                        <Header themed xxl bold>
                          Increase Sales with <br />
                          Native Apps
                        </Header>
                        <p style={{ fontSize: "20px", fontWeight: "500" }}>
                          Get your own shopping app on App Store and Play Store
                        </p>
                        <Space lg />
                        <Space lg />
                        <div>
                          <Button
                            fullWidth={isMobile}
                            noMar
                            fullRound
                            extraPad
                            alt
                            onClick={handleViewChange}
                          >
                            Get Started
                          </Button>
                          <Button fullWidth={isMobile} noMar fullRound extraPad>
                            Learn More
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <AppDetails />
          )
        }
      />
    </>
  )
}

export default NativeApps
