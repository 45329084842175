import { getDocsFromDb, handleError } from "firebaseUtils"
import { useEffect, useState } from "react"
import { useStateValue } from "state"
// import getImagePalette from "image-palette-core"

export const useColorFromImage = backgroundImage => {
  // const [palette, setPalette] = useState()
  // const getColorsFromImage = async () => {
  //   let res = await fetch(backgroundImage)
  //   let blob = await res.blob()
  //   const sourceImage = new Image()
  //   sourceImage.src = URL.createObjectURL(blob)
  //   sourceImage.onload = function () {
  //     var canvas = document.createElement("canvas")
  //     var context = canvas.getContext("2d")
  //     canvas.width = sourceImage.width
  //     canvas.height = sourceImage.height
  //     context.drawImage(sourceImage, 0, 0)
  //     var copiedImageDataURL = canvas.toDataURL("image/jpeg")
  //     var copiedImage = new Image()
  //     copiedImage.src = copiedImageDataURL
  //     copiedImage.onload = () => {
  //       setPalette(getImagePalette(copiedImage))
  //     }
  //   }
  // }
  // useEffect(() => {
  //   if (backgroundImage) {
  //     getColorsFromImage()
  //   }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [backgroundImage])
  // return palette
}

export const useIsScrolled = offset => {
  const [isScrolled, setIsScrolled] = useState(false)

  const handleScroll = () => {
    const scrollY = window.scrollY
    setIsScrolled(scrollY > offset)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    handleScroll() // Call initially to set state on mount

    return () => window.removeEventListener("scroll", handleScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset])

  return isScrolled
}

export const usePricingModels = () => {
  const [{ userMeta }, dispatch] = useStateValue()

  const getAllPricingModels = async () => {
    try {
      const res = await getDocsFromDb("pricing_model")
      if (res) {
        const data = { ...res }
        dispatch({
          type: "SET_PRICING_MODELS",
          data: {
            pricingModels: data,
          },
        })
      } else {
        handleError(res)
      }
    } catch (error) {
      handleError(error)
    }
  }

  useEffect(() => {
    if (userMeta) {
      getAllPricingModels()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMeta])

  return {
    getAllPricingModels,
  }
}

export const useCurrentStoreProducts = () => {
  const [{ currentStoreData }, dispatch] = useStateValue()

  const getAllCurrentStoreProducts = async () => {
    if (!currentStoreData?.id) return

    try {
      const res = await getDocsFromDb(
        `products/${currentStoreData.id}/store_products`,
      )
      if (res) {
        const products = { ...res }
        dispatch({
          type: "SET_CURRENT_STORE_PRODUCTS",
          data: {
            currentStoreProducts: products,
          },
        })
      } else {
        handleError("No products found.")
      }
    } catch (error) {
      handleError(error)
    }
  }

  useEffect(() => {
    if (currentStoreData?.id) {
      getAllCurrentStoreProducts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStoreData])

  return {
    getAllCurrentStoreProducts,
  }
}
