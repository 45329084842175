import React, { useState } from "react"
import { Row, Col, Card, Header, Space, Expander, Button } from "uiComponents"
import { FormMaker } from "../../Forms"
import { getSVG } from "getSVG"
import { DeviceWrapper } from "uiComponents/Devices/Devices"
import HomeScreen from "../../../images/svgs/app-logo-screen.svg"

import Splash1 from "../../../images/svgs/splash-screen-1.svg"
import Splash2 from "../../../images/svgs/splash-screen-2.svg"
import Splash3 from "../../../images/svgs/splash-screen-3.svg"
import Splash4 from "../../../images/svgs/splash-screen-4.svg"
import Splash5 from "../../../images/svgs/splash-screen-5.svg"

import Logo1 from "../../../images/svgs/logo-1.svg"
import Logo2 from "../../../images/svgs/logo-2.svg"
import Logo3 from "../../../images/svgs/logo-3.svg"
import Logo4 from "../../../images/svgs/logo-4.svg"
import Logo5 from "../../../images/svgs/logo-5.svg"

const AppDetails = () => {
  const [viewChange, setViewChange] = useState(false)
  const [selectedLogo, setSelectedLogo] = useState(Logo1)
  const [selectedSplash, setSelectedSplash] = useState(Splash1)

  const handleViewChange = () => {
    setViewChange(!viewChange)
  }

  const handleLogoSelect = logo => {
    setSelectedLogo(logo)
  }

  const handleSplashSelect = splash => {
    setSelectedSplash(splash)
  }

  const fields = [
    { name: "app_name", label: "App Name", type: "text", fullWidth: true },
    {
      name: "app_desc",
      label: "App Description",
      type: "textarea",
      fullWidth: true,
    },
  ]

  const logos = [Logo1, Logo2, Logo3, Logo4, Logo5]
  const splashScreens = [Splash1, Splash2, Splash3, Splash4, Splash5]

  return !viewChange ? (
    <Row>
      <Col sm={12} md={12}>
        <Card>
          <Header sm bold>
            App Details
          </Header>
          <p>Please fill the required application details</p>
          <Row>
            <Col sm={12} md={12} lg={12} xl={12}>
              <Card>
                <Expander title={"App Details"}>
                  <Row>
                    <Col sm={12} md={12} lg={12} xl={6}>
                      <FormMaker
                        submitLabel={"Proceed to Submission"}
                        fields={fields}
                        validateOnMount
                        onSubmit={handleViewChange}
                      />
                    </Col>
                    <Col sm={12} md={12} lg={12} xl={6}>
                      {getSVG("App installation-cuate", { width: 300 })}
                    </Col>
                  </Row>
                </Expander>
              </Card>
            </Col>
            <Col sm={12} md={12} lg={12} xl={12}>
              <Card>
                <Expander title={"Logo & Splash Screen"}>
                  <Row>
                    <Col sm={12} md={12} lg={12} xl={6}>
                      <Header md bold>
                        Logo Options
                      </Header>
                      <Space />
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          padding: "10px",
                        }}
                      >
                        {logos.map((logo, index) => (
                          <img
                            key={index}
                            src={logo}
                            alt={`Logo ${index + 1}`}
                            style={{
                              width: "50px",
                              height: "50px",
                              cursor: "pointer",
                              border:
                                selectedLogo === logo
                                  ? "2px solid green"
                                  : "none",
                            }}
                            onClick={() => handleLogoSelect(logo)}
                          />
                        ))}
                      </div>
                      <Space lg />
                      <Header md bold>
                        Splash Screen Options
                      </Header>
                      <Space />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                          height: "250px",
                          overflow: "hidden",
                          overflowX: "auto",
                          scrollbarWidth: "none",
                          gap: "10px",
                          padding: "10px",
                        }}
                      >
                        {splashScreens.map((splash, index) => (
                          <img
                            key={index}
                            src={splash}
                            alt={`Splash ${index + 1}`}
                            style={{
                              height: "250px",
                              cursor: "pointer",
                              borderRadius: "8px",
                              border:
                                selectedSplash === splash
                                  ? "2px solid green"
                                  : "none",
                            }}
                            onClick={() => handleSplashSelect(splash)}
                          />
                        ))}
                      </div>
                      <Space lg />
                      <Button>Save & Submit</Button>
                    </Col>
                    <Col
                      sm={12}
                      md={12}
                      lg={12}
                      xl={6}
                      style={{
                        borderLeft: "1px solid var(--border-color)",
                        position: "relative",
                      }}
                    >
                      <Header md bold>
                        Preview
                      </Header>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <DeviceWrapper
                          type='iphone15'
                          data={HomeScreen}
                          logo={selectedLogo}
                          scale={"2"}
                        />
                        <div
                          style={{
                            position: "absolute",
                            bottom: "120px",
                            right: "120px",
                          }}
                        >
                          <DeviceWrapper
                            type='iphone15'
                            data={selectedSplash}
                            scale={"2"}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Expander>
              </Card>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  ) : (
    <></>
  )
}

export default AppDetails

export const AppLogo = ({ logo, style }) => (
  <div style={{ padding: "20px" }}>
    <img src={logo} alt='App Logo' style={{ width: "100px", ...style }} />
  </div>
)
