import React, { useEffect, useState } from "react"
import ReactImageUploading from "react-images-uploading"
import {
  Button,
  Col,
  Dialog,
  Header,
  Icon,
  IconButton,
  Row,
  Space,
} from "uiComponents"
import { maxImageUploadSizeInBytes } from "../../../config"
import "./Uploader.css"
import { CircularProgress, Link } from "@mui/material"
import { uploadFilesToStorage } from "firebaseUtils/firebaseOperations"
import { imageDeleteFromStorage } from "../ImageUploader/ImageUploader"

// Define status constants
const statusHash = {
  ready_to_upload: "Ready to Upload",
  file_too_large: "File too large",
  uploading: "Uploading",
  uploaded: "Uploaded",
  upload_failed: "Upload Failed",
  deleting: "Deleting",
}

export default function Uploader({
  maxNumber = 6,
  filesStoragePath,
  onlyImage,
  open,
  onClose,
  onFilesChange,
}) {
  const [filesHash, setFilesHash] = useState([])

  const handleFileChange = imageList => {
    // Add information to imageList and set to fileHash
    const filesHash = imageList
      .filter(f => f.file)
      .map((file, index) => {
        return file.status
          ? { ...file }
          : {
              id: index,
              ...file,
              name: file?.file.name,
              type: file?.file.type,
              status:
                file?.file.size > maxImageUploadSizeInBytes
                  ? statusHash.file_too_large
                  : statusHash.ready_to_upload,
            }
      })
    setFilesHash([...filesHash])
  }

  useEffect(() => {
    if (filesHash.length > 0) {
      const fileToUpload = filesHash.find(
        f => f.status === statusHash.ready_to_upload,
      )
      if (fileToUpload) {
        fileToUpload.status = statusHash.uploading
        handleFileUpload(fileToUpload)
        setFilesHash(
          [...filesHash].map(f =>
            f.id === fileToUpload.id ? fileToUpload : f,
          ),
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesHash])

  const handleFileUpload = async file => {
    let fileUploadRes = await uploadFilesToStorage(
      [file.file],
      filesStoragePath,
    )
    if (fileUploadRes?.length) {
      console.log("File", fileUploadRes)
      handleUploadeSuccess(file, fileUploadRes[0])
    } else {
      handleError(file)
    }
  }

  const handleUploadeSuccess = (file, url) => {
    const fileUploaded = filesHash.find(f => f.id === file.id)
    fileUploaded.status = statusHash.uploaded
    fileUploaded.url = url
    setFilesHash(
      [...filesHash].map(f => (f.id === fileUploaded.id ? fileUploaded : f)),
    )
  }

  const handleError = file => {
    const fileToUpload = filesHash.find(f => f.id === file.id)
    fileToUpload.status = statusHash.upload_failed
    setFilesHash(
      [...filesHash].map(f => (f.id === fileToUpload.id ? fileToUpload : f)),
    )
  }

  const onRemoveFile = async id => {
    const fileToDelete = filesHash.find(f => f.id === id)
    if (fileToDelete.status === statusHash.uploading) return
    if (fileToDelete.status === statusHash.uploaded) {
      // Delete file from storage
      fileToDelete.status = statusHash.deleting
      setFilesHash(
        [...filesHash].map(f => (f.id === fileToDelete.id ? fileToDelete : f)),
      )
      deleteFromStorage(fileToDelete)
    } else {
      setFilesHash([...filesHash].filter(f => f.id !== id))
    }
  }

  const deleteFromStorage = async file => {
    try {
      await imageDeleteFromStorage(file.url)
      setFilesHash([...filesHash].filter(f => f.id !== file.id))
    } catch {
      console.error("Error deleting file", file)
    }
  }

  const FileCard = ({ name, url, status, data_url, id, type }) => {
    const isImage = type.includes("image")

    return (
      <div className='file-card'>
        <div className='file-card__img'>
          {isImage ? (
            <img
              className='file-card__img__img'
              src={
                [
                  statusHash.ready_to_upload,
                  statusHash.uploading,
                  statusHash.file_too_large,
                ].includes(status)
                  ? data_url
                  : url
              }
              alt={name}
            />
          ) : (
            <embed
              className='file-card__pdf__pdf'
              src={
                [
                  statusHash.ready_to_upload,
                  statusHash.uploading,
                  statusHash.file_too_large,
                ].includes(status)
                  ? data_url
                  : url
              }
              type='application/pdf'
              width='100%'
              height={"100%"}
              style={{ overflow: "hidden" }}
            />
          )}
        </div>
        <div className='file-card__content'>
          <div className='file-card__content__name'>
            <Header bold xs>
              {name}
            </Header>
            <span className='file-card__content__status'>
              {[statusHash.uploading, statusHash.deleting].includes(status) && (
                <>
                  <CircularProgress
                    variant='indeterminate'
                    thickness={6}
                    size={12}
                    style={{ marginRight: 5 }}
                  />{" "}
                  {status}
                </>
              )}
              {status === statusHash.ready_to_upload && <>{status}</>}
              {status === statusHash.uploaded && (
                <>
                  <Link href={url} target='_blank' className='link'>
                    View
                  </Link>
                </>
              )}
            </span>
          </div>
          <div className='file-card__content__actions'>
            <IconButton
              disabled={[statusHash.uploading, statusHash.deleting].includes(
                status,
              )}
              small
              icon='trash-2'
              onClick={() => onRemoveFile(id)}
            ></IconButton>
          </div>
        </div>
      </div>
    )
  }

  const handleDone = () => {
    onFilesChange(
      filesHash.map(file => ({
        name: file.name,
        url: file.url,
        type: file.type,
      })),
    )
    onClose()
  }

  return (
    <Dialog
      right
      open={open}
      onClose={() => {
        onFilesChange(
          filesHash.map(file => ({
            name: file.name,
            url: file.url,
            type: file.type,
          })),
        )
        onClose()
      }}
      actions={
        filesHash?.length ? (
          <Button fullWidth onClick={handleDone}>
            Proceed
          </Button>
        ) : (
          ""
        )
      }
    >
      <Header md bold>
        Upload Files
      </Header>

      <ReactImageUploading
        multiple
        value={filesHash}
        onChange={handleFileChange}
        maxNumber={maxNumber}
        dataURLKey='data_url'
        maxFileSize={maxImageUploadSizeInBytes}
        resolutionWidth={500}
        resolutionHeight={500}
        allowNonImageType={!onlyImage}
      >
        {({ onImageUpload, dragProps }) => (
          <div>
            <Row>
              <Col sm={12}>
                <div className='uploader-icon'>
                  <Icon iconClass='fas fa-upload' />
                  <Header sm bold>
                    {filesHash?.length
                      ? "Upload More Attachments"
                      : "Upload Attachments"}
                  </Header>
                  <p sm>Drag and drop files here</p>
                  <Space lg />
                  <Button small {...dragProps} onClick={onImageUpload}>
                    {filesHash?.length ? "Upload More" : "Upload"}
                  </Button>
                </div>
              </Col>
              <Col xs={12}>
                {filesHash?.map((imageHash, index) => (
                  <FileCard key={index} {...imageHash} />
                ))}
              </Col>
            </Row>
          </div>
        )}
      </ReactImageUploading>
    </Dialog>
  )
}
