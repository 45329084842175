import React from "react"
import { Header, Card, Button, Space } from "uiComponents"
import AnalyticsTile from "./AnalyticsTile"

const Traffic = () => {
  const cardsData = [
    { title: "Search Engine", value: "202K", icon: "search-2" },
    { title: "Paid Ads", value: "56K", icon: "shop-sign-paid" },
    {
      title: "Social Media",
      value: "322",
      icon: "hash",
    },
  ]
  return (
    <Card notFull noXS>
      <Header md bold>
        Web & App Traffic
      </Header>
      <p>Insights about leads coming throught various channels</p>
      <Space lg />
      {cardsData.map(data => (
        <AnalyticsTile title={data.title} value={data.value} icon={data.icon} />
      ))}
      <Space />
      <Button alt fullWidth fullRound>
        Tracking Settings
      </Button>
    </Card>
  )
}

export default Traffic
