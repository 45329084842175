import React from "react"
import "./Devices.css"
import { Row, Col } from "uiComponents"
import { AdBanner, AdBannerMobile } from "../../App/Marketing/AdBanner"

export const DeviceWrapper = ({
  data,
  style,
  type = "iphone",
  scale,
  logo,
}) => {
  return (
    <div className='wrapper'>
      {type === "iphone" && <IPhoneX data={data} style={style} scale={scale} />}
      {type === "desktop" && (
        <MacBookPro data={data} style={style} scale={scale} />
      )}
      {type === "ipad" && <IPadPro data={data} style={style} scale={scale} />}
      {type === "iphone15" && (
        <IPhone15 image={data} scale={scale} appLogo={logo} />
      )}
    </div>
  )
}

export const IPhone15 = ({ image, scale, appLogo }) => {
  return (
    <div class='iphone-15-pro-frame-wrapper'>
      <div class='iphone-15-pro-frame' style={{ scale: scale }}>
        <div class='iphone-15-pro-screen'>
          <div class='iphone-15-pro-notch'></div>
          <img
            src={image}
            alt='Screen Content'
            class='iphone-15-pro-screen-image'
          />
          {appLogo && (
            <div class='iphone-15-pro-app-logo'>
              <img src={appLogo} alt='App Logo' />
            </div>
          )}
        </div>
        <div class='iphone-15-pro-volume-buttons'>
          <div class='iphone-15-pro-button iphone-15-pro-volume-up'></div>
          <div class='iphone-15-pro-button iphone-15-pro-volume-down'></div>
        </div>
        <div class='iphone-15-pro-lock-button'></div>
      </div>
    </div>
  )
}

export const IPhoneX = ({ data, style, scale }) => {
  return (
    <div id='content' className='section section-device' style={style}>
      <div id='iphone-x' className='grid-xl text-center'>
        <div className='columns'>
          <div className='column col-12'>
            <div className='device device-iphone-x' style={{ scale: scale }}>
              <div className='device-frame'>
                <div className='device-content'>
                  <Row style={{ margin: "2rem" }}>
                    <AdBannerMobile data={data} />
                  </Row>
                </div>
              </div>
              <div className='device-stripe'></div>
              <div className='device-header'></div>
              <div className='device-sensors'></div>
              <div className='device-btns'></div>
              <div className='device-power'></div>
              <div className='device-home'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const IPadPro = ({ data, style, scale }) => {
  return (
    <div className='section section-device' style={style}>
      <div id='ipad-pro' className='grid-xl text-center'>
        <div className='columns'>
          <div className='column col-12'>
            <div className='dots'>
              <div
                className='dot tooltip'
                style={{ background: "#e2e3e4" }}
                data-tooltip='device-silver (default)'
              ></div>
              <div
                className='dot tooltip'
                style={{ background: "#f7e8dd" }}
                data-tooltip='device-gold'
              ></div>
              <div
                className='dot tooltip'
                style={{ background: "#facfc9" }}
                data-tooltip='device-rosegold'
              ></div>
              <div
                className='dot tooltip'
                style={{ background: "#9b9ba0" }}
                data-tooltip='device-spacegray'
              ></div>
            </div>
          </div>
          <div className='column col-12'>
            <div
              className='device device-ipad-pro device-gold'
              style={{ scale: scale }}
            >
              <div className='device-frame'>
                <Row style={{ margin: "2rem" }}>
                  <div className='device-content'>
                    <AdBannerMobile data={data} />
                  </div>
                </Row>
              </div>
              <div className='device-stripe'></div>
              <div className='device-sensors'></div>
              <div className='device-btns'></div>
              <div className='device-power'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const MacBookPro = ({ data, style, scale }) => {
  return (
    <div className='section section-device mcb-scale' style={style}>
      <div id='macbook-pro' className='grid-xl text-center'>
        <div className='columns'>
          <div className='column col-12'>
            <div className='dots'>
              <div
                className='dot tooltip'
                style={{ background: "#e2e3e4" }}
                data-tooltip='device-silver (default)'
              ></div>
              <div
                className='dot tooltip'
                style={{ background: "#83878a" }}
                data-tooltip='device-spacegray'
              ></div>
            </div>
          </div>
          <div className='column col-12'>
            <div
              className='device device-macbook-pro device-spacegray'
              style={{ scale: scale }}
            >
              <div className='device-frame'>
                <div className='device-content'>
                  <Row style={{ margin: "2rem" }}>
                    <Col sm={12} md={12}>
                      <AdBanner small data={data} />
                    </Col>
                  </Row>
                </div>
              </div>
              <div className='device-stripe'></div>
              <div className='device-header'></div>
              <div className='device-sensors'></div>
              <div className='device-btns'></div>
              <div className='device-power'></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
