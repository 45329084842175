export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}
export const getLocalStorage = (key, isString) => {
  let res = localStorage.getItem(key)
  return res && (res.includes("[") || res.includes("{"))
    ? JSON.parse(res)
    : res
    ? res.replaceAll('"', "")
    : null
}

export const removeLocalStorage = key => {
  localStorage.removeItem(key)
}
