export const allColors = [
  {
    name: "black",
    hex: "#000000",
  },
  {
    name: "silver",
    hex: "#c0c0c0",
  },
  {
    name: "gray",
    hex: "#808080",
  },
  {
    name: "white",
    hex: "#ffffff",
  },
  {
    name: "maroon",
    hex: "#800000",
  },
  {
    name: "red",
    hex: "#ff0000",
  },
  {
    name: "purple",
    hex: "#800080",
  },
  {
    name: "fuchsia",
    hex: "#ff00ff",
  },
  {
    name: "green",
    hex: "#008000",
  },
  {
    name: "lime",
    hex: "#00ff00",
  },
  {
    name: "olive",
    hex: "#808000",
  },
  {
    name: "yellow",
    hex: "#ffff00",
  },
  {
    name: "navy",
    hex: "#000080",
  },
  {
    name: "blue",
    hex: "#0000ff",
  },
  {
    name: "teal",
    hex: "#008080",
  },
  {
    name: "aqua",
    hex: "#00ffff",
  },
  {
    name: "orange",
    hex: "#ffa500",
  },
  {
    name: "aliceblue",
    hex: "#f0f8ff",
  },
  {
    name: "antiquewhite",
    hex: "#faebd7",
  },
  {
    name: "aquamarine",
    hex: "#7fffd4",
  },
  {
    name: "azure",
    hex: "#f0ffff",
  },
  {
    name: "beige",
    hex: "#f5f5dc",
  },
  {
    name: "bisque",
    hex: "#ffe4c4",
  },
  {
    name: "blanchedalmond",
    hex: "#ffebcd",
  },
  {
    name: "blueviolet",
    hex: "#8a2be2",
  },
  {
    name: "brown",
    hex: "#a52a2a",
  },
  {
    name: "burlywood",
    hex: "#deb887",
  },
  {
    name: "cadetblue",
    hex: "#5f9ea0",
  },
  {
    name: "chartreuse",
    hex: "#7fff00",
  },
  {
    name: "chocolate",
    hex: "#d2691e",
  },
  {
    name: "coral",
    hex: "#ff7f50",
  },
  {
    name: "cornflowerblue",
    hex: "#6495ed",
  },
  {
    name: "cornsilk",
    hex: "#fff8dc",
  },
  {
    name: "crimson",
    hex: "#dc143c",
  },
  {
    name: "cyan",
    hex: "#00ffff",
  },
  {
    name: "aqua",
    hex: "#00ffff",
  },
  {
    name: "darkblue",
    hex: "#00008b",
  },
  {
    name: "darkcyan",
    hex: "#008b8b",
  },
  {
    name: "darkgoldenrod",
    hex: "#b8860b",
  },
  {
    name: "darkgray",
    hex: "#a9a9a9",
  },
  {
    name: "darkgreen",
    hex: "#006400",
  },
  {
    name: "darkgrey",
    hex: "#a9a9a9",
  },
  {
    name: "darkkhaki",
    hex: "#bdb76b",
  },
  {
    name: "darkmagenta",
    hex: "#8b008b",
  },
  {
    name: "darkolivegreen",
    hex: "#556b2f",
  },
  {
    name: "darkorange",
    hex: "#ff8c00",
  },
  {
    name: "darkorchid",
    hex: "#9932cc",
  },
  {
    name: "darkred",
    hex: "#8b0000",
  },
  {
    name: "darksalmon",
    hex: "#e9967a",
  },
  {
    name: "darkseagreen",
    hex: "#8fbc8f",
  },
  {
    name: "darkslateblue",
    hex: "#483d8b",
  },
  {
    name: "darkslategray",
    hex: "#2f4f4f",
  },
  {
    name: "darkslategrey",
    hex: "#2f4f4f",
  },
  {
    name: "darkturquoise",
    hex: "#00ced1",
  },
  {
    name: "darkviolet",
    hex: "#9400d3",
  },
  {
    name: "deeppink",
    hex: "#ff1493",
  },
  {
    name: "deepskyblue",
    hex: "#00bfff",
  },
  {
    name: "dimgray",
    hex: "#696969",
  },
  {
    name: "dimgrey",
    hex: "#696969",
  },
  {
    name: "dodgerblue",
    hex: "#1e90ff",
  },
  {
    name: "firebrick",
    hex: "#b22222",
  },
  {
    name: "floralwhite",
    hex: "#fffaf0",
  },
  {
    name: "forestgreen",
    hex: "#228b22",
  },
  {
    name: "gainsboro",
    hex: "#dcdcdc",
  },
  {
    name: "ghostwhite",
    hex: "#f8f8ff",
  },
  {
    name: "gold",
    hex: "#ffd700",
  },
  {
    name: "goldenrod",
    hex: "#daa520",
  },
  {
    name: "greenyellow",
    hex: "#adff2f",
  },
  {
    name: "grey",
    hex: "#808080",
  },
  {
    name: "honeydew",
    hex: "#f0fff0",
  },
  {
    name: "hotpink",
    hex: "#ff69b4",
  },
  {
    name: "indianred",
    hex: "#cd5c5c",
  },
  {
    name: "indigo",
    hex: "#4b0082",
  },
  {
    name: "ivory",
    hex: "#fffff0",
  },
  {
    name: "khaki",
    hex: "#f0e68c",
  },
  {
    name: "lavender",
    hex: "#e6e6fa",
  },
  {
    name: "lavenderblush",
    hex: "#fff0f5",
  },
  {
    name: "lawngreen",
    hex: "#7cfc00",
  },
  {
    name: "lemonchiffon",
    hex: "#fffacd",
  },
  {
    name: "lightblue",
    hex: "#add8e6",
  },
  {
    name: "lightcoral",
    hex: "#f08080",
  },
  {
    name: "lightcyan",
    hex: "#e0ffff",
  },
  {
    name: "lightgoldenrodyellow",
    hex: "#fafad2",
  },
  {
    name: "lightgray",
    hex: "#d3d3d3",
  },
  {
    name: "lightgreen",
    hex: "#90ee90",
  },
  {
    name: "lightgrey",
    hex: "#d3d3d3",
  },
  {
    name: "lightpink",
    hex: "#ffb6c1",
  },
  {
    name: "lightsalmon",
    hex: "#ffa07a",
  },
  {
    name: "lightseagreen",
    hex: "#20b2aa",
  },
  {
    name: "lightskyblue",
    hex: "#87cefa",
  },
  {
    name: "lightslategray",
    hex: "#778899",
  },
  {
    name: "lightslategrey",
    hex: "#778899",
  },
  {
    name: "lightsteelblue",
    hex: "#b0c4de",
  },
  {
    name: "lightyellow",
    hex: "#ffffe0",
  },
  {
    name: "limegreen",
    hex: "#32cd32",
  },
  {
    name: "linen",
    hex: "#faf0e6",
  },
  {
    name: "magenta",
    hex: "#ff00ff",
  },
  {
    name: "fuchsia",
    hex: "#ff00ff",
  },
  {
    name: "mediumaquamarine",
    hex: "#66cdaa",
  },
  {
    name: "mediumblue",
    hex: "#0000cd",
  },
  {
    name: "mediumorchid",
    hex: "#ba55d3",
  },
  {
    name: "mediumpurple",
    hex: "#9370db",
  },
  {
    name: "mediumseagreen",
    hex: "#3cb371",
  },
  {
    name: "mediumslateblue",
    hex: "#7b68ee",
  },
  {
    name: "mediumspringgreen",
    hex: "#00fa9a",
  },
  {
    name: "mediumturquoise",
    hex: "#48d1cc",
  },
  {
    name: "mediumvioletred",
    hex: "#c71585",
  },
  {
    name: "midnightblue",
    hex: "#191970",
  },
  {
    name: "mintcream",
    hex: "#f5fffa",
  },
  {
    name: "mistyrose",
    hex: "#ffe4e1",
  },
  {
    name: "moccasin",
    hex: "#ffe4b5",
  },
  {
    name: "navajowhite",
    hex: "#ffdead",
  },
  {
    name: "oldlace",
    hex: "#fdf5e6",
  },
  {
    name: "olivedrab",
    hex: "#6b8e23",
  },
  {
    name: "orangered",
    hex: "#ff4500",
  },
  {
    name: "orchid",
    hex: "#da70d6",
  },
  {
    name: "palegoldenrod",
    hex: "#eee8aa",
  },
  {
    name: "palegreen",
    hex: "#98fb98",
  },
  {
    name: "paleturquoise",
    hex: "#afeeee",
  },
  {
    name: "palevioletred",
    hex: "#db7093",
  },
  {
    name: "papayawhip",
    hex: "#ffefd5",
  },
  {
    name: "peachpuff",
    hex: "#ffdab9",
  },
  {
    name: "peru",
    hex: "#cd853f",
  },
  {
    name: "pink",
    hex: "#ffc0cb",
  },
  {
    name: "plum",
    hex: "#dda0dd",
  },
  {
    name: "powderblue",
    hex: "#b0e0e6",
  },
  {
    name: "rosybrown",
    hex: "#bc8f8f",
  },
  {
    name: "royalblue",
    hex: "#4169e1",
  },
  {
    name: "saddlebrown",
    hex: "#8b4513",
  },
  {
    name: "salmon",
    hex: "#fa8072",
  },
  {
    name: "sandybrown",
    hex: "#f4a460",
  },
  {
    name: "seagreen",
    hex: "#2e8b57",
  },
  {
    name: "seashell",
    hex: "#fff5ee",
  },
  {
    name: "sienna",
    hex: "#a0522d",
  },
  {
    name: "skyblue",
    hex: "#87ceeb",
  },
  {
    name: "slateblue",
    hex: "#6a5acd",
  },
  {
    name: "slategray",
    hex: "#708090",
  },
  {
    name: "slategrey",
    hex: "#708090",
  },
  {
    name: "snow",
    hex: "#fffafa",
  },
  {
    name: "springgreen",
    hex: "#00ff7f",
  },
  {
    name: "steelblue",
    hex: "#4682b4",
  },
  {
    name: "tan",
    hex: "#d2b48c",
  },
  {
    name: "thistle",
    hex: "#d8bfd8",
  },
  {
    name: "tomato",
    hex: "#ff6347",
  },
  {
    name: "turquoise",
    hex: "#40e0d0",
  },
  {
    name: "violet",
    hex: "#ee82ee",
  },
  {
    name: "wheat",
    hex: "#f5deb3",
  },
  {
    name: "whitesmoke",
    hex: "#f5f5f5",
  },
  {
    name: "yellowgreen",
    hex: "#9acd32",
  },
  {
    name: "rebeccapurple",
    hex: "#663399",
  },
]

export const typeColors = {
  Primary: [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#009688",
    "#4caf50",
    "#8bc34a",
    "#cddc39",
    "#ffeb3b",
    "#ffc107",
    "#ff9800",
    "#ff5722",
    "#795548",
    "#9e9e9e",
    "#607d8b",
    "#000000",
  ],
  Danger: [
    "#ff1744",
    "#d50000",
    "#ff5252",
    "#ff8a80",
    "#b71c1c",
    "#c62828",
    "#d32f2f",
    "#e53935",
    "#f44336",
    "#ff5252",
  ],
  Success: [
    "#4caf50",
    "#388e3c",
    "#2e7d32",
    "#1b5e20",
    "#66bb6a",
    "#81c784",
    "#a5d6a7",
    "#c8e6c9",
    "#dcedc8",
    "#e8f5e9",
  ],
  Neutral: [
    "#9e9e9e",
    "#757575",
    "#616161",
    "#424242",
    "#bdbdbd",
    "#e0e0e0",
    "#eeeeee",
    "#f5f5f5",
    "#fafafa",
    "#ffffff",
  ],
}

export const generateColorVariants = hex => {
  if (!/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    throw new Error("Invalid hex color format. Use #RRGGBB or #RGB.")
  }

  // Convert hex to RGB
  const hexToRgb = h => {
    let r, g, b
    if (h.length === 4) {
      r = parseInt(h[1] + h[1], 16)
      g = parseInt(h[2] + h[2], 16)
      b = parseInt(h[3] + h[3], 16)
    } else if (h.length === 7) {
      r = parseInt(h.slice(1, 3), 16)
      g = parseInt(h.slice(3, 5), 16)
      b = parseInt(h.slice(5, 7), 16)
    }
    return { r, g, b }
  }

  // Convert RGB back to hex
  const rgbToHex = (r, g, b) =>
    `#${((1 << 24) + (r << 16) + (g << 8) + b)
      .toString(16)
      .slice(1)
      .toUpperCase()}`

  // Adjust brightness
  const adjustBrightness = (r, g, b, factor) => {
    return {
      r: Math.min(255, Math.max(0, Math.round(r * factor))),
      g: Math.min(255, Math.max(0, Math.round(g * factor))),
      b: Math.min(255, Math.max(0, Math.round(b * factor))),
    }
  }

  const { r, g, b } = hexToRgb(hex)

  // Generate lighter and darker colors
  const lighter = adjustBrightness(r, g, b, 0.4) // Increase brightness by 20%
  const darker = adjustBrightness(r, g, b, 0.6) // Decrease brightness by 20%

  return {
    bgColor: rgbToHex(darker.r, darker.g, darker.b),
    fgColor: rgbToHex(lighter.r, lighter.g, lighter.b),
  }
}

export function getColorScheme(primaryColor) {
  // Helper function to convert HEX to HSL
  function hexToHsl(hex) {
    hex = hex.replace(/^#/, "")
    let r = parseInt(hex.substring(0, 2), 16) / 255
    let g = parseInt(hex.substring(2, 4), 16) / 255
    let b = parseInt(hex.substring(4, 6), 16) / 255

    const max = Math.max(r, g, b),
      min = Math.min(r, g, b)
    let h,
      s,
      l = (max + min) / 2

    if (max === min) {
      h = s = 0 // achromatic
    } else {
      const d = max - min
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0)
          break
        case g:
          h = (b - r) / d + 2
          break
        case b:
          h = (r - g) / d + 4
          break
        default:
          h = 0
      }
      h *= 60
    }

    return { h, s, l }
  }

  // Helper function to convert HSL to HEX
  function hslToHex(h, s, l) {
    const hueToRgb = (p, q, t) => {
      if (t < 0) t += 1
      if (t > 1) t -= 1
      if (t < 1 / 6) return p + (q - p) * 6 * t
      if (t < 1 / 2) return q
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
      return p
    }

    let r, g, b

    if (s === 0) {
      r = g = b = l // achromatic
    } else {
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s
      const p = 2 * l - q
      r = hueToRgb(p, q, h / 360 + 1 / 3)
      g = hueToRgb(p, q, h / 360)
      b = hueToRgb(p, q, h / 360 - 1 / 3)
    }

    return `#${[r, g, b]
      .map(x =>
        Math.round(x * 255)
          .toString(16)
          .padStart(2, "0"),
      )
      .join("")}`
  }

  const { h, s, l } = hexToHsl(primaryColor)

  // Lighten the primary color slightly for foreground
  const foreground = hslToHex(h, s, Math.min(l + 0.4, 1))

  // Lighten the primary color more for background
  const background = hslToHex(h, s, Math.min(l + 0.45, 1))

  return { primary: primaryColor, foreground, background }
}
