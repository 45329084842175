import React from "react"
import "./UserStores.css"
import { Button, Card, Space } from "uiComponents"

const UserStores = ({ store }) => {
  return (
    <>
      <Card noXS notFull>
        <div class='store-home-card'>
          <img
            src={store?.businessInfo?.logo}
            alt={store?.store_name}
            class='store-home-card-image'
          />

          <div class='store-home-tags'>
            <span>Modern Minimalism</span>
            <span>Tech Innovations</span>
            <span>Gadget Essentials</span>
          </div>

          <div class='store-home-card-content'>
            <h2>{store?.store_name}</h2>
            <Button fullRound class='cta-button'>
              Visit Store
              <span class='lnr lnr-arrow-right' />
            </Button>
          </div>
        </div>
        <Space lg />
        <ProductCard />
      </Card>
    </>
  )
}

export default UserStores

export const ProductCard = () => {
  return (
    <div className='product-card'>
      <div className='product-image'>
        <img
          src='https://www.cnet.com/a/img/resize/a280efdd13cda75be3d830f6ef276c3d27a74ac2/hub/2021/04/29/bff485e7-ad23-4171-933c-db5f70c33a79/galaxy-s20-note-20-ultra-fe-product-promo-hoyle-2021-4.jpg?auto=webp&width=1200'
          alt='Product'
        />
        <button className='wishlist-button'>
          <span>❤️</span>
        </button>
      </div>
      <div className='product-info'>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "150px",
              alignItems: "flex-start",
            }}
          >
            <h3 className='product-name'>Samsung Galaxy A14 5G (4GB)</h3>
            <p className='product-description'>
              perform various tasks without slack and have impeccable network.
            </p>
          </div>
          <p className='product-price'>$10</p>
        </div>

        <div className='product-actions'>
          <span className='lnr lnr-cart' />
          <button className='buy-button' fullRound fullWidth>
            Buy Now
          </button>
        </div>
      </div>
    </div>
  )
}
