export const formStatus = {
  submitted: "Submitted",
  success: "Submission Successful",
  error: "Submission Error",
  validating: "Validating",
  pending: "Pending",
  approved: "Approved",
  rejected: "Rejected",
}

export const formStatusIcon = {
  submitted: "check",
  success: "check-circle-1",
  error: "xmark",
  validating: "hourglass-bottom-filled",
  pending: "spinner-1-bottom-left-blank",
  approved: "check-circle-2",
  rejected: "xmark",
}

export const formStatusColors = {
  submitted: "#00796b",
  success: "#388e3c",
  error: "#d32f2f",
  validating: "#E69A28",
  pending: "#fbc02d",
  approved: "#2e7d32",
  rejected: "#c62828",
}
