import React, { Fragment } from "react"
import "./Subtotal.css"
import { useStateValue } from "state"
import { getBasketItems, getBasketTotal } from "../../../state/reducer"
import { Link } from "react-router-dom"
import { currencyformatter } from "helpers"
import {
  Row,
  Col,
  Space,
  Button,
  Header,
  Divider,
  Highlight,
} from "uiComponents"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { CurrencySign } from "config"
import { AppPaths } from "shoppio-constants"

function Subtotal({
  onPlaceOrder,
  paymentModeComponent,
  checkingCoupon,
  couponStatus,
}) {
  const [{ basket, appliedCoupon }] = useStateValue()
  const subTotal = getBasketTotal(basket)
  const totalItems = getBasketItems(basket)
  const shippingPrice = 50
  console.log("...b...", basket)

  const calculateTotal = () => {
    let total = subTotal + shippingPrice
    let discountAmount = 0
    if (appliedCoupon) {
      let temp = (appliedCoupon.discount / 100) * subTotal
      discountAmount = temp > appliedCoupon?.upto ? appliedCoupon?.upto : temp
      total -= discountAmount
    }
    return { total, discountAmount }
  }

  const { total, discountAmount } = calculateTotal()
  const history = useHistory()
  const couponComponent = appliedCoupon ? (
    <Row middle='xs'>
      <Col xs>
        <Header xs color={"var(--font-color)"}>
          Discount{" "}
          <span
            style={{
              color: "var(--purple)",
              fontWeight: "bold",
              paddingTop: "0.5rem",
              display: "block",
            }}
          >
            ({appliedCoupon.code}){" "}
            {checkingCoupon ? (
              <span
                style={{ color: "var(--theme-color)" }}
                className='fas fa-circle-notch fa-spin'
              />
            ) : couponStatus ? (
              <span style={{ color: "var(--red)" }} className='lni lni-xmark' />
            ) : (
              <span
                style={{ color: "var(--green)" }}
                className='fas fa-check-circle'
              />
            )}
          </span>
        </Header>
      </Col>
      {couponStatus ? (
        <Col xs={12}>
          <Highlight type={"error"}>{couponStatus}</Highlight>
        </Col>
      ) : (
        ""
      )}
      <Col xs style={{ textAlign: "right" }}>
        <strong> - ₹{currencyformatter(discountAmount)}</strong>
      </Col>
      <Col xs={12}>
        <Button
          small
          onClick={() => history.push(AppPaths.SELECT_COUPONS)}
          accent
        >
          Change Coupon
        </Button>
      </Col>
    </Row>
  ) : (
    <>
      <Space />
      <Button
        small
        onClick={() => history.push(AppPaths.SELECT_COUPONS)}
        accent
      >
        Apply Coupon
      </Button>
    </>
  )
  return (
    <div>
      {" "}
      {onPlaceOrder ? (
        <Fragment>
          <Header bold>Price</Header>
          <Header noMar md bold>
            ₹{currencyformatter(total)}
          </Header>
          <Space />
          <Row middle='xs'>
            <Col xs>
              <Header color={"var(--font-color)"}>
                {totalItems} {totalItems > 1 ? "items" : "item"}
              </Header>
            </Col>
            <Col xs style={{ textAlign: "right" }}>
              <strong>
                {CurrencySign}
                {currencyformatter(subTotal)}
              </strong>
            </Col>
          </Row>
          <Row middle='xs'>
            <Col xs style={{ textAlign: "right" }}>
              <strong>
                {CurrencySign}
                {currencyformatter(shippingPrice)}
              </strong>
            </Col>
          </Row>
          {paymentModeComponent}
          {couponComponent}
          <Space />
          <Divider />
          <Space lg />
          <Header center bold>
            Total
          </Header>
          <Header center noMar lg bold>
            ₹{currencyformatter(total)}
          </Header>
        </Fragment>
      ) : (
        <div className='subtotal'>
          <Header md bold>
            Order Summary
          </Header>
          <Space />
          <Row middle='xs'>
            <Col xs>
              <Header color={"var(--font-color)"}>
                {basket?.length} {basket?.length > 1 ? "items" : "item"}
              </Header>
            </Col>
            <Col xs style={{ textAlign: "right" }}>
              <span>
                <strong>₹{currencyformatter(subTotal)}</strong>
              </span>
            </Col>
          </Row>
          <Row middle='xs'>
            <Col xs></Col>
            <Col xs style={{ textAlign: "right" }}>
              <strong>₹{currencyformatter(shippingPrice)}</strong>
            </Col>
          </Row>
          <Space />
          <Divider />
          <Space lg />
          <Row middle='xs' center='xs'>
            <Col xs>
              <Header center bold>
                Total
              </Header>
            </Col>
            <Col xs={12}>
              <Header center lg>
                ₹{currencyformatter(subTotal + shippingPrice)}
              </Header>
            </Col>
          </Row>
          <Space />
          <Space />
          <Link to={AppPaths.SELECT_ADDRESS}>
            <Button noMar fullWidth disabled={basket.length === 0} colored>
              Proceed
            </Button>
          </Link>
        </div>
      )}
    </div>
  )
}

export default Subtotal
