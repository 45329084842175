import { SelectField } from "formComponents"
import { useGetStoreData } from "hooks"
import { useStateValue } from "state"
import {
  ActionCard,
  Header,
  IconButtonCircle,
  SpaceBetween,
} from "uiComponents"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { useEffect } from "react"
import { Cache } from "cache"
import { AppPaths, CacheKeys } from "shoppio-constants"
import "./StoreSelector.css"

export const CreateStoreCard = () => {
  const [{ userMeta }] = useStateValue()
  const isDisabled = userMeta?.kycInfo?.status !== "approved"

  return (
    <div className='create-store-card'>
      <ActionCard
        title='Create Store'
        desc='Create your first store'
        svgName='Ecommerce_web_page'
        action={{
          label: "Create Store",
          link: AppPaths.CREATE_STORE,
          disabled: isDisabled,
        }}
      />
    </div>
  )
}

export const StoreSelector = () => {
  const { store_id: storeIdFromParams } = useParams()
  const { pathname } = useLocation()
  const history = useHistory()
  const [{ userMeta, currentStoreID }, dispatch] = useStateValue()
  const { busy } = useGetStoreData()

  const handleStoreChange = e => {
    const newStoreID = e.target.value
    if (newStoreID) {
      Cache.set(CacheKeys.selectedStoreId, newStoreID)
      dispatch({
        type: "GENERAL",
        data: {
          currentStoreID: newStoreID !== "Select an option" ? newStoreID : null,
        },
      })
    }
  }

  useEffect(() => {
    console.log(currentStoreID, storeIdFromParams)
    const isStorePath = [
      AppPaths.STORE_MANAGEMENT,
      AppPaths.THEME,
      AppPaths.NATIVE_APPS,
      AppPaths.MARKETING,
      AppPaths.REVIEW_RATING,
    ].some(path => pathname.includes(path))
    if (currentStoreID !== storeIdFromParams && isStorePath) {
      if (storeIdFromParams) {
        history.replace(
          pathname.replace(
            storeIdFromParams,
            currentStoreID ? currentStoreID : "",
          ),
        )
      } else if (currentStoreID && !storeIdFromParams) {
        history.push(currentStoreID ? currentStoreID : "")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStoreID])

  // useEffect(() => {
  //   let storePaths = ["/theme"]
  //   let shouldFetch =
  //     storePaths.some(path => pathname.startsWith(path)) && storeIdFromParams
  //   if (shouldFetch) {
  //     console.log("shouldFetch", shouldFetch)
  //   } else {
  //     console.log("shouldFetchNot", storeIdFromParams)
  //   }
  // }, [pathname, storeIdFromParams])

  return (
    <div className='primary-menu-store-select'>
      {!userMeta?.stores?.length && !busy ? <CreateStoreCard /> : null}

      {userMeta?.stores?.length ? (
        <>
          <SpaceBetween>
            <Header inline bold>
              Manage Store
            </Header>
            <IconButtonCircle
              small
              icon={"plus"}
              onClick={() => {
                dispatch({
                  type: "GENERAL",
                  data: { currentStoreID: null },
                })
                history.push(AppPaths.CREATE_STORE)
              }}
            />
          </SpaceBetween>
          <SelectField
            loading={busy}
            form={{ errors: {} }}
            field={{ name: "selectedStore", value: currentStoreID }}
            onChange={handleStoreChange}
            value={currentStoreID}
          >
            {userMeta.stores.map(store => (
              <option key={store.key} value={store.key}>
                {store.value}
              </option>
            ))}
          </SelectField>
        </>
      ) : null}
    </div>
  )
}

export default StoreSelector
