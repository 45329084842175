import React from "react"
import { Card, Header, IconButtonCircle, SpaceBetween } from "uiComponents"
import { FormMaker } from "formComponents"
import { useStateValue } from "state"

const fields = [
  {
    name: "name",
    label: "Name",
    type: "text",
    fullWidth: true,
  },
  {
    name: "location",
    label: "Location",
    type: "text",
    fullWidth: true,
  },
  {
    name: "phone",
    label: "Phone Number",
    type: "text",
    fullWidth: true,
  },
  {
    name: "kyc_status",
    label: "KYC Status",
    type: "select",
    fullWidth: true,
    children: ["Active", "Inactive", "Pending"].map(op => (
      <option key={op} value={op}>
        {op}
      </option>
    )),
  },
  {
    name: "tags",
    label: "Tags",
    type: "tags",
    fullWidth: true,
  },
]

const FilterData = ({ filterValues, onChange }) => {
  const [{ isFilterMenuOpen }, dispatch] = useStateValue()
  const handleFormSubmit = filterValues => {
    const newObject = { ...filterValues }
    console.log("Form data:", newObject)
  }

  return (
    <div>
      <Card lowPad>
        <SpaceBetween>
          <div>
            <Header sm bold>
              Filter Data
            </Header>
            <p>You can filter your data here</p>
          </div>
          <IconButtonCircle
            icon='xmark'
            onClick={() =>
              dispatch({
                type: isFilterMenuOpen && "CLOSE_FILTER_MENU",
              })
            }
          />
        </SpaceBetween>
        <FormMaker
          submitLabel={"Apply"}
          fields={fields}
          initialValues={{ ...filterValues }}
          onSubmit={handleFormSubmit}
          onChange={onChange}
          validateOnMount
        />
      </Card>
    </div>
  )
}

export default FilterData
