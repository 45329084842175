import React from "react"
import { Col, Row, Space, Header, Grid } from "uiComponents"
import Summary from "./Summary"
import PriceCard from "./PriceCard"
import { isMobile } from "helpers"
const Plans = () => {
  return (
    <Grid>
      <Row>
        <Col sm={12} md={12} lg={12} xl={4}>
          <Summary />
        </Col>
        <Col sm={12} md={12} lg={12} xl={8}>
          <div>
            <Space lg />
            <Header lg bold>
              Upgrade Account
            </Header>
            <p>
              Unlock a world of exclusive benefits with our Shoppio Membership!
            </p>
            <Space />
            <Col sm={12} md={12} lg={12} xl={12}>
              <Row>
                <Col sm={12} md={12} lg={6} xl={6}>
                  <PriceCard planType={"Pro"} planPrice={"1800"} />
                </Col>
                <Col sm={12} md={12} lg={6} xl={6}>
                  <PriceCard
                    elite
                    planType={"Elite"}
                    planPrice={"3000"}
                    style={{ marginleft: isMobile ? "" : "20px" }}
                  />
                </Col>
              </Row>
            </Col>
          </div>
        </Col>
      </Row>
    </Grid>
  )
}

export default Plans
