import React from "react"
import { Header, Card, Button, Space } from "uiComponents"
import AnalyticsTile from "./AnalyticsTile"

const Feedback = () => {
  const cardsData = [
    { title: "Store Rating", value: "4.5", icon: "star-sharp-half-1" },
    {
      title: "Reviews",
      value: "Mostly Positive +",
      icon: "emoji-smile-relaxed-happy",
    },
    {
      title: "Queries Resolved",
      value: "322 / 401  (80.1%)",
      icon: "hand-shake",
    },
  ]
  return (
    <Card notFull noXS>
      <Header md bold>
        Users Feedback
      </Header>
      <p>Insights about leads coming throught various channels</p>
      <Space lg />
      {cardsData.map(data => (
        <AnalyticsTile title={data.title} value={data.value} icon={data.icon} />
      ))}
      <Space />
      <Button alt fullWidth fullRound>
        View Review Center
      </Button>
    </Card>
  )
}

export default Feedback
