import React from "react"
import "./info-panel.css"
import {
  formStatus,
  formStatusColors,
  formStatusIcon,
} from "shoppio-constants/Status"

const InfoPanel = ({ content, status }) => {
  const barColor = formStatusColors[status] || "#000000"
  const containerStyle = {
    backgroundColor: `${barColor}10`,
  }

  return (
    <div className='info-panel-container' style={containerStyle}>
      <div
        className='info-panel-bar'
        style={{ backgroundColor: barColor }}
      ></div>
      <div className='info-panel-content-container'>
        <div className='info-panel-content-left'>
          <div className='info-panel-title'>
            {formStatus[status] || "Unknown Status"}
          </div>
          <div className='info-panel-content'>{content && content}</div>
        </div>
        <div
          className='info-panel-status-icon'
          style={{
            backgroundColor: barColor,
          }}
        >
          {formStatusIcon[status] ? (
            <i className={`lni lni-${formStatusIcon[status]}`}></i>
          ) : (
            <span>?</span>
          )}
        </div>
      </div>
    </div>
  )
}

export default InfoPanel
