import React from "react"
import "./ShippingLogistics.css"
import { Row, Col, Space, Button, Card, Header, Tabs, Tab } from "uiComponents"
import shiprocket from "../../../images/shiprocket.png"
import { ProvidersLogo } from "./ProvidersLogo"
import AvailableProviders from "./AvailableProviders"
import { isMobile } from "helpers"

const ActiveProviders = () => {
  const shippmentData = [
    {
      title: "Shipments Completed",
      value: "24.1 k",
    },
    {
      title: "Shipments Processed",
      value: "4.1 k",
    },
    {
      title: "Shipments Returned",
      value: "1.1 k",
    },
    {
      title: "Shipments In-transit",
      value: "30",
    },
    {
      title: "Shipments Cost",
      value: "8.1 k",
    },
    {
      title: "Shipments Weight",
      value: "4k grms",
    },
  ]
  return (
    <>
      <Tabs>
        <Tab label='Active Provider'>
          <Card>
            <div style={{ padding: "3rem" }}>
              <Row between='xs'>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <ProvidersLogo sm mr='10' logo={shiprocket} />
                  <div
                    style={{
                      fontSize: "3rem",
                      fontWeight: "600",
                      color: "var(--header-color)",
                    }}
                  >
                    Shiprocket
                  </div>
                </div>
                <Header bold>View By</Header>
              </Row>
              <Space lg />
              <Space lg />
              <Row>
                {shippmentData.map(item => (
                  <Col sm={6} md={2}>
                    <ShippmentCard title={item.title} value={item.value} />
                  </Col>
                ))}
              </Row>
              <Space lg />
              {isMobile && (
                <Header center={isMobile} sm bold>
                  <span
                    className='fas fa-sm fa-rupee-sign'
                    style={{ marginRight: "5px" }}
                  />
                  26/500 grms
                </Header>
              )}
              <Space lg />
              <Row between='xs'>
                <div>
                  <Button fullWidth={isMobile} fullRound>
                    Cancle
                  </Button>
                  <Button fullWidth={isMobile} accent fullRound>
                    {" "}
                    Download Invoices
                  </Button>
                </div>
                {!isMobile && (
                  <Header sm bold>
                    <span
                      className='fas fa-sm fa-rupee-sign'
                      style={{ marginRight: "5px" }}
                    />
                    26/500 grms
                  </Header>
                )}
              </Row>
            </div>
          </Card>
        </Tab>
        <Tab label='Available Provider'>
          <AvailableProviders />
        </Tab>
      </Tabs>
    </>
  )
}

export default ActiveProviders

export const ShippmentCard = ({ title, value }) => {
  return (
    <>
      <div className='shippment-card-container'>
        <Header>{title}</Header>
        <Space />
        <Header sm bold>
          {value}
        </Header>
      </div>
    </>
  )
}
