export const fields = [
  {
    name: "headline",
    label: "Headline",
    type: "text",
  },
  {
    name: "show_ads_on",
    label: "Show Ads On",
    type: "select",
    children: ["Other shops store front", "Product page / Checkout page"].map(
      op => <option value={op}>{op}</option>,
    ),
  },
  {
    name: "date_from",
    label: "Date (From)",
    type: "date",
  },
  {
    name: "date_to",
    label: "Date (To)",
    type: "date",
  },
  {
    name: "description",
    label: "Description",
    type: "textarea",
    fullWidth: true,
  },
  {
    name: "layout",
    label: "Layout",
    type: "select",
    children: ["Mobile", "Tablet", "Desktop"].map(op => (
      <option value={op}>{op}</option>
    )),
  },
  {
    name: "button_text",
    label: "Button Text",
    type: "text",
  },
  {
    name: "banner",
    label: "Banner",
    type: "pic",
  },
  // {
  //   name: "status",
  //   label: "Status",
  //   type: "radio",
  //   inline: true,
  //   options: ["Enabled", "Disabled"].map(v => ({ label: v, value: v })),
  // },
]
