import React, { useEffect, useState } from "react"
import "../../styles/form_components.css"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import Chip from "@mui/material/Chip"
import CircularProgress from "@mui/material/CircularProgress"
import "react-phone-number-input/style.css"
import PhoneInputWithCountrySelect from "react-phone-number-input"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormLabel from "@mui/material/FormLabel"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import { allColors } from "shoppio-constants"
import { Col, Row, Space, Button, Header, Dialog } from "uiComponents"
import Select from "react-select"
import {
  getHoursAndMinutesFromMinutes,
  getMinutesFromHoursAndMinutes,
} from "helpers"
import "react-datetime/css/react-datetime.css"
import ReactDatetime from "react-datetime"
import moment from "moment"
import { CurrencySign } from "config"
import FileUploaderInput from "./FileUploderInput/FileUploaderInput"
import Uploader from "./Uploader/Uploader"

// const ITEM_HEIGHT = 48
// const ITEM_PADDING_TOP = 8
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//     },
//   },
// }

export class InputField extends React.Component {
  state = {
    listActive: false,
    openFilesDialog: false,
    files: [],
  }
  selectNode
  toggleList = () =>
    this.setState({ listActive: !this.state.listActive }, () => {})

  UNSAFE_componentWillReceiveProps = newProps => {
    if (!newProps.field.value && this.props.field.value) {
      this.refresh(1)
    }
  }
  refresh = time => {
    setTimeout(() => {
      this.setState({ refresh: true })
      setTimeout(() => {
        this.setState({ refresh: false })
      }, time)
    }, time)
  }
  render() {
    const {
      field,
      label,
      withButton,
      withIcon,
      form,
      buttonText = "Search",
      hint = "",
      placeholder,
      type,
      dataTip,
      errorText,
      onButtonClick,
      busy,
      buttonDisabled,
      buttonBusy,
      initialValue,
      desc,
      row,
      clearOnIconClick,
      buttonActiveOnValue,
      submit,
      fullWidth,
      isValidDate,
      small,
      noMar,
      onFilesChange,
      ...props
    } = this.props
    const { touched = {}, errors = {} } = form || {}
    const { listActive, openFilesDialog } = this.state
    const hasErrors =
      field && field?.name && field?.name.includes(".")
        ? errors &&
          errors[field?.name.split(".")[0]] &&
          errors[field?.name.split(".")[0]][field?.name.split(".")[1]]
        : errors[field?.name]
    const hasTouched =
      field && field?.name && field?.name.includes(".")
        ? touched &&
          touched[field?.name.split(".")[0]] &&
          touched[field?.name.split(".")[0]][field?.name.split(".")[1]]
        : touched[field?.name]
    // console.log(">>Field", field)
    return (
      <div
        className={props.big ? "input-field big smooth" : "input-field smooth"}
        style={{
          borderColor:
            errorText || (hasTouched && hasErrors)
              ? "var(--error-font-color)"
              : "",
          boxShadow:
            errorText || (hasTouched && hasErrors)
              ? "0 0 0 0.5rem var(--error-background-color)"
              : "",

          marginBottom: errorText || (hasTouched && hasErrors) ? "6rem" : "",
          height: type === "textarea" ? "auto" : "",
          margin: noMar && "0px",
        }}
      >
        {label && (
          <label
            style={{
              color:
                errorText || (hasTouched && hasErrors)
                  ? "var(--error-font-color)"
                  : "",
            }}
          >
            {label}{" "}
            {dataTip && (
              <span className='fas fa-info-circle' data-tip={dataTip} />
            )}
            {desc ? <span className='label-desc'>({desc})</span> : ""}
          </label>
        )}
        {/* {console.log(">>>>>>>>>>>>>>>>", field, props?.value)} */}
        {type === "textarea" ? (
          <textarea
            type='text'
            placeholder={hint || placeholder}
            {...field}
            row={row || 4}
            value={field?.value || props?.value}
            onChange={e => {
              if (field?.onChange) field?.onChange(e)
              if (props?.onChange) props?.onChange(e)
            }}
            onBlur={e => {
              if (field?.onBlur) field?.onBlur(e)
              if (props?.onBlur) props?.onBlur(e)
            }}
          />
        ) : !this.state.refresh ? (
          <input
            type={type || "text"}
            {...props}
            {...field}
            value={field?.value || props?.value}
            onChange={e => {
              if (field?.onChange) field?.onChange(e)
              if (props?.onChange) props?.onChange(e)
            }}
            onBlur={e => {
              if (field?.onBlur) field?.onBlur(e)
              if (props?.onBlur) props?.onBlur(e)
            }}
            ref={n => (this.node = n)}
            placeholder={hint || placeholder}
          />
        ) : (
          ""
        )}
        {(errorText || (hasTouched && hasErrors)) && (
          <div className={"error"}>{errorText || hasErrors}</div>
        )}
        {props.list && (
          <span
            onClick={this.toggleList}
            className={
              listActive
                ? "input-chev fas fa-chevron-up"
                : "input-chev fas fa-chevron-down"
            }
          />
        )}
        {props.list && (
          <div
            className={
              listActive ? "input-select-list open" : "input-select-list"
            }
          >
            <select ref={n => (this.selectNode = n)}>
              <option value={""}>Select from previous portfolio</option>
              {props.list.map((item, k) => (
                <option value={item.value || item.label} key={k}>
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        )}
        {withButton && (
          <Button
            busy={busy}
            disabled={
              buttonDisabled || buttonActiveOnValue
                ? !(field?.value || props?.value)
                : null
            }
            colored
            submit={submit}
            onClick={() => (submit ? null : onButtonClick(field?.value))}
          >
            {buttonText}
          </Button>
        )}
        {true && (
          <span
            onClick={
              clearOnIconClick
                ? () => {
                    let newEvent = {
                      target: { name: field.name, value: "" },
                    }
                    if (field?.onChange) field?.onChange(newEvent)
                    if (props?.onChange) props?.onChange(newEvent)
                    this.refresh(5)
                  }
                : null
            }
            className='input-field-icon'
          >
            {withIcon}
          </span>
        )}
        {onFilesChange && (
          <span
            className={`input-file-icon lni lni-rotate-45 lni-paperclip-1 ${
              this.state.files.length ? "input-file-icon-active" : ""
            }`}
            onClick={() => this.setState({ openFilesDialog: true })}
          >
            {this.state.files?.length > 0 && (
              <span className='file-count-circle'>
                {this.state.files.length}
              </span>
            )}
          </span>
        )}

        {onFilesChange ? (
          <Uploader
            open={openFilesDialog}
            onClose={() => this.setState({ openFilesDialog: false })}
            onFilesChange={files =>
              this.setState(
                {
                  files: files,
                },
                () => onFilesChange(files),
              )
            }
          />
        ) : (
          ""
        )}
        {/* {this.state.files.length ? (
          <FileStatus
            files={this.state.files}
            openFilesDialog={() => this.setState({ openFilesDialog: true })}
          />
        ) : (
          ""
        )} */}
      </div>
    )
  }
}

// const FileStatus = ({ files, openFilesDialog }) => {
//   return (
//     <div className='attached-file-status'>
//       <div className='attached-file-status-children'>
//         <div className='attached-file-item'>
//           <span>
//             <i className='fas fa-paperclip' style={{ marginRight: "5px" }} />
//             {files?.length} {files?.length > 1 ? "files" : "file"} attached
//           </span>
//           <span
//             style={{ cursor: "pointer", textDecoration: "underline" }}
//             onClick={openFilesDialog}
//           >
//             view attachments
//           </span>
//         </div>
//       </div>
//     </div>
//   )
// }

export class TextWithAttach extends React.Component {
  state = {
    listActive: false,
    refresh: false,
  }

  selectNode = null
  node = null

  toggleList = () => this.setState({ listActive: !this.state.listActive })

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!newProps.field.value && this.props.field.value) {
      this.refresh(1)
    }
  }

  refresh = time => {
    setTimeout(() => {
      this.setState({ refresh: true })
      setTimeout(() => {
        this.setState({ refresh: false })
      }, time)
    }, time)
  }

  render() {
    const {
      field,
      label,
      form,
      hint = "",
      placeholder,
      dataTip,
      errorText,
      initialValue,
      desc,
      row,
      clearOnIconClick,
      ...props
    } = this.props

    const { touched = {}, errors = {} } = form || {}
    // const { listActive } = this.state

    const hasErrors =
      field && field?.name && field?.name.includes(".")
        ? errors &&
          errors[field?.name.split(".")[0]] &&
          errors[field?.name.split(".")[0]][field?.name.split(".")[1]]
        : errors[field?.name]
    const hasTouched =
      field && field?.name && field?.name.includes(".")
        ? touched &&
          touched[field?.name.split(".")[0]] &&
          touched[field?.name.split(".")[0]][field?.name.split(".")[1]]
        : touched[field?.name]

    return (
      <div
        className={props.big ? "input-field big smooth" : "input-field smooth"}
        style={{
          borderColor:
            errorText || (hasTouched && hasErrors)
              ? "var(--error-font-color)"
              : "",
          boxShadow:
            errorText || (hasTouched && hasErrors)
              ? "0 0 0 0.5rem var(--error-background-color)"
              : "",
          marginBottom: errorText || (hasTouched && hasErrors) ? "6rem" : "",
          height: "auto",
        }}
      >
        {label && (
          <label
            style={{
              color:
                errorText || (hasTouched && hasErrors)
                  ? "var(--error-font-color)"
                  : "",
            }}
          >
            {label}{" "}
            {dataTip && (
              <span className='fas fa-info-circle' data-tip={dataTip} />
            )}
            {desc ? <span className='label-desc'>({desc})</span> : ""}
          </label>
        )}
        <div className='textarea-with-icon'>
          <textarea
            type='text'
            placeholder={hint || placeholder}
            {...field}
            rows={row || 4}
            value={field?.value || props?.value}
            onChange={e => {
              if (field?.onChange) field?.onChange(e)
              if (props?.onChange) props?.onChange(e)
            }}
            onBlur={e => {
              if (field?.onBlur) field?.onBlur(e)
              if (props?.onBlur) props?.onBlur(e)
            }}
          />
          <span className='attach-icon'>
            <i className='fas fa-paperclip' />
          </span>
        </div>
        {(errorText || (hasTouched && hasErrors)) && (
          <div className={"error"}>{errorText || hasErrors}</div>
        )}
        {true && (
          <span
            onClick={
              clearOnIconClick
                ? () => {
                    let newEvent = {
                      target: { name: field.name, value: "" },
                    }
                    if (field?.onChange) field?.onChange(newEvent)
                    if (props?.onChange) props?.onChange(newEvent)
                    this.refresh(5)
                  }
                : null
            }
            className='input-field-icon'
          >
            {props.withIcon}
          </span>
        )}
      </div>
    )
  }
}

export const Range = ({ field, errorText, label, form, dataTip, ...props }) => {
  const { initialValue } = field || {}
  const { touched = {}, errors = {} } = form || {}
  const hasErrors =
    field && field?.name && field?.name.includes(".")
      ? errors &&
        errors[field?.name.split(".")[0]] &&
        errors[field?.name.split(".")[0]][field?.name.split(".")[1]]
      : errors[field?.name]
  const hasTouched =
    field && field?.name && field?.name.includes(".")
      ? touched &&
        touched[field?.name.split(".")[0]] &&
        touched[field?.name.split(".")[0]][field?.name.split(".")[1]]
      : touched[field?.name]

  return (
    <div>
      {label && (
        <label
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color:
              errorText || (hasTouched && hasErrors)
                ? "var(--error-font-color)"
                : "",
          }}
        >
          <span style={{ fontWeight: "bold" }}>
            {label}{" "}
            {dataTip && (
              <span className='fas fa-info-circle' data-tip={dataTip} />
            )}
          </span>
          <span style={{ color: "var(--font-color)", fontSize: "2rem" }}>
            {field?.value || 0}%
          </span>
        </label>
      )}
      <input
        {...props}
        {...field}
        onChange={e => {
          if (field?.onChange) field?.onChange(e)
          if (props?.onChange) props?.onChange(e)
        }}
        type='range'
        value={field.value || props.value || initialValue || 0}
      />
      {(errorText || (hasTouched && hasErrors)) && (
        <div className={"error"}>{errorText || hasErrors}</div>
      )}
    </div>
  )
}

export const PhoneField = ({
  field,
  label,
  withButton,
  form,
  buttonText = "Search",
  hint = "",
  type,
  dataTip,
  errorText,
  onButtonClick,
  busy,
  hideCaptcha,
  ...props
}) => {
  const { touched = {}, errors = {} } = form || {}
  const [customValue, setValue] = useState(field.value)
  const [phoneError, setPhoneError] = useState("")

  const hasErrors =
    field && field?.name && field?.name.includes(".")
      ? errors &&
        errors[field?.name.split(".")[0]] &&
        errors[field?.name.split(".")[0]][field?.name.split(".")[1]]
      : errors[field?.name]
  const hasTouched =
    field && field?.name && field?.name.includes(".")
      ? touched &&
        touched[field?.name.split(".")[0]] &&
        touched[field?.name.split(".")[0]][field?.name.split(".")[1]]
      : touched[field?.name]

  useEffect(() => {
    field.onChange({ target: { value: customValue, name: field.name } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customValue])

  const validatePhoneNumber = phone => {
    const phoneRegex = /^[+]?[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/
    return phoneRegex.test(phone)
  }

  const handleChange = value => {
    setValue(value)
    if (!validatePhoneNumber(value)) {
      setPhoneError("Invalid phone number format")
    } else {
      setPhoneError("")
    }
  }

  return (
    <div>
      <div
        className={props.big ? "input-field big smooth" : "input-field smooth"}
        style={{
          borderColor:
            errorText || (hasTouched && (hasErrors || phoneError))
              ? "var(--error-font-color)"
              : "",
          boxShadow:
            errorText || (hasTouched && (hasErrors || phoneError))
              ? "0 0 0 0.5rem var(--error-background-color)"
              : "",
          marginBottom:
            errorText || (hasTouched && (hasErrors || phoneError))
              ? "6rem"
              : "",
          height: type === "textarea" ? "auto" : "",
        }}
      >
        {label && (
          <label
            style={{
              color:
                errorText || (hasTouched && (hasErrors || phoneError))
                  ? "var(--error-font-color)"
                  : "",
            }}
          >
            {label}{" "}
            {dataTip && (
              <span className='fas fa-info-circle' data-tip={dataTip} />
            )}
          </label>
        )}
        <PhoneInputWithCountrySelect
          placeholder='Enter phone number'
          withCountryCallingCode
          name={field.name}
          onChange={handleChange}
          defaultCountry='IN'
          value={customValue}
        />

        {(errorText || (hasTouched && (hasErrors || phoneError))) && (
          <div className={"error"}>{errorText || hasErrors || phoneError}</div>
        )}
      </div>
      {!hideCaptcha && <div id='recaptcha-container'></div>}
    </div>
  )
}
export const DatePicker = ({
  field,
  label,
  form,
  type,
  dataTip,
  errorText,
  busy,
  isValidDate,
  ...props
}) => {
  const { touched = {}, errors = {} } = form || {}
  const hasErrors =
    field && field?.name && field?.name.includes(".")
      ? errors &&
        errors[field?.name.split(".")[0]] &&
        errors[field?.name.split(".")[0]][field?.name.split(".")[1]]
      : errors[field?.name]
  const hasTouched =
    field && field?.name && field?.name.includes(".")
      ? touched &&
        touched[field?.name.split(".")[0]] &&
        touched[field?.name.split(".")[0]][field?.name.split(".")[1]]
      : touched[field?.name]

  return (
    <div>
      <div
        className={props.big ? "input-field big smooth" : "input-field smooth"}
        style={{
          borderColor:
            errorText || (hasTouched && hasErrors)
              ? "var(--error-font-color)"
              : "",
          boxShadow:
            errorText || (hasTouched && hasErrors)
              ? "0 0 0 0.5rem var(--error-background-color)"
              : "",

          marginBottom: errorText || (hasTouched && hasErrors) ? "6rem" : "",
          height: type === "textarea" ? "auto" : "",
        }}
      >
        {label && (
          <label
            style={{
              color:
                errorText || (hasTouched && hasErrors)
                  ? "var(--error-font-color)"
                  : "",
            }}
          >
            {label}{" "}
            {dataTip && (
              <span className='fas fa-info-circle' data-tip={dataTip} />
            )}
          </label>
        )}
        <ReactDatetime
          className='date-time'
          value={field.value}
          isValidDate={isValidDate}
          dateFormat='YYYY-MM-DD'
          timeFormat={false}
          name={field.name}
          onChange={newValue => {
            if (field?.onChange) {
              newValue &&
                field?.onChange({
                  target: {
                    value: String(moment(newValue).toDate()),
                    name: field.name,
                  },
                })
            }
            if (props?.onChange) {
              newValue && props?.onChange(String(moment(newValue).toDate()))
            }
          }}
        />
        <i
          className='fa fa-lg fa-calendar'
          style={{ marginRight: "10px" }}
          aria-label='Select date'
        />

        {(errorText || (hasTouched && hasErrors)) && (
          <div className={"error"}>{errorText || hasErrors}</div>
        )}
      </div>
    </div>
  )
}

export const FileUploader = ({
  field,
  label,
  form,
  dataTip,
  errorText,
  busy,
  withButton,
  ...props
}) => {
  const { touched = {}, errors = {} } = form || {}
  const hasErrors =
    field && field?.name && field?.name.includes(".")
      ? errors &&
        errors[field?.name.split(".")[0]] &&
        errors[field?.name.split(".")[0]][field?.name.split(".")[1]]
      : errors[field?.name]
  const hasTouched =
    field && field?.name && field?.name.includes(".")
      ? touched &&
        touched[field?.name.split(".")[0]] &&
        touched[field?.name.split(".")[0]][field?.name.split(".")[1]]
      : touched[field?.name]

  return (
    <div>
      <div
        className={props.big ? "input-field big smooth" : "input-field smooth"}
        style={{
          borderColor: errorText || hasErrors ? "var(--error-font-color)" : "",
          boxShadow:
            errorText || hasErrors
              ? "0 0 0 0.5rem var(--error-background-color)"
              : "",
          marginBottom: errorText || hasErrors ? "6rem" : "",
        }}
      >
        {label && (
          <label
            style={{
              color: errorText || hasErrors ? "var(--error-font-color)" : "",
            }}
          >
            {label}{" "}
            {dataTip && (
              <span className='fas fa-info-circle' data-tip={dataTip} />
            )}
          </label>
        )}
        <FileUploaderInput
          field={field}
          {...props}
          onChange={url => {
            field?.onChange({
              target: {
                value: url,
                name: field.name,
              },
            })
          }}
          hasErrors={hasErrors}
        />
        <i
          className='fa fa-lg fa-upload'
          style={{ marginRight: "10px" }}
          aria-label='Upload file'
          // onClick={handleIconClick}
        />
        {console.log(
          ".................<<<<<<<",
          errorText,
          hasErrors,
          hasTouched,
        )}
        {(errorText || hasErrors) && (
          <div className={"error"}>{errorText || hasErrors}</div>
        )}
      </div>
    </div>
  )
}

export const DateTimePicker = ({
  field,
  label,
  form,
  type,
  dataTip,
  errorText,
  busy,
  isValidDate,
  ...props
}) => {
  const { touched = {}, errors = {} } = form || {}
  const hasErrors =
    field && field?.name && field?.name.includes(".")
      ? errors &&
        errors[field?.name.split(".")[0]] &&
        errors[field?.name.split(".")[0]][field?.name.split(".")[1]]
      : errors[field?.name]
  const hasTouched =
    field && field?.name && field?.name.includes(".")
      ? touched &&
        touched[field?.name.split(".")[0]] &&
        touched[field?.name.split(".")[0]][field?.name.split(".")[1]]
      : touched[field?.name]
  return (
    <div>
      <div
        className={props.big ? "input-field big smooth" : "input-field smooth"}
        style={{
          borderColor:
            errorText || (hasTouched && hasErrors)
              ? "var(--error-font-color)"
              : "",
          boxShadow:
            errorText || (hasTouched && hasErrors)
              ? "0 0 0 0.5rem var(--error-background-color)"
              : "",

          marginBottom: errorText || (hasTouched && hasErrors) ? "6rem" : "",
          height: type === "textarea" ? "auto" : "",
        }}
      >
        {label && (
          <label
            style={{
              color:
                errorText || (hasTouched && hasErrors)
                  ? "var(--error-font-color)"
                  : "",
            }}
          >
            {label}{" "}
            {dataTip && (
              <span className='fas fa-info-circle' data-tip={dataTip} />
            )}
          </label>
        )}
        <ReactDatetime
          className='date-time'
          value={field.value}
          isValidDate={isValidDate}
          name={field.name}
          onChange={newValue => {
            if (field?.onChange) {
              newValue &&
                field?.onChange({
                  target: {
                    value: String(moment(newValue).toDate()),
                    name: field.name,
                  },
                })
            }
            if (props?.onChange) {
              newValue && props?.onChange(String(moment(newValue).toDate()))
            }
          }}
        />
        <i
          className='fa fa-lg fa-calendar'
          style={{ marginRight: "10px" }}
          aria-label='Select date'
        />
        {(errorText || (hasTouched && hasErrors)) && (
          <div className={"error"}>{errorText || hasErrors}</div>
        )}
      </div>
    </div>
  )
}
export const InputCheck = ({ checked, onChange, label, errorText }) => {
  const label2 = { inputProps: { "aria-label": label } }

  return (
    <div>
      <FormControlLabel
        style={{ alignItems: "flex-start" }}
        control={<Checkbox {...label2} checked={checked} onChange={onChange} />}
        label={label}
      />
      {errorText && <div className={"error"}>{errorText}</div>}
      <Space />
    </div>
  )
}

export const CheckboxInput = ({
  onChange,
  field,
  errorText,
  form,
  label,
  dataTip,
  style,
  ...props
}) => {
  const label2 = { inputProps: { "aria-label": label } }
  // log("value", field.value)
  return (
    <div className='checkbox-component'>
      <FormControlLabel
        style={{ alignItems: "flex-start" }}
        control={
          <Checkbox
            {...label2}
            disableRipple
            checked={field.value?.includes("on")}
            name={field.name}
            onChange={e => {
              if (field?.onChange) field?.onChange(e)
              if (props?.onChange) props?.onChange(e)
            }}
          />
        }
        label={label}
      />
      {errorText && <div className={"error"}>{errorText}</div>}
      <Space />
    </div>
  )
}
export const Radios = ({
  onChange,
  field,
  errorText,
  form,
  label,
  dataTip,
  options,
  style,
  inline,
  ...props
}) => {
  // log("value", field.value)
  return (
    <div>
      <FormLabel className='radio-label'>{label}</FormLabel>
      <RadioGroup
        value={field.value}
        className={inline ? "radio-inline" : ""}
        defaultValue='female'
        name={field.name}
        onChange={e => {
          if (field?.onChange) field?.onChange(e)
          if (props?.onChange) props?.onChange(e)
        }}
      >
        {options?.map((op, key) => (
          <FormControlLabel
            key={key}
            disabled={props?.disabled}
            onClick={op?.onClick}
            value={op.value}
            control={<Radio />}
            label={op.label}
          />
        ))}
      </RadioGroup>
      {errorText && <div className={"error"}>{errorText}</div>}
      <Space />
    </div>
  )
}
export const DurationInput = ({
  onChange,
  field,
  errorText,
  form,
  label,
  dataTip,
  options,
  style,
  inline,
  ...props
}) => {
  const { errors = {} } = form || {}
  const [customValue, setValue] = useState(field.value)
  const [hours, setHours] = useState(
    field.value ? getHoursAndMinutesFromMinutes(field.value)?.hours : "",
  )
  const [minutes, setMinutes] = useState(
    field.value ? getHoursAndMinutesFromMinutes(field.value)?.minutes : "",
  )
  const hasErrors =
    field && field?.name && field?.name.includes(".")
      ? errors &&
        errors[field?.name.split(".")[0]] &&
        errors[field?.name.split(".")[0]][field?.name.split(".")[1]]
      : errors[field?.name]

  useEffect(() => {
    field.onChange({ target: { value: customValue, name: field.name } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customValue])
  useEffect(() => {
    setValue(getMinutesFromHoursAndMinutes(hours, minutes))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hours, minutes])
  // log("value", field, errors, hasErrors)

  return (
    <div>
      <FormLabel style={{ marginBottom: "-1rem" }} className='radio-label'>
        {label}
      </FormLabel>

      <div
        style={{ display: "flex", position: "relative", marginBottom: "4rem" }}
      >
        <div
          className={
            props.big ? "input-field big smooth" : "input-field smooth"
          }
          style={{
            marginRight: "1rem",
            marginBottom: 0,
            borderColor:
              errorText || hasErrors ? "var(--error-font-color)" : "",
            boxShadow:
              errorText || hasErrors
                ? "0 0 0 0.5rem var(--error-background-color)"
                : "",
          }}
        >
          <input
            placeholder='Hours'
            type='number'
            value={hours}
            max={24}
            onChange={e => setHours(e.target.value)}
          />
          {hours ? <span className='duration-intent'>Hours</span> : ""}
        </div>
        <div
          className={
            props.big ? "input-field big smooth" : "input-field smooth"
          }
          style={{
            marginLeft: "1rem",
            marginBottom: 0,
            borderColor:
              errorText || hasErrors ? "var(--error-font-color)" : "",
            boxShadow:
              errorText || hasErrors
                ? "0 0 0 0.5rem var(--error-background-color)"
                : "",
          }}
        >
          <input
            placeholder='Minutes'
            type='number'
            value={minutes}
            max={60}
            onChange={e => setMinutes(e.target.value)}
          />
          {minutes ? <span className='duration-intent'>Minutes</span> : ""}
        </div>

        {(errorText || hasErrors) && (
          <div className={"error"}>{errorText || hasErrors}</div>
        )}
      </div>
    </div>
  )
}

export const PriceInput = ({ field, errorText, form, label, ...props }) => {
  const [price, setPrice] = useState("")

  const handlePriceChange = e => {
    const { value } = e.target
    setPrice(value)
  }

  return (
    <div>
      <FormLabel style={{ marginBottom: "-1rem" }} className='radio-label'>
        {label}
      </FormLabel>

      <div style={{ display: "flex", position: "relative" }}>
        <div
          className={
            props.big ? "input-field big smooth" : "input-field smooth"
          }
          style={{
            marginRight: "1rem",
            marginBottom: 0,
            borderColor: errorText ? "var(--error-font-color)" : "",
            boxShadow: errorText
              ? "0 0 0 0.5rem var(--error-background-color)"
              : "",
          }}
        >
          <input
            placeholder='Price'
            type='number'
            value={price}
            onChange={handlePriceChange}
          />
          {price ? <span className='price-intent'>{CurrencySign}</span> : ""}
        </div>

        {errorText && <div className={"error"}>{errorText}</div>}
      </div>
    </div>
  )
}

export const SimpleChip = ({ onClick, label, ...rest }) => {
  return (
    <Chip
      className='simple-chip'
      onD
      label={label}
      onClick={onClick}
      {...rest}
    />
  )
}
export const AutoComplete = ({
  options,
  label,
  loading,
  getOptionLabel,
  value,
  ...rest
}) => {
  return (
    <div className='autocomplete'>
      <Autocomplete
        style={{ borderRadius: "20px" }}
        disablePortal
        value={value}
        id='combo-box-demo'
        options={options}
        getOptionLabel={getOptionLabel}
        sx={{ width: "100%" }}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color='inherit' size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
        {...rest}
      />
    </div>
  )
}
export const TextArea = p => <InputField type='textarea' {...p} />
export const NumberField = p => <InputField type='number' {...p} />
export const SelectField = ({
  field,
  errorText,
  form,
  label,
  dataTip,
  style,
  ...props
}) => {
  let { touched, errors } = form || {}
  // console.log(errorText, 'ERRRRRR');
  const hasErrors =
    form && field && field?.name && field?.name.includes(".")
      ? errors &&
        errors[field?.name.split(".")[0]] &&
        errors[field?.name.split(".")[0]][field?.name.split(".")[1]]
      : errors[field?.name]
  const hasTouched =
    field && field?.name && field?.name.includes(".")
      ? touched &&
        touched[field?.name.split(".")[0]] &&
        touched[field?.name.split(".")[0]][field?.name.split(".")[1]]
      : touched?.[field?.name]
  return (
    <div
      className={
        props.small
          ? "input-field select-small smooth select"
          : "input-field smooth select"
      }
      style={{
        borderColor:
          errorText || (hasTouched && hasErrors)
            ? "var(--error-font-color)"
            : "",
        boxShadow:
          errorText || (hasTouched && hasErrors)
            ? "0 0 0 0.5rem var(--error-background-color)"
            : "",
        ...style,
      }}
    >
      {props.loading && <span className='fas fa-circle-notch fa-spin' />}
      {label && (
        <label
          style={{
            color:
              errorText || (hasTouched && hasErrors)
                ? "var(--error-font-color)"
                : "",
          }}
        >
          {label}{" "}
          {dataTip && (
            <span className='fas fa-info-circle' data-tip={dataTip} />
          )}
        </label>
      )}
      <select
        {...field}
        onChange={e => {
          if (field?.onChange) field?.onChange(e)
          if (props?.onChange) props?.onChange(e)
        }}
      >
        <option value={null}> Select an option </option>
        {props.children ||
          (props.list &&
            props.list.map((item, k) => (
              <option value={item.value} key={k}>
                {item.label}
              </option>
            )))}
      </select>
      {((touched && touched[field?.name] && errors[field?.name]) ||
        errorText) && (
        <div className='error'>{errors[field?.name] || errorText}</div>
      )}
    </div>
  )
}

// function getStyles(optionValue, value, theme) {
//   return {
//     fontWeight:
//       value && value.indexOf(optionValue) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   }
// }

export function MultiSelectChips({
  options,
  label,
  placeholder,
  field,
  onChange,
  value,
  errorText,
  dataTip,
}) {
  const handleChange = (value, a) => {
    console.log("Value", value, field)
    if (field.onChange) {
      field?.onChange({
        target: {
          value: value,
          name: field.name,
        },
      })
    }
    if (onChange) onChange(value)
  }
  return (
    <div className='multi-select-field'>
      {label && (
        <label
          className='multi-select-field-label'
          style={{
            color: errorText ? "var(--error-font-color)" : "",
          }}
        >
          {label}{" "}
          {dataTip && (
            <span className='fas fa-info-circle' data-tip={dataTip} />
          )}
        </label>
      )}
      <Select
        isDisabled={field?.disabled}
        label={label}
        isMulti
        placeholder={placeholder}
        onChange={handleChange}
        value={value || field.value || []}
        options={options}
      />
    </div>
  )
}

export const ColorChooser = ({
  value,
  onChange,
  closeDialogOnChange = true,
}) => {
  const [dialog, setDialog] = useState()
  const [searchText, setSearchText] = useState()
  const colors =
    searchText && searchText !== ""
      ? allColors.filter(c => c.name.includes(searchText))
      : allColors
  return (
    <div>
      <ColorCard
        value={value}
        bottomAction={
          <Button noMar small onClick={setDialog}>
            {value ? "Change Color" : "Choose Color"}
          </Button>
        }
      />

      <Dialog right open={dialog} onClose={() => setDialog()}>
        <Header lg bold>
          Select Color
        </Header>
        <Row>
          <Col xs={12}>
            <InputField
              withButton
              field={{
                onChange: e => setSearchText(e.target.value),
                value: searchText,
              }}
              hintText='Search Color...'
              buttonText={<span className='lni lni-xmark' />}
              onButtonClick={() => {
                setSearchText("")
              }}
            />
          </Col>
          {colors.map((color, k) => (
            <Col xs={6}>
              <ColorCard
                onClick={color => {
                  onChange && onChange(color)
                  closeDialogOnChange && setDialog()
                }}
                value={color}
                key={k}
              />
            </Col>
          ))}
        </Row>
      </Dialog>
    </div>
  )
}

export const ColorCard = ({ value, selected, onClick, bottomAction }) => {
  const { name, hex } = value || {}
  return (
    <div
      className='color-card'
      onClick={() => onClick && onClick(value)}
      style={selected ? { borderColor: "var(--theme-color)" } : {}}
    >
      <Header bold sm>
        {name}
      </Header>
      <div className='color-card-color' style={{ backgroundColor: hex }} />
      {bottomAction && <Space />}
      {bottomAction}
    </div>
  )
}
