import React, { useState } from "react"
import { Row, Col, Card, Header, Space, Button } from "uiComponents"
import { PageLayout } from "appComponents"
import { FormMaker } from "../../Forms"
import getSVG from "../../../images/svgs/svg"
import "./QueryResolution.css"
import TicketDetails from "./TicketDetails"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { getAvatarColor, hexToRgba, parseDateTime } from "helpers"
import { useStateValue } from "state"
import { addDataToDb, DB } from "firebaseUtils"
import { getInitials } from "../Messages/CustomerMessages"
import UserTickets from "./components/UserTickets"
import { AppPaths } from "shoppio-constants"

const QueryResolution = () => {
  const [selectedTicket, setSelectedTicket] = useState(null)
  // console.log("selectedTicket.......", selectedTicket)
  const [busy, setBusy] = useState(false)
  const history = useHistory()
  const [{ user, userMeta }, dispatch] = useStateValue()
  const [attachments, setAttachments] = useState([])
  const fields = [
    {
      name: "name",
      label: "Ticket Name",
      type: "text",
      fullWidth: true,
    },
    {
      label: "Description",
      type: "textarea",
      name: "description",
      rows: 10,
      fullWidth: true,
      onFilesChange: uploadedFiles => {
        console.log("Files changed uploaded:", uploadedFiles)
        setAttachments(uploadedFiles)
      },
    },
  ]
  const addToUserMeta = async (doc, id) => {
    const ref = DB.collection(`users_meta`)
      .doc(user?.uid)
      .collection("tickets")
      .doc(id) // To set specific document id
    const res = await ref.set(doc)
    return res
  }

  const handleSubmit = async values => {
    const url = "tickets"
    console.log("Form submitted:", values)
    try {
      setBusy(true)
      const result = await addDataToDb(
        url,
        {
          ...values,
          attachments,
          created_by: {
            user_id: user?.uid,
            user_name: userMeta?.name,
          },
          status: "Open",
        },
        dispatch,
        "Ticket added successfully!",
        doc => {
          console.log("Document saved successfully:", doc)
          addToUserMeta(
            { key: doc.id, value: values.name, type: "general" },
            doc.id,
          )
        },
      )
      if (result === "success") {
        console.log("Ticket added successfully!")
      }
      setBusy(false)
    } catch (error) {
      console.error("Error adding ticket:", error)
    }
  }
  return (
    <PageLayout
      mainContent={
        <Row>
          <Col xs={12}>
            <Card
              svgIcon={getSVG(
                "Query",
                { width: "100%", height: "30rem" },
                null,
                true,
              )}
            >
              <Row>
                <Col sm={12} md={12}>
                  <Header lg bold>
                    Need help, checkout our FAQs.
                  </Header>
                  <p style={{ fontSize: "20px", fontWeight: "500" }}>
                    Check out our FAQs to get answers to your queries or raise a
                    query below and we will resolve them asap.
                  </p>
                  <Space lg />
                  <div>
                    <Button
                      noMar
                      fullRound
                      extraPad
                      onClick={() => history.push(AppPaths.FAQS)}
                    >
                      Checkout FAQs
                    </Button>
                    <Button noMar alt fullRound extraPad>
                      Learn More
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col sm={12} md={3}>
            <UserTickets setSelectedTicket={setSelectedTicket} />
          </Col>

          <Col sm={12} md={9}>
            <Card>
              {selectedTicket ? (
                <TicketDetails admin ticket={selectedTicket} />
              ) : (
                <Row>
                  <Col sm={12} md={6}>
                    <Header md bold>
                      Raise a query
                    </Header>
                    <p>
                      Do you need help, or stuck or have a suggestion, we’ll get
                      back to you as soon as possible
                    </p>
                    <Space lg />
                    <FormMaker
                      fields={fields}
                      submitLabel={"Send"}
                      onSubmit={handleSubmit}
                      busy={busy}
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    {getSVG(
                      "Chat_bot",
                      { width: "100%", height: "60rem" },
                      null,
                      true,
                    )}
                  </Col>
                </Row>
              )}
            </Card>
          </Col>
        </Row>
      }
    />
  )
}

export default QueryResolution

export const TicketCard = ({ ticket, onClick }) => {
  let ticketOwner = getInitials(
    typeof ticket?.created_by === "object"
      ? ticket?.created_by.user_name
      : ticket?.created_by,
  )

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return `${text?.substring(0, maxLength)}...`
    }
    return text
  }

  let assignee
  try {
    assignee = ticket?.assignee ? JSON.parse(ticket?.assignee) : null
  } catch (error) {
    console.error("Failed to parse assignee data", error)
    assignee = null
  }
  return (
    <div onClick={onClick}>
      <Card className='ticket-card'>
        <Row between='xs'>
          <div style={{ display: "flex", gap: "10px" }}>
            <AvatarChip
              label={ticketOwner}
              color={getAvatarColor(ticketOwner)}
            />
            <div>
              <Header bold>{truncateText(ticket?.name, 30)}</Header>
              <small>Created on: {parseDateTime(ticket?.createdAt)}</small>
            </div>
          </div>
          <i
            className='fas fa-chevron-right'
            style={{ fontSize: "12px", cursor: "pointer" }}
          />
        </Row>
        <Space />
        <Row between='xs' middle='xs'>
          <div>
            <StatusChip status={ticket?.status} />
          </div>
          <div className='new-message-container'>
            {ticket?.assignee && (
              <AvatarChip
                label={getInitials(assignee?.value)}
                color={getAvatarColor(assignee?.value)}
              />
            )}
          </div>
        </Row>
      </Card>
    </div>
  )
}

export const StatusChip = ({ status, accent, style }) => {
  return (
    <div
      className={`status-chip ${
        status === "Open"
          ? " open"
          : status === "In Progress"
          ? "inProgress"
          : " closed"
      }${accent ? " accent" : ""}`}
      style={{ ...style }}
    >
      {status}
    </div>
  )
}

export const AvatarChip = ({
  label,
  large,
  style,
  accent,
  accentAlt,
  color,
}) => {
  // console.log("Color prop:", color, typeof color)
  return (
    <div
      style={{
        backgroundColor: color && hexToRgba(color, 0.1),
        color: color && color,
        ...style,
      }}
      className={`avatar-chip ${
        large ? " large" : accent ? " accent" : accentAlt ? " accentAlt" : ""
      }`}
    >
      <b>{label}</b>
    </div>
  )
}

export const NewMessage = ({ newMessage }) => {
  return (
    <>
      {newMessage && (
        <div className='new-message'>
          <span className='fas fa-circle icon-circle' />
          New Message!
        </div>
      )}
    </>
  )
}
