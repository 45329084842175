import React from "react"
import { Card, Row, Col, Space, Expander } from "uiComponents"
import AnalyticsTile from "./AnalyticsTile"

const OrderAnalytics = () => {
  const cardsData = [
    { title: "Total orders placed", value: "2024", icon: "boxes-stacked-2" },
    { title: "Total orders returned", value: "782", icon: "box-undo" },
    {
      title: "Pending orders",
      value: "27",
      icon: "timer-1",
    },
    {
      title: "Completed orders",
      value: "1090",
      icon: "clipboard-check",
    },
  ]
  return (
    <Card>
      <Expander
        title={"Order Analytics"}
        subTitle={
          "Analyze order data, trends, and fulfillment metrics while managing products and categories"
        }
      >
        <Space lg />
        <Row>
          {cardsData.map(data => (
            <Col sm={12} md={12} lg={6}>
              <AnalyticsTile
                title={data.title}
                value={data.value}
                icon={data.icon}
                noIcon
                light
              />
            </Col>
          ))}
        </Row>
      </Expander>
    </Card>
  )
}

export default OrderAnalytics
