import React from "react"
import "./iconbtncircle.css"

function IconButtonCircle({
  icon,
  withShadow,
  fabIcon,
  onClick,
  children,
  step,
  style,
  accent,
  small,
  large,
  bgColor,
  color,
  size,
  mr,
  ml,
}) {
  const getClasses = () => {
    let initial = "icon-button-circle"
    return (
      initial +
      (accent ? " accent" : "") +
      (small ? " small" : "") +
      (large ? " large" : "") +
      (withShadow ? " with-shadow" : "")
    )
  }

  const classes = getClasses()

  return (
    <div className='icon-button-container' style={{ ...style }}>
      <div
        onClick={onClick}
        className={classes}
        style={{
          backgroundColor: bgColor,
          color: color,
          marginRight: `${mr}px`,
          marginLeft: `${ml}px`,
        }}
      >
        <i className={`lni lni-${icon}`} style={{ fontSize: `${size}px` }} />
        <i className={`fab fa-${fabIcon}`} style={{ fontSize: `${size}px` }} />
        <p>{step}</p>
        {children}
      </div>
    </div>
  )
}

export default IconButtonCircle
