import React, { useEffect } from "react"
import {
  Col,
  Row,
  VerticalLinearStepper,
  IconButtonCircle,
  Space,
  Card,
  Header,
} from "uiComponents"
import getSVG from "../../../images/svgs/svg"
import SpaceBetween from "../../UI/Space/SpaceBetween"
import "./AccountSetup.css"
import { useStateValue } from "state"
import { AppPaths } from "shoppio-constants"

const DashboardAccountSetup = () => {
  const [activeStep, setActiveStep] = React.useState(0)
  const [{ userMeta, user }] = useStateValue()

  useEffect(() => {
    let step = 0

    if (userMeta?.personalInfo?.status === "approved") {
      step = 1
    }
    if (userMeta?.businessInfo?.status === "approved") {
      step = 2
    }
    if (userMeta?.kycInfo?.status === "approved") {
      step = 3
    }
    setActiveStep(step)
    // eslint-disable-next-line
  }, [userMeta])

  const steps = [
    {
      label: "Enter Personal Information",
      description: `Please provide your name, date of birth, and contact details.`,
      onClick: AppPaths.ACCOUNT_SETUP,
    },
    {
      label: "Business Information",
      description:
        "Provide details about your business, including business name, address, and type of business.",
      onClick: AppPaths.ACCOUNT_SETUP,
    },
    {
      label: "KYC Verification",
      description:
        "Upload your identification documents and complete the Know Your Customer (KYC) verification.",
      onClick: AppPaths.ACCOUNT_SETUP,
    },
    {
      label: "Setup Store",
      description: `Set up your store by adding products, setting prices, and configuring payment methods.`,
      onClick: AppPaths.CREATE_STORE,
    },
    {
      label: "Review and Confirm",
      description: `Review the information you have provided and confirm your account setup.`,
      onClick: AppPaths.ACCOUNT_SETUP,
    },
  ]

  return (
    <Card>
      <SpaceBetween>
        <div>
          <Header bold md>
            Hi, {userMeta?.personalInfo?.name || user?.phoneNumber}
          </Header>
          <p>You can manage your store, account, and analytics.</p>
        </div>
        <IconButtonCircle icon={"chevron-right"} />
      </SpaceBetween>
      <Space lg />
      <Row>
        <Col sm={12} md={6}>
          <Header bold sm>
            Account Setup
          </Header>
          <VerticalLinearStepper
            steps={steps}
            activeStep={activeStep}
            onStepChange={setActiveStep}
          />
        </Col>
        <Col sm={12} md={6}>
          <div
            style={{ position: "absolute", bottom: 0 }}
            className='svg-style'
          >
            {getSVG("Payment Info", { width: 350 }, null, true)}
          </div>
        </Col>
      </Row>
    </Card>
  )
}

export default DashboardAccountSetup
